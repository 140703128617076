import React from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useEffect } from "react";

const AccountsPayableRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const currentYear = moment().format("YYYY");
    const currentMonth = moment().format("MM");
    navigate(`/account-payable/${currentYear}/${currentMonth}`);
  }, []);

  return <div />;
};
export default AccountsPayableRedirect;
