/* eslint-disable react/prop-types */
import React, { useState, useMemo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Http from "../services/http";

import ProcurementInvoiceTableHeader from "./ProcurementInvoiceTableHeader";

import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { set } from "date-fns";
import Dialog from "../components/Dialog";
import Loading from "../components/Loading";
import { id, is } from "date-fns/locale";

const ProcurementInvoiceTable = ({
  procurementID,
  isEditable,
  canChangeEditable,
  setLoading,
}) => {
  const navigate = useNavigate();
  const [procurementInvoiceList, setProcurementInvoiceList] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [beforeTaxTotal, setBeforeTaxTotal] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [totalAmountTotal, setTotalAmountTotal] = useState(0);
  const [isOpen, setModalOpen] = useState(false);
  const [selectedInvoiceID, setSelectedInvoiceID] = useState(null);
  const [isEditableState, setIsEditableState] = useState(false);

  const closeDialog = () => {
    setModalOpen(false);
  };

  const toggleRow = (rowIndex) => {
    if (expandedRows.includes(rowIndex)) {
      setExpandedRows(
        expandedRows?.filter((row) => {
          return row !== rowIndex;
        })
      );
    } else {
      setExpandedRows([...expandedRows, rowIndex]);
    }
  };

  const fetchProcurementInvoiceList = async () => {
    setLoading(true);
    try {
      const response = await Http.get(
        `auth/procurement/invoice/list?procurementID=${procurementID}`
      );

      console.log("response:", response);

      setProcurementInvoiceList(response);
      setBeforeTaxTotal(
        response.reduce((acc, curr) => acc + curr.beforeTax, 0)
      );
      setTaxTotal(response.reduce((acc, curr) => acc + curr.tax, 0));
      setTotalAmountTotal(
        response.reduce((acc, curr) => acc + curr.totalAmount, 0)
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("Error fetching data");
    }
    setLoading(false);
  };

  const deleteProcurementInvoice = async (procurementID, invoiceID) => {
    setLoading(true);
    try {
      const response = await Http.delete(
        `auth/procurement/invoice?procurementID=${procurementID}&invoiceID=${invoiceID}`
      );

      console.log("response:", response);

      fetchProcurementInvoiceList();
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("Error fetching data");
    }
    closeDialog();
    setLoading(false);
  };

  const changeEditableHandler = async (isEditable) => {
    setLoading(true);
    try {
      const response = await Http.post(`auth/procurement/invoice/editable`, {
        id: Number(procurementID),
        editable: isEditable,
      });

      console.log("response:", response);
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("Error fetching data");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchProcurementInvoiceList();
  }, [procurementID]);

  useEffect(() => {
    setIsEditableState(isEditable);
  }, [isEditable]);

  const procurementInvoiceDetailListTemplate = (
    procurementInvoiceDetailList
  ) => {
    return procurementInvoiceDetailList?.map((detail) => (
      <div
        className="flex justify-between items-center mt-1 mb-1 pl-16"
        key={detail.id}
      >
        <div className="text-xl break-words w-[250px] min-h-[39px]">
          {detail.itemName}
        </div>
        <div className="text-xl break-words w-[250px] min-h-[39px] pl-1">
          {detail.specification}
        </div>
        <div className="text-xl break-words w-[80px] min-h-[39px] pl-1">
          {detail.quantity?.toLocaleString()}
        </div>
        <div className="text-xl break-words w-[80px] min-h-[39px] pl-1">
          {detail.unit}
        </div>
        <div className="text-xl break-words w-[100px] min-h-[39px] pl-1">
          {detail.weight?.toLocaleString()}
        </div>
        <div className="text-xl break-words w-[120px] min-h-[39px] pl-1">
          ${detail.unitPrice?.toLocaleString()}
        </div>
        <div className="text-xl break-words w-[250px] min-h-[39px] pl-1">
          ${detail.totalAmount?.toLocaleString()} (
          {detail.isIncludeTax ? "含稅" : "未稅"})
        </div>
        <div className="text-xl break-words w-[250px] min-h-[39px] pl-1">
          {detail.remark}
        </div>
      </div>
    ));
  };

  const procurementInvoiceListTemplate = () => {
    if (procurementInvoiceList.length === 0) {
      return (
        <div className="flex justify-center items-center h-[80px]">
          <div className="text-3xl text-[#6F6F6F]">尚無到貨單</div>
        </div>
      );
    }

    return procurementInvoiceList?.map((invoice, index) => (
      <div key={invoice.invoiceID}>
        <div className="h-[75px] flex items-center justify-around">
          <div className="w-[30px] h-[30px]">
            <button onClick={() => toggleRow(index)}>
              {expandedRows.includes(index) ? (
                <IoIosArrowDown className="w-[30px] h-[30px]" />
              ) : (
                <IoIosArrowForward className="w-[30px] h-[30px]" />
              )}
            </button>
          </div>
          <div className="text-2xl w-[72px]">#{invoice.invoiceID}</div>
          <div className="text-2xl w-[180px]">{invoice.receiptNumber}</div>
          <div className="text-2xl w-[180px]">{invoice.invoiceDate}</div>
          <div className="text-2xl w-[135px]">{invoice.createUserName}</div>
          <div className="text-2xl w-[180px]">
            ${invoice.beforeTax?.toLocaleString()}
          </div>
          <div className="text-2xl w-[180px]">
            ${invoice.tax?.toLocaleString()}
          </div>
          <div className="text-2xl w-[180px]">
            ${invoice.totalAmount?.toLocaleString()}
          </div>
          {isEditableState ? (
            <button className="w-[35px] h-[35px]">
              <FiEdit
                className="w-[35px] h-[35px]"
                onClick={() => {
                  navigate(
                    `/procurement/invoice/edit/${procurementID}/${invoice.invoiceID}`
                  );
                }}
              />
            </button>
          ) : (
            <div className="w-[35px] h-[35px]" />
          )}
          {isEditableState ? (
            <button className="w-[35px] h-[35px]">
              <RiDeleteBin6Line
                className="w-[35px] h-[35px] text-red-500"
                onClick={() => {
                  setSelectedInvoiceID(invoice.invoiceID);
                  setModalOpen(true);
                }}
              />
            </button>
          ) : (
            <div className="w-[35px] h-[35px]" />
          )}
        </div>
        {expandedRows.includes(index) ? (
          <div className="bg-[#ECF5FF] pt-3 pb-3">
            <div className="flex justify-between items-center  pl-16">
              <div className="font-bold text-2xl text-[#6F6F6F] break-words w-[250px] h-[39px]">
                品名
              </div>
              <div className="font-bold text-2xl text-[#6F6F6F] break-words w-[250px] h-[39px] pl-1">
                規格
              </div>
              <div className="font-bold text-2xl text-[#6F6F6F] break-words w-[80px] h-[39px] pl-1">
                數量
              </div>
              <div className="font-bold text-2xl text-[#6F6F6F] break-words w-[80px] h-[39px] pl-1">
                單位
              </div>
              <div className="font-bold text-2xl text-[#6F6F6F] break-words w-[100px] h-[39px] pl-1">
                重量
              </div>
              <div className="font-bold text-2xl text-[#6F6F6F] break-words w-[120px] h-[39px] pl-1">
                單價
              </div>
              <div className="font-bold text-2xl text-[#6F6F6F] break-words w-[250px] h-[39px] pl-1">
                金額
              </div>
              <div className="font-bold text-2xl text-[#6F6F6F] break-words w-[250px] h-[39px] pl-1">
                備註
              </div>
            </div>
            {procurementInvoiceDetailListTemplate(
              invoice.procurementInvoiceDetailList
            )}
          </div>
        ) : null}
        <Dialog isOpen={isOpen} cancel={() => closeDialog()}>
          <div className="flex flex-col items-center">
            <div className="text-2xl m-4">
              確定刪除到貨單#{selectedInvoiceID}？
            </div>
            <div className="flex">
              <button className="btn btn-lg m-4" onClick={() => closeDialog()}>
                取消
              </button>
              <button
                className="btn btn-lg btn-error m-4"
                onClick={() => {
                  deleteProcurementInvoice(procurementID, selectedInvoiceID);
                }}
              >
                刪除
              </button>
            </div>
          </div>
        </Dialog>
      </div>
    ));
  };

  return (
    <div className="mx-20 mt-10">
      <ProcurementInvoiceTableHeader
        isEditable={isEditableState}
        canChangeEditable={canChangeEditable}
        changeEditableHandler={(isEditable) => {
          changeEditableHandler(isEditable);
          setIsEditableState(isEditable);
        }}
        createProcurementInvoiceHandler={() => {
          navigate(`/procurement/invoice/edit/${procurementID}`);
        }}
      />
      <div className="my-5">
        {/*Header*/}
        <div className="bg-[#E2E8F0] rounded-t-2xl h-[79px] flex items-center justify-around">
          <div className="w-[30px] h-[30px]">{/*drop arrow*/}</div>
          <div className="text-3xl font-bold w-[72px]">單號</div>
          <div className="text-3xl font-bold w-[180px]">發票號碼</div>
          <div className="text-3xl font-bold w-[180px]">到貨日期</div>
          <div className="text-3xl font-bold w-[135px]">開單人</div>
          <div className="text-3xl font-bold w-[180px]">$合計</div>
          <div className="text-3xl font-bold w-[180px]">$稅金</div>
          <div className="text-3xl font-bold w-[180px]">$總額</div>
          <div className="w-[35px] h-[35px]">{/*edit icon*/}</div>
          <div className="w-[35px] h-[35px]">{/*delete icon*/}</div>
        </div>

        {/*Body*/}
        {procurementInvoiceListTemplate()}

        {/*Footer*/}
        <div className="bg-[#E2E8F0] rounded-b-2xl h-[79px] flex items-center justify-around">
          <div className="w-[30px] h-[30px]">{/*drop arrow*/}</div>
          <div className="text-3xl w-[72px]">{/*單號*/}</div>
          <div className="text-3xl w-[180px]">{/*發票號碼*/}</div>
          <div className="text-3xl w-[180px]">{/*到貨日期*/}</div>
          <div className="text-3xl w-[135px]">{/*開單人*/}</div>
          <div className="text-3xl w-[180px]">
            ${beforeTaxTotal?.toLocaleString()}
          </div>
          <div className="text-3xl w-[180px]">
            ${taxTotal?.toLocaleString()}
          </div>
          <div className="text-3xl w-[180px]">
            ${totalAmountTotal?.toLocaleString()}
          </div>
          <div className="w-[35px] h-[35px]">{/*edit icon*/}</div>
          <div className="w-[35px] h-[35px]">{/*delete icon*/}</div>
        </div>
      </div>
    </div>
  );
};

export default ProcurementInvoiceTable;
