import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Http from "../services/http";

import ProcurementDetailHeader from "../components/ProcurementDetailHeader";
import ErrorNotification from "../components/ErrorNotification";
import Loading from "../components/Loading";

import ProcurementInvoiceTable from "../components/ProcurementInvoiceTable";

import ProcurementStatus from "../components/ProcurementStatus";

const ProcurementDetail = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [procurementData, setProcurementData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const permissions = JSON.parse(localStorage.getItem("permissions"));

  const { id } = useParams();

  const fetchProcurementData = async () => {
    setLoading(true);
    try {
      const response = await Http.get(
        `auth/procurement/info?procurementID=${id}`
      );

      console.log("response:", response);

      setProcurementData(response);
    } catch (error) {
      console.error("Error fetching data:", error);
      setErrorMessage("獲取採購單資料錯誤，請稍後再試");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchProcurementData();
  }, []);

  const handleApprove = useCallback(async () => {
    // TODO: test with manager account
    setLoading(true);
    try {
      await Http.post(`auth/procurement/approve`, { id: Number(id) });
    } catch (error) {
      console.error("Error for approving:", error);
      setErrorMessage("批准採購單資料錯誤，請稍後再試");
    }
    fetchProcurementData();
    setLoading(false);
  }, [navigate]);

  const handleDiscard = useCallback(async () => {
    // TODO: test with manager account
    setLoading(true);
    try {
      await Http.delete(`auth/procurement/${id}`);
    } catch (error) {
      console.error("Error for discard:", error);
      setErrorMessage("刪除採購單資料錯誤，請稍後再試");
    }
    fetchProcurementData();
    setLoading(false);
    navigate("/procurement");
  }, [navigate]);

  const handleEdit = useCallback(async () => {
    navigate(`/procurement/edit/${id}`);
  }, [navigate]);

  const handleChangeEditable = async (checked) => {
    setLoading(true);
    try {
      await Http.post(`auth/procurement/editable`, {
        id: Number(id),
        editable: Boolean(checked),
      });
    } catch (error) {
      console.error("Error for editable:", error);
      setErrorMessage("變更編輯狀態錯誤，請稍後再試");
    }
    fetchProcurementData();
    setLoading(false);
  };

  const procurementDetailTemplate = useMemo(() => {
    return procurementData?.procurementDetail?.map((detail) => (
      <div
        className="flex justify-between items-center mt-1 mb-1 pl-2"
        key={detail.id}
      >
        <div className="text-xl break-words w-[250px] min-h-[39px]">
          {detail.itemName}
        </div>
        <div className="text-xl break-words w-[250px] min-h-[39px] pl-1">
          {detail.specification}
        </div>
        <div className="text-xl break-words w-[80px] min-h-[39px] pl-1">
          {detail.quantity?.toLocaleString()}
        </div>
        <div className="text-xl break-words w-[80px] min-h-[39px] pl-1">
          {detail.unit}
        </div>
        <div className="text-xl break-words w-[100px] min-h-[39px] pl-1">
          {detail.weight?.toLocaleString()}
        </div>
        <div className="text-xl break-words w-[120px] min-h-[39px] pl-1">
          ${detail.unitPrice?.toLocaleString()}
        </div>
        <div className="text-xl break-words w-[250px] min-h-[39px] pl-1">
          ${detail.totalAmount?.toLocaleString()} (
          {detail.isIncludeTax ? "含稅" : "未稅"})
        </div>
        <div className="text-xl break-words w-[250px] min-h-[39px] pl-1">
          {detail.remark}
        </div>
      </div>
    ));
  }, [procurementData]);

  return (
    <div className="w-full">
      {isLoading && <Loading />}
      <ProcurementDetailHeader
        procurementID={id}
        approve={handleApprove}
        disgard={handleDiscard}
        edit={handleEdit}
        changeEditable={handleChangeEditable}
        canApprove={permissions.includes("approve_procurement")}
        canChangeEditable={permissions.includes("procurement_editable")}
        approveStatus={procurementData.approveStatus}
        isEditable={procurementData.editable}
      />
      {errorMessage ? <ErrorNotification message={errorMessage} /> : null}
      <div className="border border-gray-400 pt-2 pl-8 pr-8 pb-2 mx-20 my-5">
        <div className="flex flex-col">
          <div className="flex">
            <div className="flex items-center pl-2.5 w-[296px] h-[56px]">
              <div className="font-bold text-header">廠商</div>
            </div>
            <div className="flex items-center w-[590px] h-[56px]">
              <div className="text-2xl">{procurementData.supplierName}</div>
            </div>
            <div className="flex items-center pl-2.5 w-[296px] h-[56px]">
              <div className="font-bold text-header">核准人</div>
            </div>
            <div className="flex items-center w-[590px] h-[56px]">
              <div className="text-2xl">{procurementData.approveUserName}</div>
            </div>
          </div>

          <div className="flex">
            <div className="flex items-center pl-2.5 w-[296px] h-[56px]">
              <div className="font-bold text-header">工程</div>
            </div>
            <div className="flex items-center w-[590px] h-[56px]">
              <div className="text-2xl">
                {procurementData.constructionWorkName}
              </div>
            </div>
            <div className="flex items-center pl-2.5 w-[296px] h-[56px]">
              <div className="font-bold text-header">核准時間</div>
            </div>
            <div className="flex items-center w-[590px] h-[56px]">
              <div className="text-2xl">{procurementData.approveTime}</div>
            </div>
          </div>

          <div className="flex">
            <div className="flex items-center pl-2.5 w-[296px] h-[56px]">
              <div className="font-bold text-header">開單人</div>
            </div>
            <div className="flex items-center w-[590px] h-[56px]">
              <div className="text-2xl">{procurementData.createUserName}</div>
            </div>
            <div className="flex items-center pl-2.5 w-[296px] h-[56px]">
              <div className="font-bold text-header">最後編輯人</div>
            </div>
            <div className="flex items-center w-[590px] h-[56px]">
              <div className="text-2xl">
                {procurementData.lastModifiedUserName}
              </div>
            </div>
          </div>

          <div className="flex">
            <div className="flex items-center pl-2.5 w-[296px] h-[56px]">
              <div className="font-bold text-header">開單日期</div>
            </div>
            <div className="flex items-center w-[590px] h-[56px]">
              <div className="text-2xl">{procurementData.createTime}</div>
            </div>
            <div className="flex items-center pl-2.5 w-[296px] h-[56px]">
              <div className="font-bold text-header">最後編輯時間</div>
            </div>
            <div className="flex items-center w-[590px] h-[56px]">
              <div className="text-2xl">{procurementData.lastModifiedTime}</div>
            </div>
          </div>

          <div className="flex">
            <div className="flex items-center pl-2.5 w-[296px] h-[56px]">
              <div className="font-bold text-header">採購單狀態</div>
            </div>
            <div className="flex items-center w-[590px] h-[56px]">
              <ProcurementStatus status={procurementData.approveStatus} />
              <ProcurementStatus status={procurementData.editable} />
              <ProcurementStatus status={procurementData.purchaseStatus} />
            </div>
            <div className="flex items-center w-[296px] h-[56px]"></div>
            <div className="flex items-center w-[590px] h-[56px]"></div>
          </div>
        </div>

        <div className="divider"></div>

        <div className="flex flex-col">
          <div className="flex justify-between items-center mb-2 pl-2">
            <div className="font-bold text-3xl break-words w-[250px] h-[39px]">
              品名
            </div>
            <div className="font-bold text-3xl break-words w-[250px] h-[39px] pl-1">
              規格
            </div>
            <div className="font-bold text-3xl break-words w-[80px] h-[39px] pl-1">
              數量
            </div>
            <div className="font-bold text-3xl break-words w-[80px] h-[39px] pl-1">
              單位
            </div>
            <div className="font-bold text-3xl break-words w-[100px] h-[39px] pl-1">
              重量
            </div>
            <div className="font-bold text-3xl break-words w-[120px] h-[39px] pl-1">
              單價
            </div>
            <div className="font-bold text-3xl break-words w-[250px] h-[39px] pl-1">
              金額
            </div>
            <div className="font-bold text-3xl break-words w-[250px] h-[39px] pl-1">
              備註
            </div>
          </div>
          {procurementDetailTemplate}
        </div>

        <div className="divider"></div>

        <div className="flex justify-around items-center">
          <div className="font-bold text-header">合計</div>
          <div className="text-2xl">
            ${procurementData.beforeTax?.toLocaleString()}
          </div>
          <div className="font-bold text-header">稅金</div>
          <div className="text-2xl">
            ${procurementData.tax?.toLocaleString()}
          </div>
          <div className="font-bold text-header">總額</div>
          <div className="text-2xl">
            ${procurementData.totalAmount?.toLocaleString()}
          </div>
        </div>
      </div>
      <ProcurementInvoiceTable
        procurementID={id}
        isEditable={procurementData.invoiceEditable}
        canChangeEditable={permissions.includes("procurement_editable")}
        setLoading={(loading) => {
          setLoading(loading);
        }}
      />
    </div>
  );
};

export default ProcurementDetail;
