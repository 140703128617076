/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import Http from "../services/http";
import * as ExcelJS from "exceljs";
import {
  purchaseStatusLookup,
  approveStatusLookup,
} from "../constants/ProcurementConstants";
import SearchSelector from "./SearchSelector";
import Loading from "./Loading";

const ExportAllProcurementByConsturction = ({ cancel }) => {
  const [selectedConstructionID, setSelectedConstructionID] = useState("");
  const [constructionOptions, setConstructionOptions] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const fetchConstructionWork = async () => {
    try {
      const response = await Http.get(`auth/constructionWork/list`);
      setConstructionOptions(response.constructionWorkList);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchConstructionWork();
  }, []);

  const fetchProcurementList = async (constructionWorkID) => {
    try {
      const response = await Http.get(
        `auth/procurement/exports?constructionWorkID=${constructionWorkID}`
      );
      return response;
    } catch (error) {
      console.error("Error fetching data:", error);
      return {};
    }
  };

  const handleExport = async () => {
    if (!selectedConstructionID) {
      return;
    }
    setLoading(true);
    console.log(selectedConstructionID);
    const selectedConstruction = constructionOptions.find(
      (opt) => opt.id === selectedConstructionID
    );
    console.log(selectedConstruction.name);
    const procurementList = await fetchProcurementList(selectedConstructionID);
    console.log(procurementList);

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(selectedConstruction.name);

    const headerData = [
      [selectedConstruction.name],
      [
        "採購單編號",
        "日期",
        "品名",
        "規格",
        "數量",
        "單位",
        "重量(kg)",
        "單價",
        "金額",
        "廠商名稱",
        "採購狀況",
        "備註",
      ],
    ];

    // 寫入標頭
    headerData.forEach((e) => worksheet.addRow(e));

    // 合併儲存格
    worksheet.mergeCells("A1:L1");

    // 調整標頭字型＆對齊
    worksheet.getRow(1).font = { bold: true, size: 18 };
    worksheet.getRow(1).alignment = {
      horizontal: "center",
      vertical: "center",
    };
    worksheet.getRow(2).font = { bold: true, size: 15 };
    worksheet.getRow(2).alignment = {
      horizontal: "center",
      vertical: "center",
    };

    // 調整各列寬
    worksheet.getColumn("A").width = 15;
    worksheet.getColumn("B").width = 15;
    worksheet.getColumn("C").width = 25;
    worksheet.getColumn("D").width = 25;
    worksheet.getColumn("E").width = 12;
    worksheet.getColumn("F").width = 12;
    worksheet.getColumn("G").width = 15;
    worksheet.getColumn("H").width = 12;
    worksheet.getColumn("I").width = 15;
    worksheet.getColumn("J").width = 20;
    worksheet.getColumn("K").width = 15;
    worksheet.getColumn("L").width = 15;

    procurementList.forEach((p, pIndex) => {
      p.procurementDetail.forEach((detail, detailIndex) => {
        const newRow = [];
        if (detailIndex === 0) {
          newRow.push(`#${p.id}`);
          newRow.push(p.createTime.split(" ")[0]);
        } else {
          newRow.push("");
          newRow.push("");
        }
        newRow.push(detail.itemName);
        newRow.push(detail.specification);
        newRow.push(detail.quantity);
        newRow.push(detail.unit);
        newRow.push(detail.weight);
        newRow.push(detail.unitPrice);
        newRow.push(detail.totalAmount);
        if (detailIndex === 0) {
          newRow.push(p.supplierName);
          newRow.push(purchaseStatusLookup[p.purchaseStatus]);
        } else {
          newRow.push("");
          newRow.push("");
        }
        newRow.push(detail.remark);

        worksheet.addRow(newRow);
      });
    });

    // 匯出
    const content = await workbook.xlsx.writeBuffer();
    const link = document.createElement("a");
    const blobData = new Blob([content], {
      type: "application/vnd.ms-excel;charset=utf-8;",
    });
    link.download = `${selectedConstruction.name}_採購單.xlsx`;
    link.href = URL.createObjectURL(blobData);
    link.click();
    setLoading(false);
  };

  return (
    <div className="flex flex-col items-center">
      {isLoading && <Loading />}
      <div className="font-bold text-3xl m-4">匯出該工程所有 已核准 採購單</div>
      <div className="w-full text-2xl">
        <SearchSelector
          title="請選擇工程"
          value={selectedConstructionID}
          itemList={constructionOptions}
          onChange={(item) => {
            setSelectedConstructionID(item.id);
          }}
        ></SearchSelector>
      </div>

      <div className="flex">
        <button className="btn btn-lg mt-4 mr-4" onClick={cancel}>
          取消
        </button>
        <button
          className="btn btn-lg btn-success mt-4 ml-4"
          onClick={handleExport}
        >
          匯出
        </button>
      </div>
    </div>
  );
};

export default ExportAllProcurementByConsturction;
