import React, { useEffect, useState } from "react";
import _ from "lodash";

import Header from "../components/Header";
import Dialog from "../components/Dialog";
import Table from "../components/Table";
import Loading from "../components/Loading";

import http from "../services/http";

import { headerList } from "../constants/SupplierConstants";

/*
TODO: 聯絡人資訊欄位
*/

const Supplier = () => {
  const [isLoading, setLoading] = useState(false);
  const [supplierList, setSupplierList] = useState([]);
  const [searchedSupplierList, setSearchedSupplierList] = useState([]);
  const [supplier, setSupplier] = useState({});
  const [isOpen, setModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isAddnew, setIsAddnew] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const fetchSupplierList = async () => {
      try {
        const response = await http.get("auth/supplier/list");
        if (response) {
          setSupplierList(response.supplierList);
          setSearchedSupplierList(response.supplierList);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchSupplierList();
  }, [reload]);

  const searchOnChange = (query) => {
    if (query.length === 0) {
      setSearchedSupplierList(supplierList);
    }

    const lowerQuery = query.toLowerCase();

    setSearchedSupplierList(
      supplierList.filter((item) => {
        return (
          item.id.toLowerCase().includes(lowerQuery) ||
          item.industry.toLowerCase().includes(lowerQuery) ||
          item.taxID.toLowerCase().includes(lowerQuery) ||
          item.name?.toLowerCase()?.includes(lowerQuery) ||
          item.address?.toLowerCase()?.includes(lowerQuery) ||
          item.tel?.toLowerCase()?.includes(lowerQuery) ||
          item.fax?.toLowerCase()?.includes(lowerQuery)
        );
      })
    );
  };
  const handleAddNew = () => {
    setSupplier({
      name: "",
      address: "",
      tel: "",
      fax: "",
      taxID: "",
      industry: "",
      bankCode: "",
      bankName: "",
      bankAccount: "",
      remark: "",
      contacts: [],
    });
    setIsAddnew(true);
    setIsEdit(false);
    setModalOpen(true);
  };

  const handleViewDetail = (supplierID) => {
    const getSupplierInfo = async (supplierID) => {
      try {
        setLoading(true);
        const response = await http.get("auth/supplier/info", {
          params: { supplierID: supplierID },
        });
        if (response) {
          setSupplier(response);
          setIsAddnew(false);
          setIsEdit(false);
          setModalOpen(true);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    getSupplierInfo(supplierID);
  };

  const handleEditDetail = () => {
    setIsAddnew(false);
    setIsEdit(true);
    setModalOpen(true);
  };

  const closeDialog = () => {
    setModalOpen(false);
  };

  const getContactsInfo = () =>
    // eslint-disable-line
    _.map(
      supplier.contacts,
      (contact) =>
        `${contact.name} | ${contact.jobTitle} | ${contact.mobile} | 分機 ${contact.extensionNumber} `
    );

  const handleSupplierChange = (event) => {
    const { name, value } = event.target;
    setSupplier({
      ...supplier,
      [name]: value,
    });
  };

  const createNewSupplier = async (newSupplier) => {
    try {
      setLoading(true);
      const response = await http.post("auth/supplier", {
        // eslint-disable-line
        name: newSupplier.name,
        address: newSupplier.address,
        tel: newSupplier.tel,
        fax: newSupplier.fax,
        taxID: newSupplier.taxID,
        industry: newSupplier.industry,
        bankCode: newSupplier.bankCode,
        bankName: newSupplier.bankName,
        bankAccount: newSupplier.bankAccount,
        remark: newSupplier.remark,
      });
      // if (response) {}
    } catch (error) {
      console.log(error);
      alert("新增失敗");
    }
    setReload(!reload);
    closeDialog();
    setLoading(false);
  };

  const updateSupplier = async (supplier) => {
    try {
      setLoading(true);
      const response = await http.put("auth/supplier", {
        // eslint-disable-line
        id: supplier.id,
        name: supplier.name,
        address: supplier.address,
        tel: supplier.tel,
        fax: supplier.fax,
        taxID: supplier.taxID,
        industry: supplier.industry,
        bankCode: supplier.bankCode,
        bankName: supplier.bankName,
        bankAccount: supplier.bankAccount,
        remark: supplier.remark,
        contacts: null,
      });
      // if (response) {}
    } catch (error) {
      console.log(error);
      alert("更新失敗");
    }
    setReload(!reload);
    closeDialog();
    setLoading(false);
  };

  return (
    <div className="w-full">
      {isLoading && <Loading />}
      <Header
        title="廠商列表"
        addnew={() => {
          handleAddNew();
        }}
        search={searchOnChange}
      />
      <Table
        headerList={headerList}
        bodyList={searchedSupplierList}
        viewDetail={handleViewDetail}
      />
      <Dialog isOpen={isOpen} cancel={() => closeDialog()}>
        <div className="flex flex-col">
          <div className="flex text-3xl font-bold mb-2">
            {isAddnew || isEdit ? (
              <input
                type="text"
                placeholder="請輸入廠商名稱"
                className="flex-grow focus:outline-none focus:ring rounded-lg"
                name="name"
                value={supplier.name}
                onChange={handleSupplierChange}
              />
            ) : (
              supplier.name
            )}
          </div>

          <div className="flex flex-col">
            <div className="flex items-center m-2">
              <div className="text-xl font-bold">行業別：</div>
              {isAddnew || isEdit ? (
                <input
                  type="text"
                  placeholder="請輸入行業別"
                  className="input input-bordered w-full max-w-xs"
                  name="industry"
                  value={supplier.industry}
                  onChange={handleSupplierChange}
                />
              ) : (
                <span className="font-normal">{supplier.industry}</span>
              )}
            </div>
            <div className="flex items-center m-2">
              <span className="text-xl font-bold">統一編號：</span>
              {isAddnew || isEdit ? (
                <input
                  type="text"
                  placeholder="請輸入統一編號"
                  className="input input-bordered w-full max-w-xs m-1"
                  name="taxID"
                  value={supplier.taxID}
                  onChange={handleSupplierChange}
                />
              ) : (
                <span className="font-normal">{supplier.taxID}</span>
              )}
            </div>
            <div className="flex items-center m-2">
              <span className="text-xl font-bold">地址：</span>
              {isAddnew || isEdit ? (
                <input
                  type="text"
                  placeholder="請輸入廠商地址"
                  className="input input-bordered w-full max-w-xs m-1"
                  name="address"
                  value={supplier.address}
                  onChange={handleSupplierChange}
                />
              ) : (
                <span className="font-normal">{supplier.address}</span>
              )}
            </div>
            <div className="flex items-center m-2">
              <span className="text-xl font-bold">電話：</span>
              {isAddnew || isEdit ? (
                <input
                  type="text"
                  placeholder="請輸入廠商電話"
                  className="input input-bordered w-full max-w-xs m-1"
                  name="tel"
                  value={supplier.tel}
                  onChange={handleSupplierChange}
                />
              ) : (
                <span className="font-normal">{supplier.tel}</span>
              )}
            </div>
            <div className="flex items-center m-2">
              <span className="text-xl font-bold">傳真：</span>
              {isAddnew || isEdit ? (
                <input
                  type="text"
                  placeholder="請輸入廠商傳真號嗎"
                  className="input input-bordered w-full max-w-xs m-1"
                  name="fax"
                  value={supplier.fax}
                  onChange={handleSupplierChange}
                />
              ) : (
                <span className="font-normal">{supplier.fax}</span>
              )}
            </div>

            <div className="flex flex-col m-2">
              <span className="text-xl font-bold">匯款帳戶：</span>
              {isAddnew || isEdit ? (
                <div className="flex flex-col">
                  <input
                    type="text"
                    placeholder="請輸入廠商匯款銀行"
                    className="input input-bordered w-full max-w-xs m-1"
                    name="bankName"
                    value={supplier.bankName}
                    onChange={handleSupplierChange}
                  />
                  <input
                    type="text"
                    placeholder="請輸入廠商匯款銀行代碼"
                    className="input input-bordered w-full max-w-xs m-1"
                    name="bankCode"
                    value={supplier.bankCode}
                    onChange={handleSupplierChange}
                  />
                  <input
                    type="text"
                    placeholder="請輸入廠商匯款帳戶"
                    className="input input-bordered w-full max-w-xs m-1"
                    name="bankAccount"
                    value={supplier.bankAccount}
                    onChange={handleSupplierChange}
                  />
                </div>
              ) : (
                <span className="font-normal">
                  {supplier.bankName} ({supplier.bankCode}) -{" "}
                  {supplier.bankAccount}
                </span>
              )}
            </div>

            <div className="flex flex-col m-2">
              <div className="text-xl font-bold">備註：</div>
              {isAddnew || isEdit ? (
                <textarea
                  placeholder="備註..."
                  className="textarea textarea-bordered font-normal m-1 w-full"
                  name="remark"
                  value={supplier.remark}
                  onChange={handleSupplierChange}
                />
              ) : (
                <div className="font-normal whitespace-pre-line">
                  {supplier.remark}
                </div>
              )}
            </div>

            {/* <div className="flex flex-col text-xl font-bold m-2">聯絡人：
                  {
                    isEdit?
                    <input type="text" placeholder="請輸入廠商聯絡人" className="flex-grow focus:outline-none focus:ring rounded-lg font-normal" value={isEdit?supplier.address:""}/>
                    :
                    _.map(getContactsInfo(), (c)=>(
                      <div className="font-normal">{c}</div>
                    ))
                  }
                  </div> */}
          </div>

          <div className="flex justify-center mt-2">
            {isAddnew ? (
              <button
                className="px-6 py-2 border border-black border-solid rounded-xl outline-none text-2xl hover:bg-gray-200"
                onClick={() => {
                  console.log(supplier);
                  createNewSupplier(supplier);
                }}
              >
                <span className="">新增</span>
              </button>
            ) : isEdit ? (
              <div>
                <button
                  className="mr-5 px-6 py-2 border border-black border-solid rounded-xl outline-none text-2xl hover:bg-gray-200"
                  onClick={() => {
                    console.log(supplier);
                    closeDialog();
                  }}
                >
                  <span className="">取消</span>
                </button>

                <button
                  className="ml-5 px-6 py-2 bg-[#5ECC69] rounded-xl outline-none text-[#FFFFFF] text-2xl"
                  onClick={() => {
                    console.log(supplier);
                    updateSupplier(supplier);
                  }}
                >
                  <span className="">儲存</span>
                </button>
              </div>
            ) : (
              <button
                className="px-6 py-2 border border-black border-solid rounded-xl outline-none text-2xl hover:bg-gray-200"
                onClick={() => {
                  handleEditDetail();
                }}
              >
                <span className="">編輯</span>
              </button>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default Supplier;
