/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function englishToChineseMonth(englishMonth) {
  const monthMap = {
    January: "一月",
    February: "二月",
    March: "三月",
    April: "四月",
    May: "五月",
    June: "六月",
    July: "七月",
    August: "八月",
    September: "九月",
    October: "十月",
    November: "十一月",
    December: "十二月",
  };

  return monthMap[englishMonth] || englishMonth;
}

function englishToNumMonth(englishMonth) {
  const monthMap = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };

  return monthMap[englishMonth] || 0;
}

const StatisticMonthCost = ({ monthCostList, handleYearOnChange }) => {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 10 }, (_, index) => currentYear - index);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const handleYearChange = (event) => {
    const newYearSelected = parseInt(event.target.value);
    setSelectedYear(newYearSelected);
    handleYearOnChange(newYearSelected);
  };

  const yearTotalCost = monthCostList.reduce((acc, current) => {
    return acc + current.costAmount;
  }, 0);

  return (
    <div className="w-4/5 mt-3">
      <div className="flex justify-between items-center">
        <select
          className="select select-bordered select-md"
          value={selectedYear}
          onChange={handleYearChange}
        >
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
        <span className="text-2xl">{`該年總支出：${yearTotalCost?.toLocaleString()}`}</span>
      </div>
      <div className="flex flex-wrap justify-between">
        {monthCostList.map((item) => (
          <button
            key={item.month}
            className="btn btn-outline mt-5 w-22.5 h-48"
            onClick={() => {
              navigate(`${selectedYear}/${englishToNumMonth(item.month)}`);
            }}
          >
            <div className="flex flex-col">
              <span className="font-bold text-3xl">
                {englishToChineseMonth(item.month)}
              </span>
              <span className="text-2xl">共支出</span>
              <span className="text-2xl">{`${item.costAmount?.toLocaleString()}元`}</span>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default StatisticMonthCost;
