import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../components/Loading";
import StatisticDetailTable from "../components/StatisticDetailTable";
import http from "../services/http";

const StatisticSupplierDetail = () => {
  const [detailList, setDetailList] = useState([]);
  const [supplierInfo, setSupplierInfo] = useState({});
  const [isLoading, setLoading] = useState(false);

  const { id, year, month } = useParams();

  const fetchSupplierInfo = async (id) => {
    setLoading(true);
    try {
      const response = await http.get(`auth/supplier/info?supplierID=${id}`);
      if (response) {
        setSupplierInfo(response);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchSupplierDetail = async (year, month, id) => {
    setLoading(true);
    try {
      const response = await http.get(
        `auth/statistic/supplier/detailList?year=${year}&month=${month}&supplierID=${id}`
      );
      if (response) {
        setDetailList(response);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchSupplierInfo(id);
    fetchSupplierDetail(year, month, id);
  }, []);

  return (
    <div className="w-full">
      {isLoading && <Loading />}
      <div className="mx-10 mt-10 text-5xl font-bold">
        各廠商支出統計-{supplierInfo.name}
      </div>
      <StatisticDetailTable year={year} month={month} detailList={detailList} />
    </div>
  );
};

export default StatisticSupplierDetail;
