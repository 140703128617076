/* eslint-disable react/prop-types */
import React, { useState } from "react";
import Dialog from "./Dialog";

const OtherExpenditureHeader = ({ title, edit, disgard }) => {
  const [isOpen, setModalOpen] = useState(false);

  const closeDialog = () => {
    setModalOpen(false);
  };

  return (
    <div className="mx-20 mt-10 flex justify-between">
      <div className="flex text-center">
        <div className="text-5xl font-bold relative top-2">{title}</div>
      </div>
      <div className="flex">
        <button
          className="btn btn-lg btn-outline btn-error ml-4"
          onClick={() => {
            setModalOpen(true);
          }}
        >
          刪除
        </button>
        <button className="btn btn-lg btn-outline btn-info ml-4" onClick={edit}>
          編輯
        </button>
      </div>
      <Dialog isOpen={isOpen} cancel={() => closeDialog()}>
        <div className="flex flex-col items-center">
          <div className="text-2xl m-4">確定刪除此筆支出？</div>
          <div className="flex">
            <button className="btn btn-lg m-4" onClick={() => closeDialog()}>
              取消
            </button>
            <button className="btn btn-lg btn-error m-4" onClick={disgard}>
              刪除
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default OtherExpenditureHeader;
