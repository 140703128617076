import {
  Outlet,
  Navigate,
  useLocation,
  useLoaderData,
} from "react-router-dom";
import React from "react";
import Nav from "./Nav";
import _ from "lodash";

const PrivateRoute = () => {
  const loadData = useLoaderData();
  const location = useLocation();
  const currentPath = location.pathname;
  const auth_token = localStorage.getItem("auth_token");
  const initLogin = JSON.parse(localStorage.getItem("initLogin"));
  const redicrectPath = auth_token ? initLogin ? "/register" : currentPath : "/login";

  return _.isEqual(redicrectPath, currentPath) ? (
    <main className="h-full w-full flex flex-col bg-white">
      <Nav userInfo={loadData} />
      <div className="flex flex-1">
        <Outlet />
      </div>
    </main>
  ) : (
    <Navigate to={redicrectPath} />
  ); 
};

export default PrivateRoute;
