/* eslint-disable react/prop-types */
import React from "react";
import { useNavigate } from "react-router-dom";

const headerList = [
  {
    name: "日期",
    key: "date",
  },
  {
    name: "支出類型",
    key: "typeName",
  },
  {
    name: "單號",
    key: "no",
  },
  {
    name: "支付對象",
    key: "payeeName",
  },
  {
    name: "總計金額",
    key: "totalAmount",
  },
];

function typeToChinese(type) {
  const typeMap = {
    procurement: "採購單",
    employeeInvoice: "請款單",
    otherExpenditure: "其他支出",
  };

  return typeMap[type] || type;
}

const StatisticDetailTable = ({ year, month, detailList }) => {
  const navigate = useNavigate();
  const monthTotalCost = detailList.reduce((acc, current) => {
    return acc + current.totalAmount;
  }, 0);

  const formatDetailList = detailList.map((item) => ({
    ...item,
    id: item.no,
    typeName: typeToChinese(item.type),
    no: `#${item.no}`,
    totalAmount: item.totalAmount?.toLocaleString(),
  }));

  const viewDetail = (type, id) => {
    if (type === "procurement") {
      navigate(`/procurement/detail/${id}`);
    } else if (type === "employeeInvoice") {
      navigate(`/employeeInvoice/detail/${id}`);
    } else if (type === "otherExpenditure") {
      navigate(`/account-payable/detail/otherExpenditure/${id}`);
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center m-10">
        <div className="text-3xl">
          {`${year}`}年{`${month}`}月份
        </div>
        <span className="text-2xl">{`該月總支出：${monthTotalCost?.toLocaleString()}`}</span>
      </div>
      <div className="overflow-x-auto mt-10 ">
        <table className="table">
          <thead className="text-center">
            <tr className=" text-[#91949D] font-medium text-xl pb-6 border-b border-[#91949D] border-solid">
              {headerList.map((header, index) => (
                <td key={index}>{header.name}</td>
              ))}
            </tr>
          </thead>
          <tbody className="text-center">
            {formatDetailList.map((detail) => (
              <tr
                key={detail.id}
                onClick={() => viewDetail(detail.type, detail.id)}
                className="text-lg border-b border-[#91949D] border-solid hover:bg-[#C4E1FF] odd:bg-[#ECF5FF]"
              >
                {headerList.map((header) => {
                  const data = detail[header.key];
                  if (data) {
                    return <td key={data.id}>{data}</td>;
                  } else {
                    return <td key={data.id}>{""}</td>;
                  }
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StatisticDetailTable;
