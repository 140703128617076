/* eslint-disable react/prop-types */
import React from "react";
import Http from "../services/http";
import * as ExcelJS from "exceljs";
import {
  purchaseStatusLookup,
  approveStatusLookup,
} from "../constants/ProcurementConstants";

const ExportProcurement = ({ procurementID }) => {
  const fetchProcurementData = async () => {
    try {
      const response = await Http.get(
        `auth/procurement/info?procurementID=${procurementID}`
      );
      return response;
    } catch (error) {
      console.error("Error fetching data:", error);
      return {};
    }
  };

  const handleExport = async () => {
    const procurementData = await fetchProcurementData();

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("採購單");

    const templateData = [
      ["華瑞/維瑞機械有限公司"],
      [`【採購單 #${procurementID}】`],
      ["工程名稱：", "", "", "", "", "開單日期：", "", ""],
      ["廠商名稱：", "", "", "", "", "採購狀況：", "", ""],
      ["品名", "規格", "數量", "單位", "重量(kg)", "單價", "金額", "備註"],
      ["", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", ""],
      ["合計金額：", "", "稅金：", "", "", "總計金額：", "", ""],
      ["核准人：", "", "核准時間：", "", "", "核准狀態：", "", ""],
    ];

    // 寫入標頭
    templateData.forEach((e) => worksheet.addRow(e));

    // 合併儲存格
    worksheet.mergeCells("A1:H1");
    worksheet.mergeCells("A2:H2");
    //worksheet.mergeCells("D13", "E13");

    const headerCellIndex = [
      "A1",
      "A2",
      "A3",
      "A4",
      "A5",
      "A12",
      "A13",
      "B5",
      "C5",
      "C12",
      "C13",
      "D5",
      "E5",
      "F3",
      "F4",
      "F5",
      "F12",
      "F13",
      "G5",
      "H5",
    ];

    // 調整標頭字型＆對齊
    for (const idx of headerCellIndex) {
      worksheet.getCell(idx).font = { bold: true, size: 13 };
      worksheet.getCell(idx).alignment = {
        horizontal: "center",
        vertical: "center",
      };
    }

    // 加入邊框
    worksheet.getRow(5).eachCell((c, cNum) => {
      c.border = {
        top: { style: "medium", color: { argb: "FF000000" } },
        bottom: { style: "medium", color: { argb: "FF000000" } },
      };
    });

    worksheet.getRow(12).eachCell((c, cNum) => {
      c.border = {
        top: { style: "medium", color: { argb: "FF000000" } },
        bottom: { style: "medium", color: { argb: "FF000000" } },
      };
    });

    // 調整各列寬
    worksheet.getColumn("A").width = 20;
    worksheet.getColumn("B").width = 25;
    worksheet.getColumn("C").width = 12;
    worksheet.getColumn("D").width = 10;
    worksheet.getColumn("E").width = 12;
    worksheet.getColumn("F").width = 12;
    worksheet.getColumn("G").width = 15;
    worksheet.getColumn("H").width = 15;

    // 填入資料
    worksheet.getCell("B3").value = String(
      procurementData.constructionWorkName
    );
    worksheet.getCell("B4").value = String(procurementData.supplierName);
    worksheet.getCell("G3").value = String(procurementData.createTime);
    worksheet.getCell("G4").value = String(
      purchaseStatusLookup[procurementData.purchaseStatus]
    );
    worksheet.getCell("B12").value = String(procurementData.beforeTax);
    worksheet.getCell("D12").value = String(procurementData.tax);
    worksheet.getCell("G12").value = String(procurementData.totalAmount);
    worksheet.getCell("B13").value = String(procurementData.approveUserName);
    worksheet.getCell("D13").value = String(procurementData.approveTime);
    worksheet.getCell("G13").value = String(
      approveStatusLookup[procurementData.approveStatus]
    );
    const rows = [];
    procurementData.procurementDetail.forEach((detail) => {
      const row = [
        String(detail.itemName),
        String(detail.specification),
        String(detail.quantity),
        String(detail.unit),
        String(detail.weight),
        String(detail.unitPrice),
        String(
          `${detail.totalAmount} (${detail.isIncludeTax ? "含稅" : "未稅"})`
        ),
        String(detail.remark),
      ];
      rows.push(row);
    });
    worksheet.insertRows(6, rows);

    // 匯出
    const content = await workbook.xlsx.writeBuffer();
    const link = document.createElement("a");
    const blobData = new Blob([content], {
      type: "application/vnd.ms-excel;charset=utf-8;",
    });
    link.download = `採購單_${procurementID}.xlsx`;
    link.href = URL.createObjectURL(blobData);
    link.click();
  };

  return (
    <button className="text-2xl text-btn-blue" onClick={handleExport}>
      匯出
    </button>
  );
};

export default ExportProcurement;
