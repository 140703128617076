/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";

/*
value is string, ex: id
onChange = (item) =>{}
*/
const SearchSelector = ({ title, value, itemList, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectItem, setSelectItem] = useState({});
  const [searchedItemList, setSearchedItemList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const searchSelectorRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        searchSelectorRef.current &&
        !searchSelectorRef.current.contains(event.target)
      ) {
        setIsOpen(false);
        cleanSearch();
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    setSearchedItemList(itemList);
    setSelectItem(itemList.find((item) => item.id === value));
  }, [itemList, value]);

  const searchOnChange = (query) => {
    if (query.length === 0) {
      console.log(itemList);
      setSearchedItemList(itemList);
    }

    const lowerQuery = query.toLowerCase();

    setSearchedItemList(
      itemList.filter((item) => {
        return item.name.toLowerCase().includes(lowerQuery);
      })
    );
  };

  const cleanSearch = () => {
    setSearchValue("");
    setSearchedItemList(itemList);
  };

  return (
    <div className={`dropdown w-full`} ref={searchSelectorRef}>
      <div
        className={"select select-bordered w-full text-2xl flex items-center"}
        onClick={() => {
          setIsOpen(!isOpen);
          cleanSearch();
        }}
        tabIndex={0}
      >
        {selectItem?.name ? selectItem.name : title}
      </div>

      {isOpen && (
        <div className="absolute overflow-auto z-50 w-full bg-white border items-center">
          <div className="relative flex items-center m-1 p-1">
            <input
              type="text"
              placeholder="搜尋..."
              className="w-5/6 border border-[#D0D0D0] border-solid bg-[#FFFFFF] py-2 px-12 rounded-xl outline-none text-2xl"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                searchOnChange(e.target.value);
              }}
            />
            <i className="absolute ml-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="34"
                viewBox="0 0 33 34"
                fill="none"
              >
                <path
                  d="M15.0692 26.5789C20.8989 26.5789 25.6248 21.6776 25.6248 15.6316C25.6248 9.58551 20.8989 4.6842 15.0692 4.6842C9.23955 4.6842 4.51367 9.58551 4.51367 15.6316C4.51367 21.6776 9.23955 26.5789 15.0692 26.5789Z"
                  stroke="#D0D0D0"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M28.264 29.3158L22.5244 23.3632"
                  stroke="#D0D0D0"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </i>
          </div>
          <div
            className={`bg-base-200 z-50 max-h-48 overflow-auto flex-col rounded-md w-full`}
          >
            <ul className="menu menu-compact">
              {searchedItemList.map((item, index) => {
                return (
                  <li
                    key={item.id}
                    tabIndex={index + 1}
                    onClick={() => {
                      onChange(item);
                      setSelectItem(item);
                      setIsOpen(false);
                    }}
                    className="border-b border-b-base-content/10 w-full"
                  >
                    <button>{item.name}</button>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchSelector;
