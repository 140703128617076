import './index.css';
import React, { Suspense, lazy } from 'react'; // eslint-disable-line
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
} from "react-router-dom";
import App from './App';
import Supplier from './pages/Supplier';
import Invoice from './pages/Invoice';
import InvoiceEdit from './pages/InvoiceEdit';
import InvoiceDetail from './pages/InvoiceDetail';
import WorkDaily from './pages/WorkDaily';
import Statistic from './pages/Statistic';
import StatisticConstructionList from './pages/StatisticConstructionList';
import StatisticConstructionOverview from './pages/StatisticConstructionOverview';
import StatisticConstructionDetail from './pages/StatisticConstructionDetail';
import StatisticSupplierList from './pages/StatisticSupplierList';
import StatisticSupplierOverview from './pages/StatisticSupplierOverview';
import StatisticSupplierDetail from './pages/StatisticSupplierDetail';
import StatisticComprehensiveOverview from './pages/StatisticComprehensiveOverview';
import StatisticComprehensiveConstruction from './pages/StatisticComprehensiveConstruction';
import OtherExpenditure from './pages/OtherExpenditure';
import OtherExpenditureEdit from './pages/OtherExpenditureEdit';
import AccountsPayable from './pages/AccountsPayable';
import AccountsPayableDetail from './pages/AccountsPayableDetail';
import Login from './pages/Login';
import CreateUser from './pages/CreateUser';
import Procurement from './pages/Procurement';
import ProcurementDetail from './pages/ProcurementDetail';
import ProcurementEdit from './pages/ProcurementEdit';
import Constructions from './pages/Constructions';
import RouterNotFound from './components/ErrorPage';
import Http from './services/http';
import PublicRoute from './components/PublicRoute';
import PrivateRoute from './components/PrivateRoute';
import WorkDailyInfo from './pages/WorkDailyInfo';
import AccountsPayableRedirect from './pages/AccountsPayableRedirect';
import ProcurementV2 from './pages/Procurement_v2';
import ProcurementInvoiceEdit from './pages/ProcurementInvoiceEdit';
// import reportWebVitals from './reportWebVitals';
const loadUserInfo = async () => {
  const auth_token = localStorage.getItem("auth_token");
  if (!auth_token){
    return {
      displayName: "",
      linePictureURL: "",
      premissions: [],
      userID:'',
    };
  }
  try {
    const userInfo = await Http.get('/auth/user/info');
    localStorage.setItem("lineUrl", userInfo.linePictureURL);
    localStorage.setItem("displayName", userInfo.displayName);
    localStorage.setItem("permissions", JSON.stringify(userInfo.permissions));
    localStorage.setItem("userID", userInfo.userID);
    return userInfo;
  } catch (error) {
    return {
      displayName: "",
      linePictureURL: "",
      premissions: [],
      userID:'',
    };
  }
};

const loadAutocomplete = async () => {
  try {
    const response = await Http.get(`auth/procurement/autocomplete`);
    localStorage.setItem("autocomplete", JSON.stringify(response));
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    return {};
  }
};

const root = ReactDOM.createRoot(document.getElementById("root"));
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<App />}>
      <Route path="/" loader={loadUserInfo} element={<PrivateRoute />}>
        <Route path="/procurement" element={<ProcurementV2 />} />
        <Route path="/procurement/detail/:id" element={<ProcurementDetail />} />
        <Route path="/procurement/edit/:id?" loader={loadAutocomplete} element={<ProcurementEdit />} />
        <Route path="/procurement/invoice/edit/:procurementID/:invoiceID?" loader={loadAutocomplete} element={<ProcurementInvoiceEdit />} />
        <Route path="/employeeInvoice" element={<Invoice />} />
        <Route path="/employeeInvoice/detail/:id" element={<InvoiceDetail />} />
        <Route path="/employeeInvoice/edit/:id?" element={<InvoiceEdit />} />
        <Route path="/employeeWorkDaily" element={<WorkDaily />} />
        <Route path="/employeeWorkDaily/info" element={<WorkDailyInfo />} />
        <Route path="/constructions" element={<Constructions />} />
        <Route path="/supplier" element={<Supplier />} />
        <Route path="/statistic" element={<Statistic />} />
        <Route path="/statistic/constructionWork" element={<StatisticConstructionList />} />
        <Route path="/statistic/constructionWork/:id" element={<StatisticConstructionOverview />} />
        <Route path="/statistic/constructionWork/:id/:year/:month" element={<StatisticConstructionDetail />} />
        <Route path="/statistic/supplier" element={<StatisticSupplierList />} />
        <Route path="/statistic/supplier/:id" element={<StatisticSupplierOverview />} />
        <Route path="/statistic/supplier/:id/:year/:month" element={<StatisticSupplierDetail />} />
        <Route path="/statistic/comprehensive" element={<StatisticComprehensiveOverview />} />
        <Route path="/statistic/comprehensive/:year/:month" element={<StatisticComprehensiveConstruction />} />
        <Route path="/account-payable" element={<AccountsPayableRedirect />}/>
        <Route path="/account-payable/:year/:month" element={<AccountsPayable />} />
        <Route path="/account-payable/detail/:type/:id/:year/:month" element={<AccountsPayableDetail />} />
        <Route path="/account-payable/detail/otherExpenditure/:id" element={<OtherExpenditure />} />
        <Route path="/account-payable/detail/otherExpenditure/edit/:id?" element={<OtherExpenditureEdit />} />
      </Route>
      <Route element={<PublicRoute />}>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<CreateUser />}/>
      </Route>

      <Route path="*" element={<RouterNotFound />} />
    </Route>
  )
);
root.render(<RouterProvider router={router} />);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
