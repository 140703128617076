export const headerList = [
  {
    name: "編號",
    key: "id",
  },
  {
    name: "行業別",
    key: "industry",
  },
  {
    name: "廠商名稱",
    key: "name",
  },
  {
    name: "統一編號",
    key: "taxID",
  },
  {
    name: "公司地址",
    key: "address",
  },
  {
    name: "電話",
    key: "tel",
  },
  {
    name: "傳真",
    key: "fax",
  },
];
