import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import http from "../services/http";
import Dialog from "../components/Dialog";
import { generatePeriodOptions } from "../utils/periodUtils";
import PeriodSelector from "../components/PeriodSelector";
import { extractPeriodID } from "../utils/periodUtils";
import moment from "moment";
import Http from "../services/http";

const statusOptions = [
  {
    status: "not_complete",
    statusName: "待填入員工工數",
    borderColorHex: "border-[#EB7E19]",
    textColorHex: "text-[#EB7E19]",
    bgColorHex: "bg-[#FAD6BC]",
  },
  {
    status: "day_off",
    statusName: "休假日",
    borderColorHex: "border-[#65278A]",
    textColorHex: "text-[#65278A]",
    bgColorHex: "bg-[#E1B5F6]",
  },
  {
    status: "complete",
    statusName: "已完成",
    borderColorHex: "border-[#008767]",
    textColorHex: "text-[#008767]",
    bgColorHex: "bg-[#ccf3e0]",
  },
];

const WorkDaily = () => {
  const navigate = useNavigate();
  const [workDailyList, setWorkDailyList] = useState([]);
  const [nextCursor, setNextCursor] = useState("");
  const [loadMoreData, setLoadMoreData] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isOpen, setModalOpen] = useState(false);
  const [selectedPeriodValue, setSelectedPeriodValue] = useState("");
  const [dailyWorkHoursStatistic, setDailyWorkHoursStatistic] = useState({});

  const fetchDailyWorkHoursStatistic = async (year, month) => {
    try {
      const respond = await Http.get(
        `/auth/dailyWorkHours/statistic?year=${year}&month=${month}`
      );
      setDailyWorkHoursStatistic(respond);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  useEffect(() => {
    const currentYear = moment().format("YYYY");
    const currentMonth = moment().format("MM");
    setSelectedPeriodValue(`${currentYear}-${currentMonth}`);
    fetchDailyWorkHoursStatistic(currentYear, currentMonth);
  }, []);

  useEffect(() => {
    const getWorkDailyList = async (nextCursor) => {
      try {
        const response = await http.get("auth/dailyWorkHours/list", {
          params: { limit: 8, next: nextCursor },
        });
        if (response) {
          setWorkDailyList([...workDailyList, ...response.dailyWorkHoursList]);
          setNextCursor(response.nextCursor);

          if (
            response.dailyWorkHoursList.length === 0 &&
            response.nextCursor === ""
          ) {
            setHasMore(false);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    getWorkDailyList(nextCursor);
  }, [loadMoreData]);

  const closeDialog = () => {
    setModalOpen(false);
  };

  const handlePeriodChanged = async (event) => {
    const { year, month } = extractPeriodID(event.target.value);
    setSelectedPeriodValue(`${year}-${month}`);
    console.log("call year, month", year, month);
    fetchDailyWorkHoursStatistic(year, month);
  };

  return (
    <div className="w-full">
      <div className="flex justify-between mx-20 mt-10">
        <div className="text-5xl font-bold">每日員工工數列表</div>
        <button
          className="btn btn-outline btn-info"
          onClick={() => {
            setModalOpen(true);
          }}
        >
          查看每月各工程工時與比例
        </button>
      </div>
      <InfiniteScroll
        dataLength={workDailyList.length}
        next={() => setLoadMoreData(!loadMoreData)}
        hasMore={hasMore} // 是否還有更多資料需要加載
        loader={<h4>Loading...</h4>}
        className="flex flex-col items-center mt-5"
      >
        {workDailyList.map((item) => {
          const opt = statusOptions.find((opt) => opt.status === item.status);
          const statusClass = `border border-solid ${opt.borderColorHex} ${opt.bgColorHex}  rounded-xl mr-3`;
          const statusTextClass = `pr-10 pl-10 font-normal ${opt.textColorHex}`;

          return (
            <button
              key={item.date}
              className="btn btn-outline text-3xl w-4/5 h-24 mt-5 flex justify-between border-black bg-white"
              onClick={() => {
                const date = item.date.split(" ")[0];
                navigate(`/employeeWorkDaily/info?date=${date}`);
              }}
            >
              <span className="ml-3">{item.date}</span>
              <div className={statusClass}>
                <span className={statusTextClass}>{opt.statusName}</span>
              </div>
            </button>
          );
        })}
      </InfiniteScroll>
      <Dialog isOpen={isOpen} cancel={() => closeDialog()}>
        <PeriodSelector
          selectedPeriodValue={selectedPeriodValue}
          handlePeriodChanged={handlePeriodChanged}
        />
        <div className="m-3 font-bold text-xl">{`${dailyWorkHoursStatistic?.startDate} ~ ${dailyWorkHoursStatistic?.endDate}`}</div>
        <div className="m-3 font-bold text-xl">{`總時數：${dailyWorkHoursStatistic?.totalWorkHours} 小時`}</div>
        <div className="m-3 font-bold text-xl">各工程工時與比例：</div>
        <div className="m-3 text-xl">
          {dailyWorkHoursStatistic?.constructionWorkHoursList?.map((item) => {
            return (
              <div
                key={item.constructionWorkID}
                className="m-3"
              >{`${item.constructionWorkName}: ${item.workHours} 小時 (${item.workHoursPercentage}%)`}</div>
            );
          })}
        </div>
      </Dialog>
    </div>
  );
};

export default WorkDaily;
