export const header = [
  {
    name: "編號",
    key: "id",
  },
  {
    name: "日期",
    key: "createDate",
  },
  {
    name: "工程",
    key: "constructionWorkName",
  },
  {
    name: "請款人",
    key: "applyUserName",
  },
  {
    name: "金額",
    key: "totalAmount",
  },
  {
    name: "請款狀態",
    key: "status",
  },
];

export const statusLookup = {
  waiting: "請款中",
  receive: "已簽收",
  delete: "作廢",
};
