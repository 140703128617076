import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

import Http from "../services/http";

import Header from "../components/AccountsPayableDetailHeader";
import ErrorNotification from "../components/ErrorNotification";

import {
  paidStatusLookup,
  paymentMethodLookup,
  employeeInvoiceStatusLookup,
  paymentMethodOptions,
  paidStatusOptions,
  detailProcurementInvoiceTitle,
  detailEmployInvoiceTitle,
} from "../constants/AccountsPayableConstants";
import Loading from "../components/Loading";

const selectorLookup = {
  paymentMethod: {
    options: paymentMethodOptions,
    copy: "請選擇付款方式",
  },
  paymentStatus: {
    options: paidStatusOptions,
    copy: "請選擇付款狀態",
  },
};

const AccountsPayableDetail = () => {
  const { id, type, year, month } = useParams();

  const [apData, setApData] = useState({});
  const [detailList, setDetailList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  const title = `應付帳款-詳細資料`;
  const detailTitles =
    type === "procurementInvoice"
      ? detailProcurementInvoiceTitle
      : detailEmployInvoiceTitle;
  const gridColsClassName =
    detailTitles.length === 7 ? `grid-cols-7` : `grid-cols-8`;
  const titleClassName = `grid ${gridColsClassName} p-3`;

  const fetchAccountsPayableDetailData = async () => {
    setLoading(true);
    try {
      const data = await Http.get(
        `auth/accountsPayable/info?payeeID=${id}&type=${type}&year=${year}&month=${month}`
      );

      setApData(data);
      setDetailList(data?.detail ?? []);
    } catch (error) {
      setErrorMessage("獲取應付帳款資料錯誤，請稍後再試");
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  const updatePaymentInfo = async () => {
    setLoading(true);

    let idName = "id";

    if (type === "procurementInvoice") {
      idName = "procurementID";
    } else if (type === "employeeInvoice") {
      idName = "employeeInvoiceID";
    }

    try {
      await Http.put(`auth/accountsPayable/info`, {
        type,
        payeeID: id,
        detail: detailList.map((detail) => ({
          id: detail[idName],
          invoiceID: detail?.invoiceID,
          paymentMethod: detail?.paymentMethod,
          paymentNumber: detail?.paymentNumber,
          receiptNumber: detail?.receiptNumber,
          paymentStatus: detail?.paymentStatus,
        })),
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      setErrorMessage("新增請款單錯誤，請稍後再試");
    }
    setIsEdit(false);

    fetchAccountsPayableDetailData();

    setLoading(false);
  };

  useEffect(() => {
    fetchAccountsPayableDetailData();
  }, []);

  const handleEdit = () => {
    setIsEdit(true);
  };

  const handleCancel = () => {
    setIsEdit(false);
  };

  const handleSave = () => {
    updatePaymentInfo();
  };

  const handleDetailListChanged = (index, title, updatedValue) => {
    const updatedList = [...detailList];

    updatedList[index][title] = updatedValue;
    setDetailList(updatedList);
  };

  const SelectorTemplate = (selectorKey, detailIndex, title) => {
    return (
      <select
        key={selectorKey}
        className="select select-bordered w-full max-w-xs"
        value={detailList[detailIndex][title]}
        onChange={(event) => {
          handleDetailListChanged(detailIndex, title, event.target.value);
        }}
      >
        <option value="unknow" disabled>
          {selectorLookup[title].copy}
        </option>
        {selectorLookup[title].options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
    );
  };

  const InputTemplate = (inputKey, detailIndex, title) => {
    return (
      <input
        key={inputKey}
        type="text"
        value={detailList[detailIndex][title]}
        onChange={(event) => {
          handleDetailListChanged(detailIndex, title, event.target.value);
        }}
        className="input input-bordered w-full max-w-xs"
      />
    );
  };

  const DetailTitleTemplate = () => {
    return (
      <div className={titleClassName}>
        {detailTitles.map((title) => (
          <div key={title.key} className="font-bold p-3">
            {title.name}
          </div>
        ))}
      </div>
    );
  };

  const DetailTemplate = () => {
    return detailList.map((detail) => (
      <div className={titleClassName} key={detail["id"]}>
        {detailTitles.map((title) => (
          <div key={title.key} className="text-2xl p-3">
            {title.key === "paymentStatus" ? (
              paidStatusLookup[detail[title.key]]
            ) : title.key === "paymentMethod" ? (
              paymentMethodLookup[detail[title.key]]
            ) : title.key === "employeeInvoiceStatus" ? (
              employeeInvoiceStatusLookup[detail[title.key]]
            ) : title.key === "totalAmount" ? (
              <>${detail[title.key]?.toLocaleString()}</>
            ) : title.key === "employeeInvoiceID" ? (
              <Link
                key={title.key}
                className="text-2xl text-blue-500"
                to={`/employeeInvoice/detail/${detail["employeeInvoiceID"]}`}
              >
                #{detail["employeeInvoiceID"]}
              </Link>
            ) : title.key === "procurementAndInvoiceID" ? (
              <Link
                key={title.key}
                className="text-2xl text-blue-500"
                to={`/procurement/detail/${detail["procurementID"]}`}
              >
                #{detail["procurementID"]}-{detail["invoiceID"]}
              </Link>
            ) : (
              detail[title.key]
            )}
          </div>
        ))}
      </div>
    ));
  };

  const DetailInputTemplate = () => {
    if (type === "procurementInvoice") {
      return detailList.map((detail, detailIndex) => (
        <div
          className={titleClassName}
          key={`${detail.procurementID}-${detail.invoiceID}`}
        >
          {detailTitles.map((title) => {
            switch (title.key) {
              case "procurementAndInvoiceID": {
                return (
                  <Link
                    key={title.key}
                    className="text-2xl text-blue-500 p-3"
                    to={`/procurement/detail/${detail.procurementID}`}
                  >
                    #{detail.procurementID}-{detail.invoiceID}
                  </Link>
                );
              }
              case "paymentMethod": {
                return SelectorTemplate(
                  title.key,
                  detailIndex,
                  "paymentMethod"
                );
              }
              case "paymentNumber": {
                return InputTemplate(title.key, detailIndex, "paymentNumber");
              }
              case "receiptNumber": {
                return InputTemplate(title.key, detailIndex, "receiptNumber");
              }
              case "paymentStatus": {
                return SelectorTemplate(
                  title.key,
                  detailIndex,
                  "paymentStatus"
                );
              }
              case "totalAmount": {
                return (
                  <div key={title.key} className="text-2xl p-3">
                    ${detail[title.key]?.toLocaleString()}
                  </div>
                );
              }
              default: {
                return (
                  <div key={title.key} className="text-2xl p-3">
                    {detail[title.key]}
                  </div>
                );
              }
            }
          })}
        </div>
      ));
    } else if (type === "employeeInvoice") {
      return detailList.map((detail, detailIndex) => (
        <div className={titleClassName} key={detail.employeeInvoiceID}>
          {detailTitles.map((title) => {
            switch (title.key) {
              case "employeeInvoiceID": {
                return (
                  <Link
                    key={title.key}
                    className="font-bold text-blue-500 p-3"
                    to={`/employeeInvoice/detail/${detail.employeeInvoiceID}`}
                  >
                    #{detail.employeeInvoiceID}
                  </Link>
                );
              }
              case "paymentMethod": {
                return SelectorTemplate(
                  title.key,
                  detailIndex,
                  "paymentMethod"
                );
              }
              case "paymentStatus": {
                return SelectorTemplate(
                  title.key,
                  detailIndex,
                  "paymentStatus"
                );
              }
              case "employeeInvoiceStatus": {
                return (
                  <div key={title.key} className="font-bold p-3">
                    {employeeInvoiceStatusLookup[detail[title.key]]}
                  </div>
                );
              }
              default: {
                return (
                  <div key={title.key} className="font-bold p-3">
                    {detail[title.key]}
                  </div>
                );
              }
            }
          })}
        </div>
      ));
    }
  };

  return (
    <div className="w-full text-header">
      {isLoading && <Loading />}
      <Header
        title={title}
        isEdit={isEdit}
        edit={handleEdit}
        cancel={handleCancel}
        save={handleSave}
        year={year}
        month={month}
      />
      {errorMessage ? <ErrorNotification message={errorMessage} /> : null}
      <div className="border border-gray-400 p-3 mx-20 my-2">
        <div className="flex items-center mb-5">
          <div className="font-bold ml-10 w-[180px] max-h-[56px]">交易對象</div>
          <div className="text-2xl w-[350px] max-h-[56px]">
            {apData.payeeName}
          </div>
          <div className="w-[300px] max-h-[56px]"></div>
          <div className="font-bold w-[180px] max-h-[56px]">付款狀態</div>
          <div className="text-2xl w-[350px] max-h-[56px]">
            {paidStatusLookup[apData.status]}
          </div>
        </div>
        <div className="flex items-center mb-5">
          <div className="font-bold ml-10 w-[180px] max-h-[56px]">總計金額</div>
          <div className="text-2xl w-[350px] max-h-[56px]">
            ${apData.totalAmount?.toLocaleString()}
          </div>
          <div className="w-[300px] max-h-[56px]"></div>
          <div className="font-bold w-[180px] max-h-[56px]"></div>
          <div className="text-2xl w-[350px] max-h-[56px]"></div>
        </div>
        <div className="divider"></div>
        <div className="flex-col">
          {DetailTitleTemplate()}
          {isEdit ? DetailInputTemplate() : DetailTemplate()}
        </div>
      </div>
    </div>
  );
};

export default AccountsPayableDetail;
