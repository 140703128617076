import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Http from "../services/http";

import ProcurementHeader from "../components/ProcurementHeader";
import Table from "../components/Table";

import {
  header,
  purchaseStatusLookup,
  approveStatusLookup,
  editableStatusLookup,
} from "../constants/ProcurementConstants";
import ExportAllProcurementByConsturction from "../components/ExportAllProcurementByConsturction";
import Dialog from "../components/Dialog";
import ExportAllProcurementBySupplier from "../components/ExportAllProcurementBySupplier";
import Loading from "../components/Loading";
import Pagination from "../components/Pagination";

const PAGE_SIZE = 15;
const CONSTURCTION_TAG = "consturction";
const SUPPLIER_TAG = "supplier";

const ProcurementV2 = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [procurementList, setProcurementList] = useState([]);
  const [isOpen, setModalOpen] = useState(false);
  const [exportTag, setExportTag] = useState("");
  const [isLoading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const closeDialog = () => {
    setModalOpen(false);
  };

  const handleExports = () => {
    setModalOpen(true);
  };

  const fetchData = async (currentPage, keyword) => {
    setLoading(true);
    try {
      const response = await Http.get("auth/procurement/listV2", {
        params: { page: currentPage, page_size: PAGE_SIZE, keyword: keyword },
      });
      const formatedList = response.procurementList.map((item) => ({
        ...item,
        totalAmount: item.totalAmount.toLocaleString(),
        approveStatus: approveStatusLookup[item.approveStatus],
        purchaseStatus: purchaseStatusLookup[item.purchaseStatus],
        editable: editableStatusLookup[item.editable],
      }));

      setProcurementList((prevList) => {
        return [...prevList, ...formatedList];
      });

      setTotalPage(response.totalPage);
      setCurrentPage(response.currentPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    const page = searchParams.get("page") || 1;
    const keyword = searchParams.get("keyword") || "";
    setProcurementList([]);
    fetchData(page, keyword);
  }, [searchParams]);

  const handleAddNew = () => {
    navigate("/procurement/edit");
  };
  const handleViewDetail = (procurementID) => {
    navigate(`/procurement/detail/${procurementID}`);
  };
  const searchOnChange = (query) => {
    setSearchParams({ page: 1, keyword: query });
  };

  const clickPage = (page) => {
    const keyword = searchParams.get("keyword") || "";
    setSearchParams({ page: page, keyword: keyword });
  };

  const exportTemplate = () => {
    if (!exportTag || exportTag === CONSTURCTION_TAG) {
      return (
        <ExportAllProcurementByConsturction cancel={() => closeDialog()} />
      );
    } else if (exportTag === SUPPLIER_TAG) {
      return <ExportAllProcurementBySupplier cancel={() => closeDialog()} />;
    } else {
      return <div />;
    }
  };

  return (
    <div className="w-full">
      {isLoading && <Loading />}
      <ProcurementHeader
        title="採購單"
        addnew={handleAddNew}
        search={searchOnChange}
        exports={handleExports}
        nowWord={searchParams.get("keyword") || ""}
      />
      <Table
        headerList={header}
        bodyList={procurementList}
        viewDetail={handleViewDetail}
      />
      <Pagination
        currentPage={currentPage}
        totalPage={totalPage}
        clickPage={clickPage}
      />
      <Dialog isOpen={isOpen} cancel={() => closeDialog()} dialogClass="w-full">
        <div className="tabs w-full">
          <a
            className={`tab tab-lg tab-bordered ${
              !exportTag || exportTag === CONSTURCTION_TAG ? "tab-active" : ""
            }`}
            onClick={() => {
              setExportTag(CONSTURCTION_TAG);
            }}
          >
            工程
          </a>
          <a
            className={`tab tab-lg tab-bordered ${
              exportTag === SUPPLIER_TAG ? "tab-active" : ""
            }`}
            onClick={() => {
              setExportTag(SUPPLIER_TAG);
            }}
          >
            廠商
          </a>
        </div>
        {exportTemplate()}
      </Dialog>
    </div>
  );
};

export default ProcurementV2;
