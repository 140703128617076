/* eslint-disable react/prop-types */
import React from "react";

const Dialog = ({ dialogClass, isOpen, cancel, children }) => {
  const open = isOpen ? "modal-open" : "";
  return (
    <dialog className={`modal ${dialogClass} ${open} w-full`}>
      <div className={`modal-box`}>
        <button
          className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
          onClick={cancel}
        >
          ✕
        </button>
        <div>{children}</div>
      </div>
    </dialog>
  );
};

export default Dialog;
