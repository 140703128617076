/* eslint-disable react/prop-types */
import React, { useState } from "react";

const ProcurementInvoiceTableHeader = ({
  isEditable = false,
  canChangeEditable = false,
  changeEditableHandler,
  createProcurementInvoiceHandler,
}) => {
  return (
    <div className="flex items-center justify-between">
      <div className="text-4xl font-bold">到貨單列表</div>
      <div className="flex items-center">
        {canChangeEditable ? (
          <div>
            {isEditable ? (
              <div className="flex items-center">
                <button
                  className="text-2xl text-btn-blue"
                  onClick={() => {
                    changeEditableHandler(false);
                  }}
                >
                  鎖定編輯
                </button>
                <div className="text-2xl text-divider-gray">｜</div>
              </div>
            ) : (
              <div className="flex items-center">
                <button
                  className="text-2xl text-btn-blue"
                  onClick={() => {
                    changeEditableHandler(true);
                  }}
                >
                  開啟編輯
                </button>
                <div className="text-2xl text-divider-gray">｜</div>
              </div>
            )}
          </div>
        ) : null}

        {isEditable ? (
          <button
            className="text-2xl text-btn-blue"
            onClick={createProcurementInvoiceHandler}
          >
            新增到貨單
          </button>
        ) : (
          <div className="text-2xl text-divider-gray">新增到貨單</div>
        )}
      </div>
    </div>
  );
};

export default ProcurementInvoiceTableHeader;
