/* eslint-disable react/prop-types */
import React from "react";
import { useNavigate } from "react-router-dom";

const StatisticNavBtn = ({ content, href }) => {
  const navigate = useNavigate();
  return (
    <button
      className="btn btn-outline m-8 text-3xl w-72 h-64"
      onClick={() => {
        navigate(href);
      }}
    >
      {content}
    </button>
  );
};

export default StatisticNavBtn;
