import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import Logo from "../components/Logo";
import Dialog from "../components/Dialog";
import Http from "../services/http";

const CreateUser = () => {
  const displayName = localStorage.getItem("displayName");
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [permissionCode, setPermissionCode] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    try {
      const response = await Http.put("auth/user/info", {
        displayName: name,
        permissionCode,
      });
      console.log("response", response);
      setTimeout(() => {
        setLoading(false);
        localStorage.setItem("initLogin", false);
        navigate("/");
      }, 1000);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  return (
    <div className="h-full w-full flex items-center justify-center ">
      {isLoading && <Loading />}
      <div className="w-2/4 h-[80vh] flex justify-center items-center border-r border-solid border-[#8E8E8E] ">
        <Logo isNav={false} />
      </div>
      <div className="w-2/4 h-[80vh] px-24">
        <div className="text-4xl">
          <span className="font-bold">{displayName}</span>, 您好！
        </div>
        <div className="my-16 flex flex-col text-2xl bg-[#107AB9] bg-opacity-20 rounded-2xl p-6">
          <div>
            首次登入，請使用您的
            <span className="text-[#E73737] font-black">真實姓名</span>及
            <span className="text-[#E73737] font-black">授權碼</span>
          </div>
          <span className="mt-6 font-black">
            (務必填寫完整姓名，未來表單填寫將會自動帶入。)
          </span>
        </div>
        <div className="my-16">
          <div className="flex flex-col">
            <span className=" text-2xl">姓名</span>
            <input
              type="text"
              placeholder="請輸入姓名"
              className="mt-3 py-4 px-6 text-xl max-w-sm outline-none border border-black border-solid rounded-2xl"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </div>
          <div className="mt-10 flex flex-col">
            <span className="text-2xl">授權碼</span>
            <input
              type="text"
              placeholder="請輸入授權碼"
              className="mt-3 py-4 px-6 text-xl max-w-sm outline-none border border-black border-solid rounded-2xl"
              value={permissionCode}
              onChange={(event) => setPermissionCode(event.target.value)}
            />
          </div>
        </div>
        <div className="flex justify-end">
          <button
            className="btn text-white bg-[#107AB9] bg-opacity-80 rounded-2xl px-4 py-1 tracking-widest text-2xl min-w-[150px] hover:bg-[#107AB9]"
            disabled={!name || !permissionCode}
            onClick={() => setModalOpen(true)}
          >
            確認
          </button>
        </div>
      </div>
      <Dialog
        isOpen={isModalOpen}
        cancel={() => setModalOpen(false)}
        width={450}
      >
        <div className="text-center text-2xl font-bold">以下是您填寫的資料</div>
        <div className="flex flex-col mt-6 text-xl w-40 m-auto">
          <span>姓名：{name}</span>
          <span className="mt-1">授權碼：{permissionCode}</span>
        </div>
        <div className="mt-7 flex justify-center">
          <button
            className="btn btn-outline outline-none rounded-2xl px-4 py-1 tracking-widest text-xl"
            onClick={() => setModalOpen(false)}
          >
            修改
          </button>
          <button
            className="btn bg-accent text-white ml-14 outline-none  rounded-2xl px-4 py-1 tracking-widest text-xl hover:bg-accent-focus "
            onClick={onSubmit}
          >
            送出
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default CreateUser;
