export const header = [
  {
    name: "編號",
    key: "id",
  },
  {
    name: "日期",
    key: "createDate",
  },
  {
    name: "廠商",
    key: "supplierName",
  },
  {
    name: "工程",
    key: "constructionWorkName",
  },
  {
    name: "總計金額",
    key: "totalAmount",
  },
  {
    name: "核准狀態",
    key: "approveStatus",
  },
  {
    name: "採購狀況",
    key: "purchaseStatus",
  },
  {
    name: "編輯狀態",
    key: "editable",
  },
];

export const purchaseStatusLookup = {
  not_yet_purchase: "未購買",
  purchased: "已購買",
  arrived: "已到貨",
};

export const approveStatusLookup = {
  waiting: "等待中",
  approve: "已核准",
  reject: "已拒絕",
  delete: "已刪除",
};

export const editableStatusLookup = {
  true: "可編輯",
  false: "不可編輯",
};
