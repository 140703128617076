import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Http from "../services/http";

import Header from "../components/InvoiceEditHeader";
import ErrorNotification from "../components/ErrorNotification";
import NumericInput from "../components/NumericInput";
import Loading from "../components/Loading";
import SearchSelector from "../components/SearchSelector";

const handleNumberType = (title, value) => {
  if (title === "unitPrice" || title === "quantity") {
    return Number(value);
  } else {
    return value;
  }
};

const employeeInvoiceDetailInitialObject = {
  content: "",
  quantity: 0,
  unitPrice: 0,
  remark: "",
};

const InvoiceEdit = () => {
  const { id } = useParams();
  const isEdit = Boolean(id);

  const navigate = useNavigate();
  const [invoiceData, setInvoiceData] = useState({});
  const [selectedConstructionValue, setSelectedConstructionValue] =
    useState("");
  const [constructionOptions, setConstructionOptions] = useState([]);
  const [employeeInvoiceDetailList, setEmployeeInvoiceDetailList] = useState(
    []
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  const title = `${isEdit ? "編輯" : "新增"}請款單`;
  const userName = localStorage.getItem("displayName");

  useEffect(() => {
    const fetchInvoiceData = async () => {
      setLoading(true);
      try {
        const data = await Http.get(
          `auth/employeeInvoice/info?employeeInvoiceID=${id}`
        );

        setInvoiceData(data);
        setSelectedConstructionValue(data?.constructionWorkID ?? "");
        setEmployeeInvoiceDetailList(data?.employeeInvoiceDetail ?? []);
      } catch (error) {
        console.error("Error fetching data:", error);
        setErrorMessage("獲取請款單資料錯誤，請稍後再試");
      }
      setLoading(false);
    };

    const fetchConstructionWork = async () => {
      setLoading(true);
      try {
        const response = await Http.get(`auth/constructionWork/list`);
        setConstructionOptions(response.constructionWorkList);
      } catch (error) {
        console.error("Error fetching data:", error);
        setErrorMessage("獲取工程清單錯誤，請稍後再試");
      }
      setLoading(false);
    };

    if (isEdit) {
      fetchInvoiceData();
    }

    fetchConstructionWork();
  }, []);

  const totalAmount = useMemo(() => {
    return Math.round(
      employeeInvoiceDetailList.reduce((acc, current) => {
        const totalAmount = current.unitPrice * current.quantity;
        return acc + totalAmount;
      }, 0)
    );
  }, [employeeInvoiceDetailList]);

  const handleCancel = useCallback(() => {
    if (isEdit) {
      navigate(`/employeeInvoice/detail/${id}`);
    } else {
      navigate("/employeeInvoice");
    }
  }, []);
  const handleUpdate = useCallback(async () => {
    const updateInvoice = async () => {
      // TODO: error handling here
      // if () {
      //   setErrorMessage('testing');

      //   return;
      // }
      setLoading(true);
      try {
        await Http.put(`auth/employeeInvoice`, {
          id: Number(id),
          constructionWorkID: selectedConstructionValue,
          totalAmount,
          employeeInvoiceDetail: employeeInvoiceDetailList.map((detail) => ({
            ...detail,
            totalAmount: detail.quantity * detail.unitPrice,
          })),
        });
        navigate("/employeeInvoice");
      } catch (error) {
        console.error("Error fetching data:", error);
        setErrorMessage("修改請款單錯誤，請稍後再試");
      }
      setLoading(false);
    };

    await updateInvoice();
  }, [
    employeeInvoiceDetailList,
    selectedConstructionValue,
    totalAmount,
    setErrorMessage,
  ]);
  const handleCreate = useCallback(async () => {
    const createInvoice = async () => {
      setLoading(true);
      try {
        await Http.post(`auth/employeeInvoice`, {
          constructionWorkID: selectedConstructionValue,
          totalAmount,
          employeeInvoiceDetail: employeeInvoiceDetailList.map((detail) => ({
            ...detail,
            totalAmount: detail.quantity * detail.unitPrice,
          })),
        });
        navigate("/employeeInvoice");
      } catch (error) {
        console.error("Error fetching data:", error);
        setErrorMessage("新增請款單錯誤，請稍後再試");
      }
      setLoading(false);
    };

    await createInvoice();
  }, [
    employeeInvoiceDetailList,
    selectedConstructionValue,
    totalAmount,
    setErrorMessage,
  ]);
  const handleRemoveEmployeeInvoiceDetailItem = useCallback(
    (index) => {
      const updatedList = [...employeeInvoiceDetailList];

      updatedList.splice(index, 1); // Remove 1 item at the specified index

      setEmployeeInvoiceDetailList(updatedList);
    },
    [employeeInvoiceDetailList, setEmployeeInvoiceDetailList]
  );

  const handleEmployeeInvoiceDetailListChanged = useCallback(
    (index, title, updatedValue) => {
      const updatedList = [...employeeInvoiceDetailList];

      updatedList[index][title] = handleNumberType(title, updatedValue);
      setEmployeeInvoiceDetailList(updatedList);
    },
    [employeeInvoiceDetailList, setEmployeeInvoiceDetailList]
  );

  const handleAddEmployeeInvoiceDetailItem = useCallback(() => {
    const updatedList = [...employeeInvoiceDetailList];
    const deepClonedObject = JSON.parse(
      JSON.stringify(employeeInvoiceDetailInitialObject)
    );

    updatedList.push(deepClonedObject);
    setEmployeeInvoiceDetailList(updatedList);
  }, [employeeInvoiceDetailList, setEmployeeInvoiceDetailList]);

  const constructionSelectorTemplate = useMemo(() => {
    return (
      <div className="w-full col-span-2 text-2xl">
        <SearchSelector
          title="請選擇工程"
          value={selectedConstructionValue}
          itemList={constructionOptions}
          onChange={(item) => {
            setSelectedConstructionValue(item.id);
          }}
        ></SearchSelector>
      </div>
    );
  }, [
    constructionOptions,
    setSelectedConstructionValue,
    selectedConstructionValue,
  ]);

  const employeeInvoiceDetailTemplate = useMemo(() => {
    return employeeInvoiceDetailList.map((detail, index) => (
      <div className="p-3" key={index}>
        <div className="grid grid-cols-6 place-items-center">
          <div className="font-bold p-4">項目 {index + 1}</div>
          <div className="font-bold p-4">請款內容</div>
          <input
            type="text"
            placeholder="請款內容..."
            value={detail.content}
            onChange={(event) => {
              handleEmployeeInvoiceDetailListChanged(
                index,
                "content",
                event.target.value
              );
            }}
            className="input input-bordered col-span-3 w-full text-2xl"
          />
          <button
            onClick={() => {
              handleRemoveEmployeeInvoiceDetailItem(index);
            }}
            className="btn btn-error btn-md ml-4 w-1/2"
          >
            刪除
          </button>
        </div>
        <div className="grid grid-cols-6 place-items-center">
          <div></div>
          <div className="font-bold p-4">數量</div>
          <NumericInput
            placeholder="數量..."
            value={detail.quantity}
            onChange={(event) => {
              handleEmployeeInvoiceDetailListChanged(
                index,
                "quantity",
                event.target.value
              );
            }}
          />
          <div className="font-bold p-4">單價</div>
          <NumericInput
            placeholder="單價..."
            value={detail.unitPrice}
            onChange={(event) => {
              handleEmployeeInvoiceDetailListChanged(
                index,
                "unitPrice",
                event.target.value
              );
            }}
          />
          <div></div>
        </div>
        <div className="grid grid-cols-6 place-items-center">
          <div></div>
          <div className="font-bold p-4">金額</div>
          <div className="font-bold p-4">
            {(detail.quantity * detail.unitPrice)?.toLocaleString()}
          </div>
          <div className="font-bold p-4">備註</div>
          <input
            type="text"
            placeholder="備註..."
            value={detail.remark}
            onChange={(event) => {
              handleEmployeeInvoiceDetailListChanged(
                index,
                "remark",
                event.target.value
              );
            }}
            className="input input-bordered w-full max-w-xs text-2xl"
          />
          <div></div>
        </div>
      </div>
    ));
  }, [employeeInvoiceDetailList]);

  return (
    <div className="w-full text-header">
      {isLoading && <Loading />}
      <Header
        title={title}
        isEdit={isEdit}
        upsert={isEdit ? handleUpdate : handleCreate}
        cancel={handleCancel}
      />
      {errorMessage ? <ErrorNotification message={errorMessage} /> : null}
      <div className="border border-gray-400 p-3 mx-20 my-10">
        <div className="grid grid-rows-2">
          <div className="grid grid-cols-6 p-3 place-items-center">
            <div className="font-bold p-3">工程</div>
            {constructionSelectorTemplate}
          </div>
          <div className="grid grid-cols-6 p-3 place-items-center">
            <div className="font-bold p-3">開單人</div>
            <div className="p-3 col-span-2 text-2xl">
              {isEdit ? invoiceData?.applyUserName : userName}
            </div>
          </div>
        </div>
        {employeeInvoiceDetailList?.length > 0 ? (
          <div className="divider"></div>
        ) : null}

        {employeeInvoiceDetailTemplate}
        <div className="divider"></div>
        <div className="flex justify-center items-center">
          <button
            className="btn btn-wide"
            onClick={handleAddEmployeeInvoiceDetailItem}
          >
            新增項目+
          </button>
        </div>
        <div className="divider"></div>
        <div className="grid grid-cols-6 p-3">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div className="p-3">總額</div>
          <div className="p-3">{totalAmount?.toLocaleString()}</div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceEdit;
