/* eslint-disable react/prop-types */
import React from "react";

const AccountsPayableDetailHeader = ({
  title,
  isEdit,
  edit,
  cancel,
  save,
  year,
  month,
}) => {
  return (
    <div className="mx-20 mt-10 flex justify-between">
      <div className="flex text-center items-end">
        <div className="text-4xl font-bold">{title}</div>
        <span className="text-2xl ml-3">{`${
          Number(year) - 1911
        }年${month}月份`}</span>
      </div>
      <div className="flex text-center items-end">
        {isEdit ? (
          <>
            <button className="text-2xl text-btn-blue ml-4" onClick={cancel}>
              取消
            </button>
            <div className="text-2xl text-divider-gray">｜</div>
            <button className="text-2xl text-btn-blue" onClick={save}>
              儲存
            </button>
          </>
        ) : (
          <button className="text-2xl text-btn-blue" onClick={edit}>
            編輯
          </button>
        )}
      </div>
    </div>
  );
};

export default AccountsPayableDetailHeader;
