/* eslint-disable react/prop-types */
import React from "react";
import Http from "../services/http";
import * as ExcelJS from "exceljs";

const ExportInvoice = ({ employeeInvoiceID }) => {
  const fetchInvoiceData = async () => {
    try {
      const response = await Http.get(
        `auth/employeeInvoice/info?employeeInvoiceID=${employeeInvoiceID}`
      );

      return response;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleExport = async () => {
    const invoiceData = await fetchInvoiceData();
    console.log(invoiceData);

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("現金請款單");

    const header = [
      ["華瑞/維瑞機械有限公司"],
      [`【現金請款單 #${employeeInvoiceID}】`],
    ];
    header.forEach((e) => worksheet.addRow(e));
    worksheet.getCell("A1").font = { bold: true, size: 15 };
    worksheet.getCell("A1").alignment = {
      horizontal: "center",
      vertical: "center",
    };
    worksheet.getCell("A2").font = { bold: true, size: 15 };
    worksheet.getCell("A2").alignment = {
      horizontal: "center",
      vertical: "center",
    };
    worksheet.mergeCells("A1:F1");
    worksheet.mergeCells("A2:F2");

    const row = [];
    row.push("工程名稱：");
    row.push(invoiceData.constructionWorkName);
    row.push("請款人：");
    row.push(invoiceData.applyUserName);
    row.push("請款日期：");
    row.push(invoiceData.createTime.split(" ")[0]);
    worksheet.addRow(row);
    worksheet.getCell("A3").font = { bold: true, size: 13 };
    worksheet.getCell("C3").font = { bold: true, size: 13 };
    worksheet.getCell("E3").font = { bold: true, size: 13 };
    worksheet.getCell("A3").alignment = {
      horizontal: "center",
      vertical: "center",
    };
    worksheet.getCell("C3").alignment = {
      horizontal: "center",
      vertical: "center",
    };
    worksheet.getCell("E3").alignment = {
      horizontal: "center",
      vertical: "center",
    };

    const detailHeader = ["請款內容", "", "數量", "單價", "金額", "備註"];
    worksheet.addRow(detailHeader);
    worksheet.getRow(4).font = { bold: true, size: 13 };
    worksheet.getRow(4).alignment = {
      horizontal: "center",
      vertical: "center",
    };
    worksheet.mergeCells("A4:B4");

    invoiceData.employeeInvoiceDetail.forEach((detail) => {
      const newRow = [];
      newRow.push(detail.content);
      newRow.push("");
      newRow.push(detail.quantity);
      newRow.push(detail.unitPrice);
      newRow.push(detail.totalAmount);
      newRow.push(detail.remark);
      const r = worksheet.addRow(newRow);
      worksheet.mergeCells(r.number, 1, r.number, 2);
    });

    // add empty row
    for (let i = 0; i < 5; i++) {
      const r = worksheet.addRow(["", "", "", "", "", ""]);
      worksheet.mergeCells(r.number, 1, r.number, 2);
    }

    const footer1 = [];
    footer1.push("總金額：");
    footer1.push(invoiceData.totalAmount);
    const f1r = worksheet.addRow(footer1);
    worksheet.getCell(`A${f1r.number}`).font = { bold: true, size: 13 };
    worksheet.getCell(`A${f1r.number}`).alignment = {
      horizontal: "center",
      vertical: "center",
    };

    const footer2 = [];
    footer2.push("簽收人：");
    footer2.push(invoiceData.applyUserName);
    footer2.push("");
    footer2.push("簽收時間：");
    footer2.push(invoiceData.receiveTime.split(" ")[0]);
    footer2.push("");
    const f2r = worksheet.addRow(footer2);
    worksheet.getCell(`A${f2r.number}`).font = { bold: true, size: 13 };
    worksheet.getCell(`A${f2r.number}`).alignment = {
      horizontal: "center",
      vertical: "center",
    };
    worksheet.getCell(`D${f2r.number}`).font = { bold: true, size: 13 };
    worksheet.getCell(`D${f2r.number}`).alignment = {
      horizontal: "center",
      vertical: "center",
    };

    // 調整各列寬
    worksheet.getColumn("A").width = 12;
    worksheet.getColumn("B").width = 18;
    worksheet.getColumn("C").width = 15;
    worksheet.getColumn("D").width = 15;
    worksheet.getColumn("E").width = 15;
    worksheet.getColumn("F").width = 15;

    // 匯出
    const content = await workbook.xlsx.writeBuffer();
    const link = document.createElement("a");
    const blobData = new Blob([content], {
      type: "application/vnd.ms-excel;charset=utf-8;",
    });
    link.download = `現金請款單_${employeeInvoiceID}.xlsx`;
    link.href = URL.createObjectURL(blobData);
    link.click();
  };

  return (
    <button
      className="btn btn-lg btn-outline btn-success ml-4"
      onClick={handleExport}
    >
      匯出
    </button>
  );
};

export default ExportInvoice;
