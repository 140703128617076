/* eslint-disable react/prop-types */
import React from "react";

const Logo = ({ className, isNav = true }) => {
  return (
    <div className={className}>
      <div className="flex justify-center">
        <img
          className={`relative ${
            isNav ? "w-[80px] h-[80px]" : "w-[100px] h-[100px]"
          }`}
          src="/assets/logo.png"
          alt="weirwei Logo"
        />
      </div>
      <div
        className={`flex flex-col ${
          isNav ? "ml-1 text-left" : " mt-6 text-center"
        }`}
      >
        <span className="text-[#107AB9] font-bold  text-3xl">收支管理系統</span>
        <span
          className={`text-[#7A8A96] font-bold ${
            isNav ? "mt-1 ,text-sm" : "text-2xl mt-4"
          }`}
        >
          華瑞機械 | 維瑞機械
        </span>
      </div>
    </div>
  );
};

export default Logo;
