import {
  Outlet,
  Navigate, // eslint-disable-line
  useNavigate, // eslint-disable-line
  useLocation, // eslint-disable-line
  useLoaderData, // eslint-disable-line
  useSearchParams,
} from "react-router-dom";
import React, { useEffect } from "react";
import jwt_decode from "jwt-decode";
import _ from "lodash";

const App = () => {
  const [searchParam] = useSearchParams();
  const storage_token = localStorage.getItem("auth_token");
  const isExpiry = () => {
    const tokenInfo = jwt_decode(storage_token) || { exp: 0, userID: "" };
    const expiryDate = tokenInfo.exp * 1000;
    if (_.now() > expiryDate) {
      localStorage.clear();
    }
  };
  const storeParam = () => {
    const authorization = searchParam.get("authorization");
    if (authorization) {
      const isFirstLogin = searchParam.get("isFirstLogin");
      localStorage.setItem("auth_token", authorization);
      localStorage.setItem("initLogin", isFirstLogin);
    }
  };
  useEffect(storage_token ? isExpiry : storeParam, []);

  return <Outlet />;
};

export default App;
