import moment from "moment";

const MOTHS_FOR_TRACE_BACK = 24;

export const generatePeriodOptions = () => {
  const options = [];
  const startDate = moment().subtract(MOTHS_FOR_TRACE_BACK, "months"); // 2 years ago from now
  const endDate = moment(); // Current date

  const yearsDiff = endDate.diff(startDate, "years"); // Calculate the number of years between dates

  for (let i = 0; i < yearsDiff; i++) {
    const year = startDate.clone().add(i, "years");

    for (let month = 1; month <= 12; month++) {
      const addedMonth = year.clone().add(month, "months");
      const currentMonth = addedMonth.format("MM");
      const currentYear = Number(addedMonth.format("YYYY"));

      options.push({
        id: `${currentYear}-${currentMonth}`,
        name: `${currentYear - 1911}年${currentMonth}月份`,
      });
    }
  }

  return options.reverse();
};

export const extractPeriodID = (id) => {
  const idArray = id.split('-');

  return {
    year: idArray[0],
    month: idArray[1],
  };
};
