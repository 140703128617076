import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Http from "../services/http";

import Header from "../components/ProcurementEditHeader";
import ErrorNotification from "../components/ErrorNotification";
import Loading from "../components/Loading";
import NumericInput from "../components/NumericInput";
import Autocomplete from "../components/Autocomplete";

import { purchaseStatusLookup } from "../constants/ProcurementConstants";
import SearchSelector from "../components/SearchSelector";

const taxRate = 0.05;

const handleNumberType = (title, value) => {
  if (title === "unitPrice" || title === "quantity" || title === "weight") {
    return Number(value);
  } else {
    return value;
  }
};

const purchaseStatusOptions = Object.keys(purchaseStatusLookup).map((key) => ({
  status: key,
  statusName: purchaseStatusLookup[key],
}));

const procurementDetailInitialObject = {
  itemName: "",
  specification: "",
  weight: 0,
  unit: "",
  quantity: 0,
  unitPrice: 0,
  isIncludeTax: false,
  remark: "",
};

const ProcurementEdit = () => {
  const { id } = useParams();
  const isEdit = Boolean(id);
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();
  const [procurementData, setProcurementData] = useState({});
  const [selectedSupplierValue, setSelectedSupplierValue] = useState("");
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [selectedPurchaseStatusValue, setSelectedPurchaseStatusValue] =
    useState("");
  const [selectedConstructionValue, setSelectedConstructionValue] =
    useState("");
  const [constructionOptions, setConstructionOptions] = useState([]);
  const [procurementDetailList, setProcurementDetailList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const title = `${isEdit ? "編輯" : "新增"}採購單`;
  const userName = localStorage.getItem("displayName");

  useEffect(() => {
    const fetchProcurementData = async () => {
      setLoading(true);
      try {
        const data = await Http.get(
          `auth/procurement/info?procurementID=${id}`
        );

        setProcurementData(data);
        setSelectedSupplierValue(data?.supplierID ?? "");
        setSelectedPurchaseStatusValue(data?.purchaseStatus ?? "");
        setSelectedConstructionValue(data?.constructionWorkID ?? "");
        setProcurementDetailList(data?.procurementDetail ?? []);
      } catch (error) {
        setErrorMessage("獲取採購單資料錯誤，請稍後再試");
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };

    const fetchSupplierOptions = async () => {
      setLoading(true);
      try {
        const response = await Http.get(`auth/supplier/list`);
        setSupplierOptions(response.supplierList);
      } catch (error) {
        console.error("Error fetching data:", error);
        setErrorMessage("獲取廠商清單錯誤，請稍後再試");
      }
      setLoading(false);
    };

    const fetchConstructionWork = async () => {
      setLoading(true);
      try {
        const response = await Http.get(`auth/constructionWork/list`);
        setConstructionOptions(response.constructionWorkList);
      } catch (error) {
        console.error("Error fetching data:", error);
        setErrorMessage("獲取工程清單錯誤，請稍後再試");
      }
      setLoading(false);
    };

    if (isEdit) {
      fetchProcurementData();
    }

    fetchSupplierOptions();
    fetchConstructionWork();
  }, []);

  const beforeTax = useMemo(() => {
    let isIncludeTax = false;
    const total = procurementDetailList.reduce((acc, current) => {
      isIncludeTax = current.isIncludeTax;
      const price =
        current.weight === 0
          ? Math.round(current.quantity * current.unitPrice)
          : Math.round(current.weight * current.unitPrice);
      return acc + price;
    }, 0);

    return isIncludeTax ? Math.round(total / (1 + taxRate)) : total;
  }, [procurementDetailList]);

  const totalAmount = useMemo(() => {
    let isIncludeTax = false;
    const total = procurementDetailList.reduce((acc, current) => {
      isIncludeTax = current.isIncludeTax;
      const price =
        current.weight === 0
          ? Math.round(current.quantity * current.unitPrice)
          : Math.round(current.weight * current.unitPrice);
      return acc + price;
    }, 0);

    return isIncludeTax ? total : Math.round(total * (1 + taxRate));
  }, [procurementDetailList]);

  const totalTax = useMemo(() => {
    return totalAmount - beforeTax;
  }, [procurementDetailList]);

  const handleCancel = useCallback(() => {
    if (isEdit) {
      navigate(`/procurement/detail/${id}`);
    } else {
      navigate("/procurement?page=1");
    }
  }, []);
  const handleUpdate = useCallback(async () => {
    const updateProcurement = async () => {
      try {
        await Http.put(`auth/procurement`, {
          id: Number(id),
          supplierID: selectedSupplierValue,
          constructionWorkID: selectedConstructionValue,
          purchaseStatus: selectedPurchaseStatusValue,
          beforeTax,
          tax: totalTax,
          totalAmount,
          procurementDetail: procurementDetailList.map((detail) => ({
            ...detail,
            totalAmount:
              detail.weight === 0
                ? Math.round(detail.quantity * detail.unitPrice)
                : Math.round(detail.weight * detail.unitPrice),
          })),
        });
        navigate(`/procurement/detail/${id}`);
      } catch (error) {
        console.error("Error fetching data:", error);
        setErrorMessage("更新錯誤，請稍後再試");
      }
    };

    await updateProcurement();
  }, [
    procurementDetailList,
    selectedSupplierValue,
    selectedConstructionValue,
    selectedPurchaseStatusValue,
    beforeTax,
    totalTax,
    totalAmount,
  ]);
  const handleCreate = useCallback(async () => {
    setLoading(true);
    try {
      await Http.post(`auth/procurement`, {
        supplierID: selectedSupplierValue,
        constructionWorkID: selectedConstructionValue,
        purchaseStatus: selectedPurchaseStatusValue,
        beforeTax,
        tax: totalTax,
        totalAmount,
        procurementDetail: procurementDetailList.map((detail) => ({
          ...detail,
          totalAmount:
            detail.weight === 0
              ? Math.round(detail.quantity * detail.unitPrice)
              : Math.round(detail.weight * detail.unitPrice),
        })),
      });
      navigate("/procurement?page=1");
    } catch (error) {
      console.error("Error fetching data:", error);
      setErrorMessage("建立採購單錯誤，請稍後再試");
    }
    setLoading(false);
  }, [
    procurementDetailList,
    selectedSupplierValue,
    selectedConstructionValue,
    selectedPurchaseStatusValue,
    beforeTax,
    totalTax,
    totalAmount,
  ]);
  const handleRemoveProcurementDetailItem = useCallback(
    (index) => {
      const updatedList = [...procurementDetailList];

      updatedList.splice(index, 1); // Remove 1 item at the specified index

      setProcurementDetailList(updatedList);
    },
    [procurementDetailList, setProcurementDetailList]
  );

  const handleProcurementDetailListChanged = useCallback(
    (index, title, updatedValue) => {
      const updatedList = [...procurementDetailList];

      updatedList[index][title] = handleNumberType(title, updatedValue);
      setProcurementDetailList(updatedList);
    },
    [procurementDetailList, setProcurementDetailList]
  );

  const handleProcurementDetailListIsIncludeTaxChanged = (updatedValue) => {
    const updatedList = procurementDetailList.map((detail) => ({
      ...detail,
      isIncludeTax: updatedValue === "includeTax",
    }));
    setProcurementDetailList(updatedList);
  };

  const handleAddProcurementDetailItem = useCallback(() => {
    const updatedList = [...procurementDetailList];
    const deepClonedObject = JSON.parse(
      JSON.stringify(procurementDetailInitialObject)
    );

    if (updatedList.length > 0) {
      deepClonedObject.isIncludeTax = updatedList[0].isIncludeTax;
    }

    updatedList.push(deepClonedObject);
    setProcurementDetailList(updatedList);
  }, [procurementDetailList, setProcurementDetailList]);

  const supplierSelectorTemplate = useMemo(() => {
    return (
      <div className="w-full col-span-2 text-2xl">
        <SearchSelector
          title="請選擇廠商"
          value={selectedSupplierValue}
          itemList={supplierOptions}
          onChange={(item) => {
            setSelectedSupplierValue(item.id);
          }}
        ></SearchSelector>
      </div>
    );
  }, [supplierOptions, setSelectedSupplierValue, selectedSupplierValue]);

  const constructionSelectorTemplate = useMemo(() => {
    return (
      <div className="w-full col-span-2 text-2xl">
        <SearchSelector
          title="請選擇工程"
          value={selectedConstructionValue}
          itemList={constructionOptions}
          onChange={(item) => {
            setSelectedConstructionValue(item.id);
          }}
        ></SearchSelector>
      </div>
    );
  }, [
    constructionOptions,
    setSelectedConstructionValue,
    selectedConstructionValue,
  ]);

  const purchaseStatusSelectorTemplate = useMemo(() => {
    return (
      <select
        className="select select-bordered w-full col-span-2 text-2xl"
        value={selectedPurchaseStatusValue}
        onChange={(event) => {
          setSelectedPurchaseStatusValue(event.target.value);
        }}
      >
        <option value="" disabled>
          請選擇採購狀況
        </option>
        {purchaseStatusOptions.map((option) => (
          <option key={option.status} value={option.status}>
            {option.statusName}
          </option>
        ))}
      </select>
    );
  }, [selectedPurchaseStatusValue, setSelectedPurchaseStatusValue]);

  const procurementDetailTemplate = useMemo(() => {
    return procurementDetailList.map((detail, index) => (
      <div className="p-4" key={index}>
        <div className="grid grid-cols-8 place-items-center">
          <div className="font-bold p-4">項目 {index + 1}</div>
          <div className="font-bold p-4">品名</div>
          <div className="col-span-2 w-full max-w-xs">
            <Autocomplete
              fieldName="itemName"
              placeholder="品名..."
              value={detail.itemName}
              onChange={(value) => {
                handleProcurementDetailListChanged(index, "itemName", value);
              }}
            />
          </div>

          <div className="font-bold p-4">規格</div>
          <div className="col-span-2 w-full max-w-xs">
            <Autocomplete
              fieldName="specification"
              placeholder="規格..."
              value={detail.specification}
              onChange={(value) => {
                handleProcurementDetailListChanged(
                  index,
                  "specification",
                  value
                );
              }}
            />
          </div>

          <button
            onClick={() => {
              handleRemoveProcurementDetailItem(index);
            }}
            className="btn btn-error btn-md ml-4"
          >
            刪除
          </button>
        </div>

        <div className="grid grid-cols-8 place-items-center">
          <div></div>
          <div className="font-bold p-4">數量</div>
          <div className="col-span-2 w-full max-w-xs">
            <NumericInput
              placeholder="數量..."
              value={detail.quantity}
              onChange={(event) => {
                handleProcurementDetailListChanged(
                  index,
                  "quantity",
                  event.target.value
                );
              }}
            />
          </div>

          <div className="font-bold p-4">單位</div>
          <div className="col-span-2 w-full max-w-xs">
            <Autocomplete
              fieldName="unit"
              placeholder="單位..."
              value={detail.unit}
              onChange={(value) => {
                handleProcurementDetailListChanged(index, "unit", value);
              }}
            />
          </div>

          <div></div>
        </div>

        <div className="grid grid-cols-8 place-items-center">
          <div></div>
          <div className="font-bold p-4">單價</div>
          <div className="col-span-2 w-full max-w-xs">
            <NumericInput
              placeholder="單價..."
              value={detail.unitPrice}
              onChange={(event) => {
                handleProcurementDetailListChanged(
                  index,
                  "unitPrice",
                  event.target.value
                );
              }}
            />
          </div>

          <div className="font-bold p-4">重量</div>
          <div className="col-span-2 w-full max-w-xs">
            <NumericInput
              placeholder="重量..."
              value={detail.weight}
              onChange={(event) => {
                handleProcurementDetailListChanged(
                  index,
                  "weight",
                  event.target.value
                );
              }}
            />
          </div>

          <div></div>
        </div>

        <div className="grid grid-cols-8 place-items-center">
          <div></div>
          <div className="font-bold p-4">金額</div>
          <div className="font-bold p-4 col-span-2">
            {detail.weight === 0
              ? Math.round(detail.quantity * detail.unitPrice)?.toLocaleString()
              : Math.round(detail.weight * detail.unitPrice)?.toLocaleString()}
          </div>
          <div className="font-bold p-4">備註</div>
          <div className="col-span-2 w-full max-w-xs">
            <input
              type="text"
              placeholder="備註..."
              value={detail.remark}
              onChange={(event) => {
                handleProcurementDetailListChanged(
                  index,
                  "remark",
                  event.target.value
                );
              }}
              className="input input-bordered w-full max-w-xs text-2xl"
            />
          </div>

          <div></div>
        </div>
        <div className="grid grid-cols-8">
          <div></div>
          <div></div>
          <div className="col-span-2 grid grid-cols-2">
            <label
              htmlFor={`excludeTax-${index}`}
              className="flex justify-center items-center"
            >
              <input
                type="radio"
                id={`excludeTax-${index}`}
                name={`tax-${index}`}
                className="radio"
                value="excludeTax"
                checked={!detail.isIncludeTax}
                onChange={(event) => {
                  handleProcurementDetailListIsIncludeTaxChanged(
                    event.target.value
                  );
                }}
              />
              <span className="ml-2">未稅</span>
            </label>
            <label
              htmlFor={`includeTax-${index}`}
              className="flex justify-center items-center"
            >
              <input
                type="radio"
                id={`includeTax-${index}`}
                name={`tax-${index}`}
                className="radio"
                value="includeTax"
                checked={detail.isIncludeTax}
                onChange={(event) => {
                  handleProcurementDetailListIsIncludeTaxChanged(
                    event.target.value
                  );
                }}
              />
              <span className="ml-2">含稅</span>
            </label>
          </div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    ));
  }, [procurementDetailList]);

  return (
    <div className="w-full text-header">
      {isLoading && <Loading />}
      <Header
        title={title}
        isEdit={isEdit}
        upsert={isEdit ? handleUpdate : handleCreate}
        cancel={handleCancel}
      />
      {errorMessage ? <ErrorNotification message={errorMessage} /> : null}
      <div className="border border-gray-400 p-3 mx-20 my-10">
        <div className="grid grid-rows-3">
          <div className="grid grid-cols-6 p-3 place-items-center">
            <div className="font-bold col-span-1">廠商</div>
            {supplierSelectorTemplate}
            <div className="font-bold col-span-1">採購狀況</div>
            {purchaseStatusSelectorTemplate}
          </div>
          <div className="grid grid-cols-6 p-3 place-items-center">
            <div className="font-bold col-span-1">工程</div>
            {constructionSelectorTemplate}
          </div>
          <div className="grid grid-cols-6 p-3 place-items-center">
            <div className="font-bold col-span-1">開單人</div>
            <div className="col-span-2">
              {isEdit ? procurementData?.createUserName : userName}
            </div>
          </div>
        </div>
        {procurementDetailList?.length > 0 ? (
          <div className="divider"></div>
        ) : null}

        {procurementDetailTemplate}
        <div className="divider"></div>
        <div className="flex justify-center items-center">
          <button
            className="btn btn-wide"
            onClick={handleAddProcurementDetailItem}
          >
            新增項目+
          </button>
        </div>
        <div className="divider"></div>
        <div className="grid grid-cols-6 p-3 place-items-center">
          <div className="p-3">合計</div>
          <div className="p-3">{beforeTax?.toLocaleString()}</div>
          <div className="p-3">稅金</div>
          <div className="p-3">{totalTax?.toLocaleString()}</div>
          <div className="p-3">總額</div>
          <div className="p-3">{totalAmount?.toLocaleString()}</div>
        </div>
      </div>
    </div>
  );
};

export default ProcurementEdit;
