/* eslint-disable react/prop-types */
import React, { useState } from "react";
import Dialog from "./Dialog";
import ExportInvoice from "./ExportInvoice";

const InvoiceDetailHeader = ({
  employeeInvoiceID,
  receive,
  disgard,
  edit,
  isOwner,
  isWaiting,
}) => {
  const title = `員工請款單 - 詳細資訊 #${employeeInvoiceID}`;
  const [isOpen, setModalOpen] = useState(false);
  const closeDialog = () => {
    setModalOpen(false);
  };

  return (
    <div className="mx-20 mt-10 flex justify-between items-center">
      <div className="text-5xl font-bold">{title}</div>
      <div className="flex items-center">
        {isOwner && isWaiting ? (
          <button
            className="btn btn-lg btn-outline btn-secondary ml-4"
            onClick={receive}
          >
            簽收
          </button>
        ) : null}

        <ExportInvoice employeeInvoiceID={employeeInvoiceID} />

        {isOwner && isWaiting ? (
          <button
            className="btn btn-lg btn-outline btn-error ml-4"
            onClick={() => {
              setModalOpen(true);
            }}
          >
            作廢
          </button>
        ) : null}

        {isOwner && isWaiting ? (
          <button
            className="btn btn-lg btn-outline btn-info ml-4"
            onClick={edit}
          >
            編輯
          </button>
        ) : null}
      </div>
      <Dialog isOpen={isOpen} cancel={() => closeDialog()}>
        <div className="flex flex-col items-center">
          <div className="text-2xl m-4">確定作廢此請款單？</div>
          <div className="flex">
            <button className="btn btn-lg m-4" onClick={() => closeDialog()}>
              取消
            </button>
            <button className="btn btn-lg btn-error m-4" onClick={disgard}>
              作廢
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default InvoiceDetailHeader;
