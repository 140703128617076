import React, { useEffect, useState } from "react";
import Loading from "../components/Loading";
import StatisticMonthCost from "../components/StatisticMonthCost";
import http from "../services/http";

const initData = {
  eachMonthCostAmount: [],
  totalIncome: 1000000000,
};

const StatisticComprehensiveOverview = () => {
  const [comprehensiveOverview, setComprehensiveOverview] = useState(initData);
  const [isLoading, setLoading] = useState(false);

  const cost = comprehensiveOverview.eachMonthCostAmount.reduce(
    (acc, current) => {
      return acc + current.costAmount;
    },
    0
  );
  const profit = comprehensiveOverview.totalIncome - cost;
  const profitTextClass = profit >= 0 ? "text-[#49CC4E]" : "text-[#FF5454]";

  const fetchComprehensiveOverview = async (year) => {
    setLoading(true);
    try {
      const response = await http.get(
        `auth/statistic/comprehensive?year=${year}`
      );
      if (response) {
        setComprehensiveOverview(response);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    fetchComprehensiveOverview(currentYear);
  }, []);

  return (
    <div className="w-full">
      {isLoading && <Loading />}
      <div className="mx-10 mt-10 text-5xl font-bold">年度綜合統計</div>
      <div className="flex flex-col items-center">
        <div className="w-4/5 m-5 border border-2 border-black rounded-lg">
          <div className="grid grid-cols-3 place-items-center font-bold text-2xl">
            <div className="p-3">該年總支出</div>
            <div className="p-3">該年總收入</div>
            <div className="p-3">該年淨盈利</div>
          </div>
          <div className="grid grid-cols-3 place-items-center text-2xl">
            <div className="p-3">{cost?.toLocaleString()}</div>
            <div className="p-3">
              {comprehensiveOverview.totalIncome?.toLocaleString()}
            </div>
            <div className={`p-3 ${profitTextClass}`}>
              {profit >= 0
                ? `+ ${profit?.toLocaleString()}`
                : profit?.toLocaleString()}
            </div>
          </div>
        </div>
        <StatisticMonthCost
          monthCostList={comprehensiveOverview.eachMonthCostAmount}
          handleYearOnChange={(year) => {
            fetchComprehensiveOverview(year);
          }}
        />
      </div>
    </div>
  );
};

export default StatisticComprehensiveOverview;
