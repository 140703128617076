/* eslint-disable react/prop-types */
import React from "react";
import PeriodSelector from "./PeriodSelector";

const AccountsPayableHeader = ({
  title,
  addnew,
  selectedPeriodValue,
  handlePeriodChanged,
}) => {
  return (
    <div className="mx-10 mt-10 flex justify-between">
      <div className="flex items-center">
        <div className="text-5xl font-bold">{title}</div>
        <PeriodSelector
          selectedPeriodValue={selectedPeriodValue}
          handlePeriodChanged={handlePeriodChanged}
        />
      </div>
      <button
        className="btn btn-lg btn-outline btn-info px-5 py-3 text-2xl flex items-center"
        onClick={addnew}
      >
        <span className="ml-1">新增支出</span>
      </button>
    </div>
  );
};

export default AccountsPayableHeader;
