import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import zhCN from "date-fns/locale/zh-CN"; // 引入中文語言包
import moment from "moment";
import _ from "lodash";

import Header from "../components/Header";
import Dialog from "../components/Dialog";
import Table from "../components/Table";
import Loading from "../components/Loading";

import http from "../services/http";

import {
  header,
  companyOptions,
  statusOptions,
} from "../constants/ConstructionsConstants";

registerLocale("zh-CN", zhCN); // 註冊中文語言包

const formateConstructionsList = (constructionsList) =>
  constructionsList.map((construction) => {
    const companyName = _.find(companyOptions, {
      companyID: construction.companyID,
    }).companyName;
    const statusName = _.find(statusOptions, {
      status: construction.status,
    }).statusName;

    return {
      id: construction.id,
      companyID: construction.companyID,
      companyName: companyName,
      name: construction.name,
      startDate: construction.startDate,
      endDate: construction.endDate,
      status: construction.status,
      statusName: statusName,
    };
  });

const Constructions = () => {
  const [isLoading, setLoading] = useState(false);
  const [constructionList, setConstructionList] = useState([]);
  const [searchedConstructionList, setSearchedConstructionList] = useState([]);
  const [construction, setConstruction] = useState({});
  const [isOpen, setModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isAddnew, setIsAddnew] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const fetchConstructionList = async () => {
      try {
        const response = await http.get("auth/constructionWork/list");
        if (response) {
          setConstructionList(response.constructionWorkList);
          setSearchedConstructionList(
            formateConstructionsList(response.constructionWorkList)
          );
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchConstructionList();
  }, [reload]);

  const searchOnChange = (query) => {
    if (query.length === 0) {
      setSearchedConstructionList(formateConstructionsList(constructionList));
    }

    const lowerQuery = query.toLowerCase();

    setSearchedConstructionList(
      formateConstructionsList(constructionList).filter((item) => {
        return (
          item.id?.toLowerCase()?.includes(lowerQuery) ||
          item.companyID?.toString()?.toLowerCase()?.includes(lowerQuery) ||
          item.companyName?.toLowerCase()?.includes(lowerQuery) ||
          item.name?.toLowerCase()?.includes(lowerQuery) ||
          item.startDate?.toLowerCase()?.includes(lowerQuery) ||
          item.endDate?.toLowerCase()?.includes(lowerQuery) ||
          item.statusName?.toLowerCase()?.includes(lowerQuery)
        );
      })
    );
  };
  const handleAddNew = () => {
    setConstruction({
      name: "",
      companyID: companyOptions[0].companyID,
      companyName: companyOptions[0].companyName,
      startDate: "",
      endDate: "",
      status: statusOptions[0].status,
      statusName: statusOptions[0].statusName,
    });
    setIsAddnew(true);
    setIsEdit(false);
    setModalOpen(true);
  };

  const handleViewDetail = (constructionID) => {
    const c = _.find(formateConstructionsList(constructionList), {
      id: constructionID,
    });
    setConstruction(c);
    setIsAddnew(false);
    setIsEdit(false);
    setModalOpen(true);
  };

  const handleEditDetail = () => {
    setIsAddnew(false);
    setIsEdit(true);
    setModalOpen(true);
  };

  const closeDialog = () => {
    setModalOpen(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "companyID") {
      const id = parseInt(value, 10);
      const companyOption = companyOptions.find((opt) => opt.companyID === id);
      setConstruction({
        ...construction,
        ["companyID"]: companyOption.companyID,
        ["companyName"]: companyOption.companyName,
      });
      return;
    }

    if (name === "status") {
      const statusOption = statusOptions.find((opt) => opt.status === value);
      setConstruction({
        ...construction,
        ["status"]: statusOption.status,
        ["statusName"]: statusOption.statusName,
      });
      return;
    }

    setConstruction({
      ...construction,
      [name]: value,
    });
  };

  const handleStartDateChange = (date) => {
    setConstruction({
      ...construction,
      ["startDate"]: moment(date).format("YYYY-MM-DD"),
    });
  };

  const handleEndDateChange = (date) => {
    setConstruction({
      ...construction,
      ["endDate"]: moment(date).format("YYYY-MM-DD"),
    });
  };

  const createNewConstruction = async (newConstruction) => {
    try {
      setLoading(true);
      const response = await http.post("auth/constructionWork", {
        // eslint-disable-line
        name: newConstruction.name,
        companyID: newConstruction.companyID,
        startDate: newConstruction.startDate,
        endDate: newConstruction.endDate,
        status: newConstruction.status,
      });
      // if (response) {}
    } catch (error) {
      console.log(error);
      alert("新增失敗");
    }
    setReload(!reload);
    closeDialog();
    setLoading(false);
  };

  const updateConstruction = async (construction) => {
    try {
      setLoading(true);
      const response = await http.put("auth/constructionWork", {
        // eslint-disable-line
        id: construction.id,
        name: construction.name,
        companyID: construction.companyID,
        startDate: construction.startDate,
        endDate: construction.endDate,
        status: construction.status,
      });
      // if (response) {}
    } catch (error) {
      console.log(error);
      alert("更新失敗");
    }
    setReload(!reload);
    closeDialog();
    setLoading(false);
  };

  return (
    <div className="w-full">
      {isLoading && <Loading />}
      <Header
        title="工程案列表"
        addnew={handleAddNew}
        search={searchOnChange}
      />
      <Table
        headerList={header}
        bodyList={searchedConstructionList}
        viewDetail={handleViewDetail}
      />
      <Dialog isOpen={isOpen} cancel={() => setModalOpen(false)}>
        <div className="flex flex-col">
          <div className="flex text-3xl font-bold mb-2">
            {isAddnew || isEdit ? (
              <input
                type="text"
                placeholder="請輸入工程名稱"
                className="flex-grow focus:outline-none focus:ring rounded-lg"
                name="name"
                value={construction.name}
                onChange={handleChange}
              />
            ) : (
              construction.name
            )}
          </div>

          <div className="flex flex-col">
            <div className="flex text-xl font-bold border-b m-2">
              承包公司：
              {isAddnew || isEdit ? (
                <select
                  name="companyID"
                  value={construction.companyID}
                  onChange={handleChange}
                >
                  {_.map(companyOptions, (opt) => (
                    <option key={opt.companyID} value={opt.companyID}>
                      {opt.companyName}
                    </option>
                  ))}
                </select>
              ) : (
                <span className="font-normal">{construction.companyName}</span>
              )}
            </div>
            <div className="flex text-xl font-bold border-b m-2">
              開始日期：
              {isAddnew || isEdit ? (
                <DatePicker
                  selected={
                    construction.startDate === ""
                      ? null
                      : moment(construction.startDate).toDate()
                  }
                  onChange={handleStartDateChange}
                  dateFormat="yyyy-MM-dd"
                  locale="zh-CN"
                  placeholderText={"請輸入日期"}
                />
              ) : (
                <span className="font-normal">{construction.startDate}</span>
              )}
            </div>
            <div className="flex text-xl font-bold border-b m-2">
              結束日期：
              {isAddnew || isEdit ? (
                <DatePicker
                  selected={
                    construction.endDate === ""
                      ? null
                      : moment(construction.endDate).toDate()
                  }
                  onChange={handleEndDateChange}
                  dateFormat="yyyy-MM-dd"
                  locale="zh-CN"
                  placeholderText={"請輸入日期"}
                />
              ) : (
                <span className="font-normal">{construction.endDate}</span>
              )}
            </div>
            <div className="flex text-xl font-bold border-b m-2">
              工程狀態：
              {isAddnew || isEdit ? (
                <select
                  name="status"
                  value={construction.status}
                  onChange={handleChange}
                >
                  {_.map(statusOptions, (opt) => (
                    <option key={opt.status} value={opt.status}>
                      {opt.statusName}
                    </option>
                  ))}
                </select>
              ) : (
                <span className="font-normal">{construction.statusName}</span>
              )}
            </div>
          </div>

          <div className="flex justify-center mt-2">
            {isAddnew ? (
              <button
                className="px-6 py-2 border border-black border-solid rounded-xl outline-none text-2xl hover:bg-gray-200"
                onClick={() => {
                  console.log(construction);
                  createNewConstruction(construction);
                }}
              >
                <span className="">新增</span>
              </button>
            ) : isEdit ? (
              <div>
                <button
                  className="mr-5 px-6 py-2 border border-black border-solid rounded-xl outline-none text-2xl hover:bg-gray-200"
                  onClick={() => {
                    console.log(construction);
                    closeDialog();
                  }}
                >
                  <span className="">取消</span>
                </button>

                <button
                  className="ml-5 px-6 py-2 bg-[#5ECC69] rounded-xl outline-none text-[#FFFFFF] text-2xl"
                  onClick={() => {
                    console.log(construction);
                    updateConstruction(construction);
                  }}
                >
                  <span className="">儲存</span>
                </button>
              </div>
            ) : (
              <button
                className="px-6 py-2 border border-black border-solid rounded-xl outline-none text-2xl hover:bg-gray-200"
                onClick={() => {
                  handleEditDetail();
                }}
              >
                <span className="">編輯</span>
              </button>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Constructions;
