import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import zhCN from "date-fns/locale/zh-CN"; // 引入中文語言包
import moment from "moment";

import Http from "../services/http";

import Header from "../components/ProcurementEditHeader";
import ErrorNotification from "../components/ErrorNotification";
import Loading from "../components/Loading";
import NumericInput from "../components/NumericInput";
import Autocomplete from "../components/Autocomplete";

import { purchaseStatusLookup } from "../constants/ProcurementConstants";
import SearchSelector from "../components/SearchSelector";

import ProcurementInvoiceEditHeader from "../components/ProcurementInvoiceEditHeader";

import { RiDeleteBin6Line } from "react-icons/ri";

registerLocale("zh-CN", zhCN); // 註冊中文語言包

const taxRate = 0.05;

const handleNumberType = (title, value) => {
  if (title === "unitPrice" || title === "quantity" || title === "weight") {
    return Number(value);
  } else {
    return value;
  }
};

const ProcurementInvoiceEdit = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const { procurementID, invoiceID } = useParams();
  const [isEditMode, setIsEditMode] = useState(false);
  const [procurementInvoice, setProcurementInvoice] = useState({});
  const [procurementInvoiceDetailList, setProcurementInvoiceDetailList] =
    useState([]);
  const [receiptNumber, setReceiptNumber] = useState("");
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [createUserName, setCreateUserName] = useState("");

  const procurementDetailInitialObject = {
    itemName: "",
    specification: "",
    weight: 0,
    unit: "",
    quantity: 0,
    unitPrice: 0,
    isIncludeTax: false,
    remark: "",
  };

  const beforeTax = () => {
    let isIncludeTax = false;
    const total = procurementInvoiceDetailList.reduce((acc, current) => {
      isIncludeTax = current.isIncludeTax;
      const price =
        current.weight === 0
          ? Math.round(current.quantity * current.unitPrice)
          : Math.round(current.weight * current.unitPrice);
      return acc + price;
    }, 0);

    return isIncludeTax ? Math.round(total / (1 + taxRate)) : total;
  };

  const totalAmount = () => {
    let isIncludeTax = false;
    const total = procurementInvoiceDetailList.reduce((acc, current) => {
      isIncludeTax = current.isIncludeTax;
      const price =
        current.weight === 0
          ? Math.round(current.quantity * current.unitPrice)
          : Math.round(current.weight * current.unitPrice);
      return acc + price;
    }, 0);

    return isIncludeTax ? total : Math.round(total * (1 + taxRate));
  };

  const totalTax = () => {
    return totalAmount() - beforeTax();
  };

  const fetchProcurementInvoice = async () => {
    setLoading(true);
    try {
      const data = await Http.get(
        `auth/procurement/invoice/info?procurementID=${procurementID}&invoiceID=${invoiceID}`
      );

      setProcurementInvoice(data);
      setInvoiceDate(new Date(data.invoiceDate));
      setReceiptNumber(data.receiptNumber);
      setCreateUserName(data.createUserName);
      setProcurementInvoiceDetailList(data.procurementInvoiceDetailList);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  const createProcurementInvoice = async () => {
    setLoading(true);
    try {
      const data = {
        procurementID: parseInt(procurementID),
        receiptNumber: receiptNumber,
        invoiceDate: moment(invoiceDate).format("YYYY-MM-DD"),
        beforeTax: beforeTax(),
        tax: totalTax(),
        totalAmount: totalAmount(),
        procurementInvoiceDetailList: procurementInvoiceDetailList.map(
          (detail) => ({
            ...detail,
            totalAmount:
              detail.weight === 0
                ? Math.round(detail.quantity * detail.unitPrice)
                : Math.round(detail.weight * detail.unitPrice),
          })
        ),
      };

      const response = await Http.post("auth/procurement/invoice", data);

      if (response) {
        navigate(`/procurement/detail/${procurementID}`);
      }
    } catch (error) {
      console.error("Error creating procurement invoice:", error);
    }
    setLoading(false);
  };

  const updateProcurementInvoice = async () => {
    setLoading(true);
    try {
      const data = {
        procurementID: parseInt(procurementID),
        invoiceID: parseInt(invoiceID),
        receiptNumber: receiptNumber,
        invoiceDate: moment(invoiceDate).format("YYYY-MM-DD"),
        beforeTax: beforeTax(),
        tax: totalTax(),
        totalAmount: totalAmount(),
        procurementInvoiceDetailList: procurementInvoiceDetailList.map(
          (detail) => ({
            ...detail,
            totalAmount:
              detail.weight === 0
                ? Math.round(detail.quantity * detail.unitPrice)
                : Math.round(detail.weight * detail.unitPrice),
          })
        ),
      };

      const response = await Http.put("auth/procurement/invoice", data);

      if (response) {
        navigate(`/procurement/detail/${procurementID}`);
      }
    } catch (error) {
      console.error("Error updating procurement invoice:", error);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (invoiceID) {
      setIsEditMode(true);
      fetchProcurementInvoice();
    }
  }, [invoiceID]);

  const handleProcurementInvoiceDetailListChanged = (
    index,
    title,
    updatedValue
  ) => {
    const updatedList = [...procurementInvoiceDetailList];

    updatedList[index][title] = handleNumberType(title, updatedValue);
    setProcurementInvoiceDetailList(updatedList);
  };

  const handleRemoveProcurementInvoiceDetailItem = (index) => {
    const updatedList = [...procurementInvoiceDetailList];

    updatedList.splice(index, 1); // Remove 1 item at the specified index

    setProcurementInvoiceDetailList(updatedList);
  };

  const handleProcurementInvoiceDetailListIsIncludeTaxChanged = (
    updatedValue
  ) => {
    const updatedList = procurementInvoiceDetailList.map((detail) => ({
      ...detail,
      isIncludeTax: updatedValue === "includeTax",
    }));
    setProcurementInvoiceDetailList(updatedList);
  };

  const handleAddProcurementDetailItem = () => {
    const updatedList = [...procurementInvoiceDetailList];
    const deepClonedObject = JSON.parse(
      JSON.stringify(procurementDetailInitialObject)
    );

    if (updatedList.length > 0) {
      deepClonedObject.isIncludeTax = updatedList[0].isIncludeTax;
    }

    updatedList.push(deepClonedObject);
    setProcurementInvoiceDetailList(updatedList);
  };

  const procurementDetailTemplate = () => {
    return procurementInvoiceDetailList.map((detail, index) => (
      <div className="p-4" key={index}>
        <div className="grid grid-cols-8 place-items-center">
          <div className="font-bold p-4">項目 {index + 1}</div>
          <div className="font-bold p-4">品名</div>
          <div className="col-span-2 w-full max-w-xs">
            <Autocomplete
              fieldName="itemName"
              placeholder="品名..."
              value={detail.itemName}
              onChange={(value) => {
                handleProcurementInvoiceDetailListChanged(
                  index,
                  "itemName",
                  value
                );
              }}
            />
          </div>

          <div className="font-bold p-4">規格</div>
          <div className="col-span-2 w-full max-w-xs">
            <Autocomplete
              fieldName="specification"
              placeholder="規格..."
              value={detail.specification}
              onChange={(value) => {
                handleProcurementInvoiceDetailListChanged(
                  index,
                  "specification",
                  value
                );
              }}
            />
          </div>

          <button
            onClick={() => {
              handleRemoveProcurementInvoiceDetailItem(index);
            }}
            className="ml-4"
          >
            <RiDeleteBin6Line className="w-[35px] h-[35px] text-red-500" />
          </button>
        </div>

        <div className="grid grid-cols-8 place-items-center">
          <div></div>
          <div className="font-bold p-4">數量</div>
          <div className="col-span-2 w-full max-w-xs">
            <NumericInput
              placeholder="數量..."
              value={detail.quantity}
              onChange={(event) => {
                handleProcurementInvoiceDetailListChanged(
                  index,
                  "quantity",
                  event.target.value
                );
              }}
            />
          </div>

          <div className="font-bold p-4">單位</div>
          <div className="col-span-2 w-full max-w-xs">
            <Autocomplete
              fieldName="unit"
              placeholder="單位..."
              value={detail.unit}
              onChange={(value) => {
                handleProcurementInvoiceDetailListChanged(index, "unit", value);
              }}
            />
          </div>

          <div></div>
        </div>

        <div className="grid grid-cols-8 place-items-center">
          <div></div>
          <div className="font-bold p-4">單價</div>
          <div className="col-span-2 w-full max-w-xs">
            <NumericInput
              placeholder="單價..."
              value={detail.unitPrice}
              onChange={(event) => {
                handleProcurementInvoiceDetailListChanged(
                  index,
                  "unitPrice",
                  event.target.value
                );
              }}
            />
          </div>

          <div className="font-bold p-4">重量</div>
          <div className="col-span-2 w-full max-w-xs">
            <NumericInput
              placeholder="重量..."
              value={detail.weight}
              onChange={(event) => {
                handleProcurementInvoiceDetailListChanged(
                  index,
                  "weight",
                  event.target.value
                );
              }}
            />
          </div>

          <div></div>
        </div>

        <div className="grid grid-cols-8 place-items-center">
          <div></div>
          <div className="font-bold p-4">金額</div>
          <div className="font-bold p-4 col-span-2">
            {detail.weight === 0
              ? Math.round(detail.quantity * detail.unitPrice)?.toLocaleString()
              : Math.round(detail.weight * detail.unitPrice)?.toLocaleString()}
          </div>
          <div className="font-bold p-4">備註</div>
          <div className="col-span-2 w-full max-w-xs">
            <input
              type="text"
              placeholder="備註..."
              value={detail.remark}
              onChange={(event) => {
                handleProcurementInvoiceDetailListChanged(
                  index,
                  "remark",
                  event.target.value
                );
              }}
              className="input input-bordered w-full max-w-xs text-2xl"
            />
          </div>

          <div></div>
        </div>
        <div className="grid grid-cols-8">
          <div></div>
          <div></div>
          <div className="col-span-2 grid grid-cols-2">
            <label
              htmlFor={`excludeTax-${index}`}
              className="flex justify-center items-center"
            >
              <input
                type="radio"
                id={`excludeTax-${index}`}
                name={`tax-${index}`}
                className="radio"
                value="excludeTax"
                checked={!detail.isIncludeTax}
                onChange={(event) => {
                  handleProcurementInvoiceDetailListIsIncludeTaxChanged(
                    event.target.value
                  );
                }}
              />
              <span className="ml-2">未稅</span>
            </label>
            <label
              htmlFor={`includeTax-${index}`}
              className="flex justify-center items-center"
            >
              <input
                type="radio"
                id={`includeTax-${index}`}
                name={`tax-${index}`}
                className="radio"
                value="includeTax"
                checked={detail.isIncludeTax}
                onChange={(event) => {
                  handleProcurementInvoiceDetailListIsIncludeTaxChanged(
                    event.target.value
                  );
                }}
              />
              <span className="ml-2">含稅</span>
            </label>
          </div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    ));
  };

  return (
    <div className="w-full text-header">
      {isLoading && <Loading />}
      <ProcurementInvoiceEditHeader
        procurementID={procurementID}
        invoiceID={invoiceID}
        isEditMode={isEditMode}
        cancelHandler={() => {
          navigate(`/procurement/detail/${procurementID}`);
        }}
        upsertHandler={() => {
          if (isEditMode) {
            updateProcurementInvoice();
          } else {
            createProcurementInvoice();
          }
        }}
      />
      <div className="border border-gray-400 p-3 mx-10 my-3">
        <div className="flex items-center mb-5">
          <div className="font-bold ml-10 w-[180px] max-h-[56px]">發票號碼</div>
          <input
            value={receiptNumber}
            onChange={(event) => setReceiptNumber(event.target.value)}
            type="text"
            placeholder="發票號碼..."
            className="input input-bordered text-2xl w-[350px] max-h-[56px]"
          />
          <div className="w-[300px] max-h-[56px]"></div>
          <div className="font-bold w-[180px] max-h-[56px]">到貨日期</div>
          <DatePicker
            selected={invoiceDate}
            onChange={(date) => setInvoiceDate(date)}
            dateFormat="yyyy-MM-dd"
            locale="zh-CN"
            placeholderText={"請選擇支出日期"}
            className="input input-bordered text-2xl mr-20 w-[350px] max-h-[56px]"
          />
        </div>
        {isEditMode ? (
          <div className="flex items-center mb-5">
            <div className="font-bold ml-10 w-[180px] max-h-[56px]">開單人</div>
            <div className="text-2xl mr-20 w-[350px] max-h-[56px]">
              {createUserName}
            </div>
          </div>
        ) : null}

        {procurementInvoiceDetailList?.length > 0 ? (
          <div className="divider ml-10 mr-10" />
        ) : null}
        {procurementDetailTemplate()}
        <div className="divider ml-10 mr-10" />
        <div className="flex justify-center items-center">
          <button
            className="btn btn-wide"
            onClick={handleAddProcurementDetailItem}
          >
            新增項目+
          </button>
        </div>
        <div className="divider ml-10 mr-10" />
        <div className="grid grid-cols-6 p-3 place-items-center">
          <div className="p-3">合計</div>
          <div className="p-3">{beforeTax()?.toLocaleString()}</div>
          <div className="p-3">稅金</div>
          <div className="p-3">{totalTax()?.toLocaleString()}</div>
          <div className="p-3">總額</div>
          <div className="p-3">{totalAmount()?.toLocaleString()}</div>
        </div>
      </div>
    </div>
  );
};

export default ProcurementInvoiceEdit;
