export const header = [
  {
    name: "編號",
    key: "id",
  },
  {
    name: "工程名稱",
    key: "name",
  },
  {
    name: "承包公司",
    key: "companyName",
  },
  {
    name: "開始日期",
    key: "startDate",
  },
  {
    name: "結束日期",
    key: "endDate",
  },
  {
    name: "工程狀態",
    key: "statusName",
  },
];

export const companyOptions = [
  { companyID: 0, companyName: "維瑞機械有限公司" },
  { companyID: 1, companyName: "華瑞機械有限公司" },
];

export const statusOptions = [
  { status: "in_process", statusName: "進行中" },
  { status: "done", statusName: "已完成" },
];
