import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Http from "../services/http";

import Header from "../components/Header";
import Table from "../components/InfinityTable";

import { header, statusLookup } from "../constants/InvoiceConstants";

const LOADING_LIMIT = 20;

const Invoice = () => {
  const navigate = useNavigate();

  const [invoiceList, setInvoiceList] = useState([]);
  const [nextCursor, setNextCursor] = useState("");
  const [searchedInvoiceList, setSearchedInvoiceList] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const fetchData = async (nextCursor) => {
    try {
      const response = await Http.get("auth/employeeInvoice/list?limit=20", {
        params: { limit: LOADING_LIMIT, next: nextCursor },
      });
      const formatedList = response.employeeInvoiceList.map((item) => ({
        ...item,
        totalAmount: item.totalAmount?.toLocaleString(),
        status: statusLookup[item.status],
      }));

      setInvoiceList((prevList) => {
        return [...prevList, ...formatedList];
      });
      setSearchedInvoiceList((prevList) => {
        return [...prevList, ...formatedList];
      });
      setNextCursor(response.nextCursor);

      if (
        response.employeeInvoiceList.length === 0 &&
        response.nextCursor === ""
      ) {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(nextCursor);
  }, []);

  const handleAddNew = () => {
    navigate("/employeeInvoice/edit");
  };
  const handleViewDetail = (invoiceID) => {
    navigate(`/employeeInvoice/detail/${invoiceID}`);
  };
  const searchOnChange = (query) => {
    if (query.length === 0) {
      setSearchedInvoiceList(invoiceList);
    }

    const lowerQuery = query.toLowerCase();

    setSearchedInvoiceList(
      invoiceList.filter((item) => {
        return (
          item.id.toString().toLowerCase().includes(lowerQuery) ||
          item.createDate.toLowerCase().includes(lowerQuery) ||
          item.totalAmount.toString().toLowerCase().includes(lowerQuery) ||
          item.constructionWorkName.toLowerCase().includes(lowerQuery) ||
          item.applyUserName.toLowerCase().includes(lowerQuery) ||
          item.status.toLowerCase().includes(lowerQuery)
        );
      })
    );
  };

  return (
    <div className="w-full">
      <Header
        title="員工請款單"
        addnew={handleAddNew}
        search={searchOnChange}
      />
      <Table
        headerList={header}
        bodyList={searchedInvoiceList}
        viewDetail={handleViewDetail}
        dataLength={invoiceList.length}
        next={() => {
          fetchData(nextCursor);
        }}
        hasMore={hasMore}
      />
    </div>
  );
};

export default Invoice;
