/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";

const Autocomplete = ({ value, fieldName, placeholder, onChange }) => {
  let items = [];
  try {
    const storageData = localStorage.getItem("autocomplete");
    const autocomplete = storageData ? JSON.parse(storageData) : {};
    items = autocomplete[fieldName] ? autocomplete[fieldName] : [];
  } catch (error) {
    console.log(error);
  }

  const [open, setOpen] = useState(false);
  const [searchedItemList, setSearchedItemList] = useState(items);
  const dropdownOpenClass = open ? "" : "hidden";

  const searchOnChange = (query) => {
    if (query.length === 0) {
      console.log(items);
      setSearchedItemList(items);
    }

    const lowerQuery = query.toLowerCase();

    setSearchedItemList(
      items.filter((item) => {
        return item.toLowerCase().includes(lowerQuery);
      })
    );
  };

  return (
    <div className={`dropdown w-full`}>
      <input
        type="text"
        className="input input-bordered w-full text-2xl"
        value={value}
        onChange={(e) => {
          searchOnChange(e.target.value);
          onChange(e.target.value);
        }}
        onFocus={() => {
          searchOnChange(value);
          setOpen(true);
        }}
        placeholder={placeholder}
        tabIndex={0}
      />
      <div
        className={`dropdown-content bg-base-200 z-50 max-h-48 overflow-auto flex-col rounded-md w-full ${dropdownOpenClass}`}
      >
        <ul className="menu menu-compact">
          {searchedItemList.map((item, index) => {
            return (
              <li
                key={index}
                tabIndex={index + 1}
                onClick={() => {
                  onChange(item);
                  setOpen(false);
                }}
                className="border-b border-b-base-content/10 w-full"
              >
                <button>{item}</button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Autocomplete;
