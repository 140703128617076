/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import Http from "../services/http";
import * as ExcelJS from "exceljs";
import SearchSelector from "./SearchSelector";
import PeriodSelector from "./PeriodSelector";
import { extractPeriodID } from "../utils/periodUtils";
import moment from "moment";
import Loading from "./Loading";
import { paymentMethodLookup } from "../constants/AccountsPayableConstants";
import { purchaseStatusLookup } from "../constants/ProcurementConstants";

const ExportAllProcurementBySupplier = ({ cancel }) => {
  const [selectedSupplierID, setSelectedSupplierID] = useState("");
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [selectedPeriodValue, setSelectedPeriodValue] = useState("");
  const [isLoading, setLoading] = useState(false);

  const fetchSupplierOptions = async () => {
    try {
      const response = await Http.get(`auth/supplier/list`);
      setSupplierOptions(response.supplierList);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const currentYear = moment().format("YYYY");
    const currentMonth = moment().format("MM");
    setSelectedPeriodValue(`${currentYear}-${currentMonth}`);
    fetchSupplierOptions();
  }, []);

  const fetchSupplierInfo = async (supplierID) => {
    try {
      const response = await Http.get(
        `auth/supplier/info?supplierID=${supplierID}`
      );
      return response;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchProcurementInfo = async (year, month, supplierID) => {
    try {
      const response = await Http.get(
        `auth/procurement/exports?year=${year}&month=${month}&supplierID=${supplierID}`
      );
      return response;
    } catch (error) {
      console.error("Error fetching data:", error);
      return {};
    }
  };

  const handlePeriodChanged = async (event) => {
    const { year, month } = extractPeriodID(event.target.value);
    setSelectedPeriodValue(`${year}-${month}`);
  };
  const handleExport = async () => {
    if (!selectedPeriodValue || !selectedSupplierID) {
      return;
    }

    setLoading(true);
    const { year, month } = extractPeriodID(selectedPeriodValue);
    const procurementInfo = await fetchProcurementInfo(
      year,
      month,
      selectedSupplierID
    );
    const supplierInfo = await fetchSupplierInfo(selectedSupplierID);
    // TODO use procurementList & supplierInfo to create excel
    const workbook = new ExcelJS.Workbook();

    //封面 row_1~2
    const worksheetCover = workbook.addWorksheet(`${year}-${month}_封面`);
    const header = [
      ["華瑞/維瑞機械有限公司"],
      [`【${year}-${month}月份付款明細】`],
    ];
    header.forEach((e) => worksheetCover.addRow(e));
    worksheetCover.getCell("A1").font = { bold: true, size: 18 };
    worksheetCover.getCell("A1").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    worksheetCover.getCell("A2").font = { bold: true, size: 12 };
    worksheetCover.getCell("A2").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    worksheetCover.mergeCells("A1:G1");
    worksheetCover.mergeCells("A2:G2");

    const row3 = ["公司名稱：", "", "", "", "", "公司電話：", ""];
    worksheetCover.addRow(row3);
    worksheetCover.getCell("B3").value = String(supplierInfo.name);
    worksheetCover.getCell("G3").value = String(supplierInfo.tel);
    worksheetCover.mergeCells("B3:E3");
    worksheetCover.getRow(3).font = { size: 12 };
    worksheetCover.getRow(3).alignment = { vertical: "middle" };

    const row4 = ["公司統編：", "", "", "", "", "公司傳真：", ""];
    worksheetCover.addRow(row4);
    worksheetCover.getCell("B4").value = String(supplierInfo.taxID);
    worksheetCover.getCell("G4").value = String(supplierInfo.fax);
    worksheetCover.mergeCells("B4:E4");
    worksheetCover.getRow(4).font = { size: 12 };
    worksheetCover.getRow(4).alignment = { vertical: "middle" };

    const row5 = ["公司地址：", "", "", "", "", "備註：", ""];
    worksheetCover.addRow(row5);
    worksheetCover.getCell("B5").value = String(supplierInfo.address);
    worksheetCover.getCell("G5").value = String(supplierInfo.remark);
    worksheetCover.mergeCells("B5:E5");
    worksheetCover.getRow(5).font = { size: 12 };
    worksheetCover.getRow(5).alignment = { vertical: "middle" };

    const row6 = [
      "採購單號",
      "付款方式",
      "付款號碼",
      "發票號碼",
      "合計(未稅)",
      "稅金",
      "總計(含稅)",
    ];
    worksheetCover.addRow(row6);
    worksheetCover.getRow(6).font = { bold: true, size: 12 };
    worksheetCover.getRow(6).eachCell((cell) => {
      if (cell.col === 1) {
        cell.border = {
          top: { style: "medium", color: { argb: "FF000000" } },
          left: { style: "medium", color: { argb: "FF000000" } },
        };
      } else if (cell.col === 7) {
        cell.border = {
          top: { style: "medium", color: { argb: "FF000000" } },
          right: { style: "medium", color: { argb: "FF000000" } },
        };
      } else {
        cell.border = {
          top: { style: "medium", color: { argb: "FF000000" } },
        };
      }

      cell.alignment = { horizontal: "center", vertical: "middle" };
    });

    let nowRow;
    procurementInfo?.procurementList?.map((p) => {
      const procurementInfo = [];
      procurementInfo.push(`#${p.id}`);
      procurementInfo.push(paymentMethodLookup[p.paymentMethod]);
      procurementInfo.push(p.paymentNumber);
      procurementInfo.push(p.receiptNumber);
      procurementInfo.push(p.beforeTax);
      procurementInfo.push(p.tax);
      procurementInfo.push(p.totalAmount);
      nowRow = worksheetCover.addRow(procurementInfo);
      worksheetCover.getRow(nowRow.number).eachCell((cell) => {
        if (cell.col === 1) {
          cell.border = {
            left: { style: "medium", color: { argb: "FF000000" } },
          };
        } else if (cell.col === 5) {
          cell.numFmt = "#,##0";
        } else if (cell.col === 6) {
          cell.numFmt = "#,##0";
        } else if (cell.col === 7) {
          cell.numFmt = "#,##0";
          cell.border = {
            right: { style: "medium", color: { argb: "FF000000" } },
          };
        }

        cell.alignment = { horizontal: "center", vertical: "middle" };
      });
    });

    const totalAmount = ["", "", "", "", "", "合計應付金額：", ""];
    nowRow = worksheetCover.addRow(totalAmount);
    worksheetCover.getCell(`F${nowRow.number}`).font = { bold: true };
    worksheetCover.getCell(`G${nowRow.number}`).value =
      procurementInfo.totalAmount;
    worksheetCover.getCell(`G${nowRow.number}`).numFmt = "#,##0";
    worksheetCover.getRow(nowRow.number).eachCell((cell) => {
      if (cell.col === 1) {
        cell.border = {
          bottom: { style: "medium", color: { argb: "FF000000" } },
          left: { style: "medium", color: { argb: "FF000000" } },
        };
      } else if (cell.col === 7) {
        cell.border = {
          bottom: { style: "medium", color: { argb: "FF000000" } },
          right: { style: "medium", color: { argb: "FF000000" } },
        };
      } else {
        cell.border = {
          bottom: { style: "medium", color: { argb: "FF000000" } },
        };
      }

      cell.alignment = { horizontal: "center", vertical: "middle" };
    });

    const allocateHeader = [
      "分攤明細：",
      "工程名稱",
      "",
      "",
      "分攤金額(含稅)",
      "",
      "",
    ];
    nowRow = worksheetCover.addRow(allocateHeader);
    nowRow.alignment = { horizontal: "center", vertical: "middle" };
    nowRow.font = { bold: true };
    worksheetCover.mergeCells(`B${nowRow.number}:D${nowRow.number}`);
    worksheetCover.mergeCells(`E${nowRow.number}:G${nowRow.number}`);
    nowRow.eachCell((cell) => {
      if (cell.col === 1) {
        cell.border = {
          top: { style: "medium", color: { argb: "FF000000" } },
          left: { style: "medium", color: { argb: "FF000000" } },
        };
      } else if (cell.col === 7) {
        cell.border = {
          top: { style: "medium", color: { argb: "FF000000" } },
          right: { style: "medium", color: { argb: "FF000000" } },
        };
      } else {
        cell.border = {
          top: { style: "medium", color: { argb: "FF000000" } },
        };
      }
    });

    procurementInfo.constructionWorkAllocateList.map((a) => {
      const allocateDetail = ["", a.name, "", "", a.allocateAmount, "", ""];
      nowRow = worksheetCover.addRow(allocateDetail);
      worksheetCover.getCell(`E${nowRow.number}`).numFmt = "#,##0";
      nowRow.eachCell((cell) => {
        if (cell.col === 1) {
          cell.border = {
            left: { style: "medium", color: { argb: "FF000000" } },
          };
        } else if (cell.col === 5) {
          cell.border = {
            right: { style: "medium", color: { argb: "FF000000" } },
          };
        }
      });
      worksheetCover.mergeCells(`B${nowRow.number}:D${nowRow.number}`);
      worksheetCover.mergeCells(`E${nowRow.number}:G${nowRow.number}`);
      nowRow.alignment = { horizontal: "center", vertical: "middle" };
    });

    const remark = ["備註：", "", "", "", "", "", ""];
    nowRow = worksheetCover.addRow(remark);
    nowRow.font = { bold: true };
    nowRow.alignment = { horizontal: "center", vertical: "middle" };
    nowRow.eachCell((cell) => {
      if (cell.col === 1) {
        cell.border = {
          top: { style: "medium", color: { argb: "FF000000" } },
          left: { style: "medium", color: { argb: "FF000000" } },
        };
      } else if (cell.col === 7) {
        cell.border = {
          top: { style: "medium", color: { argb: "FF000000" } },
          right: { style: "medium", color: { argb: "FF000000" } },
        };
      } else {
        cell.border = {
          top: { style: "medium", color: { argb: "FF000000" } },
        };
      }
    });
    const remark2 = ["", "", "", "", "", "", ""];
    nowRow = worksheetCover.addRow(remark2);
    nowRow.alignment = { horizontal: "center", vertical: "middle" };
    nowRow.eachCell((cell) => {
      if (cell.col === 1) {
        cell.border = {
          bottom: { style: "medium", color: { argb: "FF000000" } },
          left: { style: "medium", color: { argb: "FF000000" } },
        };
      } else if (cell.col === 7) {
        cell.border = {
          bottom: { style: "medium", color: { argb: "FF000000" } },
          right: { style: "medium", color: { argb: "FF000000" } },
        };
      } else {
        cell.border = {
          bottom: { style: "medium", color: { argb: "FF000000" } },
        };
      }
    });

    const sign = ["簽核：", "", "", "主管：", "", "", ""];
    nowRow = worksheetCover.addRow(sign);
    nowRow.font = { bold: true };
    nowRow.alignment = { horizontal: "center", vertical: "middle" };

    worksheetCover.getColumn("A").width = 12;
    worksheetCover.getColumn("B").width = 12;
    worksheetCover.getColumn("C").width = 12;
    worksheetCover.getColumn("D").width = 12;
    worksheetCover.getColumn("E").width = 12;
    worksheetCover.getColumn("F").width = 16;
    worksheetCover.getColumn("G").width = 12;

    //明細
    const worksheetDetail = workbook.addWorksheet(
      `${year}-${month}_採購單明細`
    );

    const headerData = [
      [`${year}-${month}_${supplierInfo.name}_採購單明細`],
      [
        "採購單編號",
        "日期",
        "品名",
        "規格",
        "數量",
        "單位",
        "重量(kg)",
        "單價",
        "金額",
        "工程名稱",
        "採購狀況",
        "備註",
      ],
    ];

    // 寫入標頭
    headerData.forEach((e) => worksheetDetail.addRow(e));

    // 合併儲存格
    worksheetDetail.mergeCells("A1:L1");

    // 調整標頭字型＆對齊
    worksheetDetail.getRow(1).font = { bold: true, size: 18 };
    worksheetDetail.getRow(1).alignment = {
      horizontal: "center",
      vertical: "center",
    };
    worksheetDetail.getRow(2).font = { bold: true, size: 15 };
    worksheetDetail.getRow(2).alignment = {
      horizontal: "center",
      vertical: "center",
    };

    // 調整各列寬
    worksheetDetail.getColumn("A").width = 15;
    worksheetDetail.getColumn("B").width = 15;
    worksheetDetail.getColumn("C").width = 25;
    worksheetDetail.getColumn("D").width = 25;
    worksheetDetail.getColumn("E").width = 12;
    worksheetDetail.getColumn("F").width = 12;
    worksheetDetail.getColumn("G").width = 15;
    worksheetDetail.getColumn("H").width = 12;
    worksheetDetail.getColumn("I").width = 15;
    worksheetDetail.getColumn("J").width = 20;
    worksheetDetail.getColumn("K").width = 15;
    worksheetDetail.getColumn("L").width = 15;

    procurementInfo?.procurementList?.forEach((p, pIndex) => {
      p.procurementDetail.forEach((detail, detailIndex) => {
        const newRow = [];
        if (detailIndex === 0) {
          newRow.push(`#${p.id}`);
          newRow.push(p.createTime.split(" ")[0]);
        } else {
          newRow.push("");
          newRow.push("");
        }
        newRow.push(detail.itemName);
        newRow.push(detail.specification);
        newRow.push(detail.quantity);
        newRow.push(detail.unit);
        newRow.push(detail.weight);
        newRow.push(detail.unitPrice);
        newRow.push(detail.totalAmount);
        if (detailIndex === 0) {
          newRow.push(p.constructionWorkName);
          newRow.push(purchaseStatusLookup[p.purchaseStatus]);
        } else {
          newRow.push("");
          newRow.push("");
        }
        newRow.push(detail.remark);

        worksheetDetail.addRow(newRow);
      });
    });

    // 匯出
    const content = await workbook.xlsx.writeBuffer();
    const link = document.createElement("a");
    const blobData = new Blob([content], {
      type: "application/vnd.ms-excel;charset=utf-8;",
    });
    link.download = `${year}-${month}_${supplierInfo.name}_採購單.xlsx`;
    link.href = URL.createObjectURL(blobData);
    link.click();
    setLoading(false);
  };

  return (
    <div className="flex flex-col items-center">
      {isLoading && <Loading />}
      <div className="font-bold text-2xl m-4">
        匯出 該月廠商 所有 已核准 採購單及封面
      </div>

      <div className="flex w-full">
        <PeriodSelector
          selectedPeriodValue={selectedPeriodValue}
          handlePeriodChanged={handlePeriodChanged}
        />
        <div className="w-full text-3xl ml-2">
          <SearchSelector
            title="請選擇廠商"
            value={selectedSupplierID}
            itemList={supplierOptions}
            onChange={(item) => {
              setSelectedSupplierID(item.id);
            }}
          ></SearchSelector>
        </div>
      </div>

      <div className="flex">
        <button className="btn btn-lg mt-4 mr-4" onClick={cancel}>
          取消
        </button>
        <button
          className="btn btn-lg btn-success mt-4 ml-4"
          onClick={handleExport}
        >
          匯出
        </button>
      </div>
    </div>
  );
};

export default ExportAllProcurementBySupplier;
