import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../components/Loading";
import StatisticMonthCost from "../components/StatisticMonthCost";
import http from "../services/http";

const initData = {
  name: "",
  startDate: "",
  endDate: "",
  costAmount: 0,
  contractAmount: 0,
  paidAmount: 0,
  eachMonthCostAmount: [],
};

const StatisticConstructionOverview = () => {
  const [constructionOverview, setConstructionOverview] = useState(initData);
  const [isLoading, setLoading] = useState(false);

  const { id } = useParams();

  const profit =
    constructionOverview.paidAmount - constructionOverview.costAmount;
  const profitTextClass = profit >= 0 ? "text-[#49CC4E]" : "text-[#FF5454]";

  const fetchConstructionOverview = async (year) => {
    setLoading(true);
    try {
      const response = await http.get(
        `auth/statistic/constructionWork/overview?year=${year}&constructionWorkID=${id}`
      );
      if (response) {
        setConstructionOverview(response);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    fetchConstructionOverview(currentYear);
  }, []);

  return (
    <div className="w-full">
      {isLoading && <Loading />}
      <div className="mx-10 mt-10 text-5xl font-bold">
        工程案收支統計-{`${constructionOverview.name}`}
      </div>
      <div className="flex flex-col items-center">
        <div className="w-4/5 m-5 border border-2 border-black rounded-lg">
          <div className="grid grid-cols-6 place-items-center font-bold text-2xl">
            <div className="p-3">開始時間</div>
            <div className="p-3">結束時間</div>
            <div className="p-3">累計總成本</div>
            <div className="p-3">合約金額</div>
            <div className="p-3">已收金額</div>
            <div className="p-3">淨盈利</div>
          </div>
          <div className="grid grid-cols-6 place-items-center text-2xl">
            <div className="p-3">{constructionOverview.startDate}</div>
            <div className="p-3">
              {constructionOverview.endDate !== ""
                ? constructionOverview.endDate
                : "-"}
            </div>
            <div className="p-3">
              {constructionOverview.costAmount?.toLocaleString()}
            </div>
            <div className="p-3">
              {constructionOverview.contractAmount?.toLocaleString()}
            </div>
            <div className="p-3">
              {constructionOverview.paidAmount?.toLocaleString()}
            </div>
            <div className={`p-3 ${profitTextClass}`}>
              {profit >= 0
                ? `+ ${profit?.toLocaleString()}`
                : profit?.toLocaleString()}
            </div>
          </div>
        </div>
        <StatisticMonthCost
          monthCostList={constructionOverview.eachMonthCostAmount}
          handleYearOnChange={(year) => {
            fetchConstructionOverview(year);
          }}
        />
      </div>
    </div>
  );
};

export default StatisticConstructionOverview;
