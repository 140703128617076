import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Http from "../services/http";

import Header from "../components/OtherExpenditureHeader";
import ErrorNotification from "../components/ErrorNotification";
import { getYearMonth } from "../utils/tool";

import {
  paidStatusLookup,
  paymentMethodLookup,
  employeeInvoiceStatusLookup,
} from "../constants/AccountsPayableConstants";
import Loading from "../components/Loading";

const OtherExpenditure = () => {
  const navigate = useNavigate();
  const [expenditureData, setExpenditureData] = useState({});
  const { id } = useParams();

  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  const title = `其他支出-詳細資料 #${id}`;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await Http.get(
          `auth/accountsPayable/otherExpenditure/${id}`
        );

        setExpenditureData(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  const handleEdit = async () => {
    navigate(`/account-payable/detail/otherExpenditure/edit/${id}`);
  };

  const handleDiscard = async () => {
    setLoading(true);
    try {
      await Http.delete(`auth/accountsPayable/otherExpenditure/${id}`);
      const [year, month] = getYearMonth(expenditureData.expenditureDate);
      navigate(`/account-payable/${year}/${month}`);
    } catch (error) {
      setErrorMessage("刪除請款單錯誤，請稍後再試");
      console.error("Error for deleting:", error);
    }
    setLoading(false);
  };

  return (
    <div className="w-full text-header">
      {isLoading && <Loading />}
      <Header title={title} edit={handleEdit} disgard={handleDiscard} />
      {errorMessage ? <ErrorNotification message={errorMessage} /> : null}
      <div className="border border-gray-400 p-3 mx-20 my-10">
        <div className="grid grid-rows-11">
          <div className="flex items-center">
            <div className="font-bold p-3 w-1/5">開單人</div>
            <div className="p-3">{expenditureData.createUserName}</div>
          </div>
          <div className="flex items-center">
            <div className="font-bold p-3 w-1/5">交易對象</div>
            <div className="p-3">{expenditureData.payeeName}</div>
          </div>
          <div className="flex items-center">
            <div className="font-bold p-3 w-1/5">支出日期</div>
            <div className="p-3">{expenditureData.expenditureDate}</div>
          </div>
          <div className="flex items-center">
            <div className="font-bold p-3 w-1/5">支出內容</div>
            <div className="p-3">{expenditureData.content}</div>
          </div>
          <div className="flex items-center">
            <div className="font-bold p-3 w-1/5">備註</div>
            <div className="p-3">{expenditureData.remark}</div>
          </div>
          <div className="flex items-center">
            <div className="font-bold p-3 w-1/5">單價</div>
            <div className="p-3">
              {expenditureData.unitPrice?.toLocaleString()}
            </div>
          </div>
          <div className="flex items-center">
            <div className="font-bold p-3 w-1/5">數量</div>
            <div className="p-3">
              {expenditureData.quantity?.toLocaleString()}
            </div>
          </div>
          <div className="flex items-center">
            <div className="font-bold p-3 w-1/5">金額</div>
            <div className="p-3">
              {expenditureData.totalAmount?.toLocaleString()}
            </div>
          </div>
          <div className="flex">
            <div className="font-bold p-3 w-1/5">工程</div>
            <div className="flex flex-wrap items-center w-4/5">
              {expenditureData?.constructionWorkAllocateList?.map((work) => (
                <div key={work.id} className="p-3">
                  {`${work.name}($${work.allocateAmount?.toLocaleString()})`}
                </div>
              ))}
            </div>
          </div>
          <div className="flex items-center">
            <div className="font-bold p-3 w-1/5">付款狀態</div>
            <div className="p-3 w-1/5">
              {paidStatusLookup[expenditureData.paymentStatus]}
            </div>
            <div className="font-bold p-3 w-1/5">付款方式</div>
            <div className="p-3 w-1/5">
              {paymentMethodLookup[expenditureData.paymentMethod]}
            </div>
          </div>
          <div className="flex items-center">
            <div className="font-bold p-3 w-1/5">付款號碼</div>
            <div className="p-3 w-1/5">{expenditureData.paymentNumber}</div>
            <div className="font-bold p-3 w-1/5">發票號碼</div>
            <div className="p-3 w-1/5">{expenditureData.receiptNumber}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherExpenditure;
