import React from "react";

const Loading = () => {
  return (
    <div className="h-full w-full fixed top-0 left-0 flex justify-center items-center bg-gray-800 bg-opacity-50 z-[9999]">
      <span className="loading loading-dots w-24"></span>
    </div>
  );
};
export default Loading;
