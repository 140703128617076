/* eslint-disable react/jsx-key */
import { useSearchParams, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { RiDeleteBinLine, RiAddCircleLine } from "react-icons/ri";
import http from "../services/http";
import { GenerateRandomNumber } from "../utils/tool";
import Loading from "../components/Loading";

const headerList = [
  {
    name: "",
    key: "totalHour",
  },
  {
    name: "員工",
    key: "name",
  },
  {
    name: "",
    key: "add",
  },
  {
    name: "工數",
    key: "hour",
  },
  {
    name: "工程",
    key: "construction",
  },
  {
    name: "",
    key: "delete",
  },
];

const workHoursOptions = [
  { hours: -1, name: "請假／休假" },
  { hours: 0, name: "0小時" },
  { hours: 0.5, name: "半小時" },
  { hours: 1, name: "1小時" },
  { hours: 1.5, name: "1.5小時" },
  { hours: 2, name: "2小時" },
  { hours: 2.5, name: "2.5小時" },
  { hours: 3, name: "3小時" },
  { hours: 3.5, name: "3.5小時" },
  { hours: 4, name: "4小時" },
  { hours: 4.5, name: "4.5小時" },
  { hours: 5, name: "5小時" },
  { hours: 5.5, name: "5.5小時" },
  { hours: 6, name: "6小時" },
  { hours: 6.5, name: "6.5小時" },
  { hours: 7, name: "7小時" },
  { hours: 7.5, name: "7.5小時" },
  { hours: 8, name: "8小時" },
  { hours: 8.5, name: "8.5小時" },
  { hours: 9, name: "9小時" },
  { hours: 9.5, name: "9.5小時" },
  { hours: 10, name: "10小時" },
  { hours: 10.5, name: "10.5小時" },
  { hours: 11, name: "11小時" },
  { hours: 11.5, name: "11.5小時" },
  { hours: 12, name: "12小時" },
  { hours: 12.5, name: "12.5小時" },
];

const genEmptyWorkHour = () => ({
  id: GenerateRandomNumber(7),
  constructionWorkID: "",
  constructionName: "",
  hours: 0,
});

const WorkDailyInfo = () => {
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const [date, setDate] = useState(searchParam.get("date")); // eslint-disable-line
  const [isLoading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [constructionList, setConstructionList] = useState([]);
  const [dailyWorkHoursList, setDailyWorkHoursList] = useState([]);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const fetchDailyWorkHoursList = async () => {
      try {
        setLoading(true);
        const response = await http.get("auth/dailyWorkHours/info", {
          params: { date: date },
        });
        if (response) {
          setDailyWorkHoursList(response);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    fetchDailyWorkHoursList();
  }, [reload]);

  useEffect(() => {
    const fetchConstructionList = async () => {
      try {
        setLoading(true);
        const response = await http.get("auth/constructionWork/list");
        if (response) {
          setConstructionList([
            { id: "", name: "-" },
            ...response.constructionWorkList,
          ]);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    fetchConstructionList();
  }, []);

  const handleHoursChange = (event, userID, workHourID) => {
    // name:userID, value: hours
    const { value } = event.target;
    let hours = parseFloat(value);

    const newDailyWorkHoursList = dailyWorkHoursList.map((employee) => {
      if (employee.userID === userID) {
        if (employee.dayOff && hours !== -1) {
          employee.dayOff = false;
        }

        //設為請假
        if (hours === -1) {
          employee.dayOff = true;
          employee.totalWorkHours = 0;
          let newWorkHour = employee.workHours[0];
          newWorkHour.constructionName = "";
          newWorkHour.constructionWorkID = "";
          newWorkHour.hours = 0;
          return { ...employee, workHours: [newWorkHour] };
        }

        let newTotalHours = 0;
        const updatedWorkHours = employee.workHours.map((workHour) => {
          if (workHour.id === workHourID) {
            newTotalHours += hours;
            return { ...workHour, hours: hours };
          } else {
            newTotalHours += workHour.hours;
            return workHour;
          }
        });
        employee.totalWorkHours = newTotalHours;
        return { ...employee, workHours: updatedWorkHours };
      } else {
        return employee;
      }
    });

    setDailyWorkHoursList(newDailyWorkHoursList);
  };

  const handleConstructionChange = (event, userID, workHourID) => {
    const constructionID = event.target.value;

    const newDailyWorkHoursList = dailyWorkHoursList.map((employee) => {
      if (employee.userID === userID) {
        const updatedWorkHours = employee.workHours.map((workHour) => {
          if (workHour.id === workHourID) {
            return { ...workHour, constructionWorkID: constructionID };
          } else {
            return workHour;
          }
        });

        return { ...employee, workHours: updatedWorkHours };
      } else {
        return employee;
      }
    });

    setDailyWorkHoursList(newDailyWorkHoursList);
  };

  const handleAddWorkHour = (userID) => {
    const newDailyWorkHoursList = dailyWorkHoursList.map((employee) => {
      if (employee.userID === userID) {
        const updatedWorkHours = [...employee.workHours, genEmptyWorkHour()];
        return { ...employee, workHours: updatedWorkHours };
      } else {
        return employee;
      }
    });

    setDailyWorkHoursList(newDailyWorkHoursList);
  };

  const handleDeleteWorkHour = (userID, workHourID) => {
    const newDailyWorkHoursList = dailyWorkHoursList.map((employee) => {
      if (employee.userID === userID && employee.workHours.length > 1) {
        const updatedWorkHours = employee.workHours.filter((workHour) => {
          if (workHour.id === workHourID) {
            employee.totalWorkHours -= workHour.hours;
            return false;
          } else {
            return true;
          }
        });

        return { ...employee, workHours: updatedWorkHours };
      } else {
        return employee;
      }
    });

    setDailyWorkHoursList(newDailyWorkHoursList);
  };

  const handleSaveChange = () => {
    const updateDailyWorkHoursList = async () => {
      try {
        const reqEmployeeWorkHoursList = dailyWorkHoursList.map((item) => {
          const newWorkHours = item.workHours.map((workHour) => {
            const { id, ...rest } = workHour; // eslint-disable-line
            // 移除 id 欄位
            return rest;
          });

          return {
            ...item,
            workHours: newWorkHours,
          };
        });
        setLoading(true);
        const response = await http.put("auth/dailyWorkHours", {
          date: date,
          employeeWorkHoursList: reqEmployeeWorkHoursList,
        });
        if (response) {
          setIsEdit(false);
          setReload(!reload);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    updateDailyWorkHoursList();
  };

  const handleSetDayOff = () => {
    const setDayOff = async () => {
      try {
        setLoading(true);
        const response = await http.put("auth/dailyWorkHours/dayoff", {
          date: date,
        });
        if (response) {
          setIsEdit(false);
          navigate("/employeeWorkDaily");
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    setDayOff();
  };

  return (
    <div className="w-full">
      {isLoading && <Loading />}
      <div className="flex ml-10 mr-36 mt-10 justify-between">
        <div
          className="text-5xl font-bold"
          onClick={() => console.log(dailyWorkHoursList)}
        >
          每日員工工數列表 {date}
        </div>
        {isEdit ? (
          <div>
            <button
              className="mr-3 text-3xl btn btn-outline btn-secondary"
              onClick={() => {
                handleSetDayOff();
              }}
            >
              設為休假日
            </button>
            <button
              className="ml-3 text-3xl btn btn-outline"
              onClick={() => {
                handleSaveChange();
              }}
            >
              完成
            </button>
          </div>
        ) : (
          <button
            className="text-3xl btn btn-outline"
            onClick={() => {
              setIsEdit(!isEdit);
            }}
          >
            編輯
          </button>
        )}
      </div>

      <div className="overflow-x-auto flex justify-center mt-5">
        <table className="table ml-36 mr-36">
          <thead className="text-center">
            <tr className=" text-[#91949D] font-medium text-3xl pb-6 border-b border-[#91949D] border-solid">
              {headerList.map((header, index) => (
                <th key={index}>{header.name}</th>
              ))}
            </tr>
          </thead>
          <tbody className="text-center">
            {
              // return <tr>...</tr> for each employee
              dailyWorkHoursList.map((employee) => {
                if (employee.workHours.length === 0) {
                  employee.workHours = [genEmptyWorkHour()];
                }
                return employee.workHours.map((workhour, index) => {
                  const isFirstElement = index === 0;
                  const isLastElement = index === employee.workHours.length - 1;
                  const isDayOff = employee.dayOff;
                  const hasBorder = isLastElement ? "" : "border-none";
                  const workHoursOpt = isDayOff
                    ? workHoursOptions[0]
                    : workHoursOptions.find(
                        (opt) => opt.hours === workhour.hours
                      );
                  const constructionOpt = constructionList.find(
                    (opt) => opt.id === workhour.constructionWorkID
                  );

                  return (
                    <tr className={`text-2xl border-[#91949D] ${hasBorder}`}>
                      {headerList.map((header) => {
                        if (header.key === "totalHour" && isFirstElement) {
                          return (
                            <td>
                              <div className="border border-[#235793] bg-[#cbdff2] rounded-lg ">
                                <span className="font-normal text-[#235793]">
                                  總計 {employee.totalWorkHours} 小時
                                </span>
                              </div>
                            </td>
                          );
                        }

                        if (header.key === "name" && isFirstElement) {
                          return (
                            <td>
                              <span className="font-normal">
                                {employee.userName}
                              </span>
                            </td>
                          );
                        }

                        if (header.key === "add" && isFirstElement && isEdit) {
                          return (
                            <td>
                              <RiAddCircleLine
                                onClick={() =>
                                  handleAddWorkHour(employee.userID)
                                }
                              />
                            </td>
                          );
                        }

                        if (header.key === "hour") {
                          return (
                            <td>
                              {isEdit ? (
                                <select
                                  className="text-center"
                                  value={
                                    workHoursOpt ? workHoursOpt.hours : "?"
                                  }
                                  onChange={(event) =>
                                    handleHoursChange(
                                      event,
                                      employee.userID,
                                      workhour.id
                                    )
                                  }
                                >
                                  {workHoursOptions.map((opt) => (
                                    <option key={opt.hours} value={opt.hours}>
                                      {opt.name}
                                    </option>
                                  ))}
                                </select>
                              ) : (
                                <span className="mr-5 ml-5 font-normal">
                                  {workHoursOpt ? workHoursOpt.name : "?"}
                                </span>
                              )}
                            </td>
                          );
                        }

                        if (header.key === "construction") {
                          return (
                            <td>
                              {isEdit ? (
                                <select
                                  className="text-center"
                                  value={
                                    constructionOpt ? constructionOpt.id : "-"
                                  }
                                  onChange={(event) =>
                                    handleConstructionChange(
                                      event,
                                      employee.userID,
                                      workhour.id
                                    )
                                  }
                                >
                                  {constructionList.map((construction) => (
                                    <option
                                      key={construction.id}
                                      value={construction.id}
                                    >
                                      {construction.name}
                                    </option>
                                  ))}
                                </select>
                              ) : (
                                <span className="mr-5 ml-5 font-normal">
                                  {constructionOpt ? constructionOpt.name : "-"}
                                </span>
                              )}
                            </td>
                          );
                        }

                        if (header.key === "delete" && isEdit) {
                          return (
                            // eslint-disable-next-line react/jsx-key
                            <td>
                              <RiDeleteBinLine
                                onClick={() =>
                                  handleDeleteWorkHour(
                                    employee.userID,
                                    workhour.id
                                  )
                                }
                              />
                            </td>
                          );
                        }

                        // eslint-disable-next-line react/jsx-key
                        return <td>{""}</td>;
                      })}
                    </tr>
                  );
                });
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WorkDailyInfo;
