import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../components/Loading";
import StatisticMonthCost from "../components/StatisticMonthCost";
import http from "../services/http";

const StatisticSupplierOverview = () => {
  const [supplierOverview, setSupplierOverview] = useState([]);
  const [supplierInfo, setSupplierInfo] = useState({});
  const [isLoading, setLoading] = useState(false);

  const { id } = useParams();

  const fetchSupplierInfo = async (id) => {
    setLoading(true);
    try {
      const response = await http.get(`auth/supplier/info?supplierID=${id}`);
      if (response) {
        setSupplierInfo(response);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchSupplierOverview = async (year, id) => {
    setLoading(true);
    try {
      const response = await http.get(
        `auth/statistic/supplier/overview?year=${year}&supplierID=${id}`
      );
      if (response) {
        setSupplierOverview(response);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchSupplierInfo(id);
    const currentYear = new Date().getFullYear();
    fetchSupplierOverview(currentYear, id);
  }, []);

  return (
    <div className="w-full">
      {isLoading && <Loading />}
      <div className="mx-10 mt-10 text-5xl font-bold">
        各廠商支出統計-{supplierInfo.name}
      </div>
      <div className="flex flex-col items-center">
        <StatisticMonthCost
          monthCostList={supplierOverview}
          handleYearOnChange={(year) => {
            fetchSupplierOverview(year, id);
          }}
        />
      </div>
    </div>
  );
};

export default StatisticSupplierOverview;
