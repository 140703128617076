/* eslint-disable react/prop-types */
import React, { useState } from "react";

const Pagination = ({ currentPage, totalPage, clickPage }) => {
  return (
    <div className="join mt-5 mr-10 flex flex-wrap justify-end">
      {[...Array(totalPage)].map((_, index) => (
        <input
          key={index}
          className="join-item btn btn-md"
          type="radio"
          aria-label={index + 1}
          checked={index + 1 === currentPage}
          onClick={() => {
            clickPage(index + 1);
          }}
        />
      ))}
    </div>
  );
};

export default Pagination;
