export const GenerateRandomNumber = (length) =>{
    let numberStr = '';

  for (let i = 0; i < length; i++) {
    const digit = Math.floor(Math.random() * 10);
    numberStr += digit;
  }

  const randomNumber = parseInt(numberStr, 10);

  return randomNumber;
};


export const getYearMonth = (dateStr) => {
  console.log(dateStr);
  const dateParts = dateStr.split("-");
  const year = dateParts[0];
  const month = dateParts[1];
  console.log(year, month);
  return [year, month];
};