/* eslint-disable react/prop-types */
import React from "react";

const ProcurementInvoiceEditHeader = ({
  procurementID,
  invoiceID,
  cancelHandler,
  upsertHandler,
  isEditMode,
}) => {
  const upsertTitleCopy = isEditMode ? "編輯" : "新增";
  const upsertButtonCopy = isEditMode ? "儲存" : "建立";
  const invoiceIDStr = isEditMode ? "#" + invoiceID : "";
  return (
    <div className="mx-10 mt-10 flex items-end justify-between">
      <div className="text-4xl font-bold">
        {upsertTitleCopy}到貨單{invoiceIDStr} - 採購單#{procurementID}
      </div>
      <div className="flex">
        <button className="text-2xl text-btn-blue" onClick={cancelHandler}>
          取消
        </button>
        <div className="text-2xl text-divider-gray">｜</div>
        <button className="text-2xl text-btn-blue" onClick={upsertHandler}>
          {upsertButtonCopy}
        </button>
      </div>
    </div>
  );
};

export default ProcurementInvoiceEditHeader;
