import React from "react";
import StatisticNavBtn from "../components/StatisticNavBtn";

const statisticConfig = [
  {
    title: "各工程案收支統計",
    href: "/statistic/constructionWork",
  },
  {
    title: "各廠商支出統計",
    href: "/statistic/supplier",
  },
  {
    title: "年度綜合收支統計",
    href: `/statistic/comprehensive`,
  },
];

const Statistic = () => {
  return (
    <div className="w-full">
      <div className="mx-10 mt-10 text-5xl font-bold">收支統計</div>
      <div className="flex">
        {statisticConfig.map((item) => (
          <StatisticNavBtn
            key={item.href}
            content={item.title}
            href={item.href}
          />
        ))}
      </div>
    </div>
  );
};

export default Statistic;
