import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import Logo from "../components/Logo";
import http from "../services/http";

const Login = () => {
  const navigate = useNavigate(); // eslint-disable-line
  const [isLoading, setLoading] = useState(false);
  const handleClick = async () => {
    setLoading(true);
    try {
      const response = await http.get("line/loginURL");
      if (response) {
        window.location.replace(response.uri);
      }
    } catch (error) {
      console.log("error");
    }
  };
  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      {isLoading && <Loading />}
      <Logo isNav={false} />
      <button
        className="mt-8 bg-[#00C300] text-xl px-9 py-3 text-white font-black rounded-3xl"
        onClick={handleClick}
        type="button"
      >
        使用 LINE 帳號登入
      </button>
    </div>
  );
};

export default Login;
