/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";

function NumericInput({ value, placeholder, onChange }) {
  const [inputValue, setInputValue] = useState("");
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleInputChange = (event) => {
    let value = event.target.value;
    if (value === "") {
      value = 0;
    }
    //檢查輸入是否為數字
    const isValidInput = /^[+-]?\d+(\.\d+)?$/.test(value);
    setInputValue(value);
    setIsValid(isValidInput);
    onChange(event);
  };

  const errorClass = isValid ? "" : "input-error";

  return (
    <div className="w-full max-w-xs">
      <input
        type="text"
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
        className={`input input-bordered w-full max-w-xs text-2xl ${errorClass}`}
      />
      {isValid ? null : (
        <div className="text-red-500 text-sm">* 請輸入數字</div>
      )}
    </div>
  );
}

export default NumericInput;
