/* eslint-disable react/prop-types */
import React, { useState } from "react";

const Header = ({ title, addnew, search, exports }) => {
  const [word, setWord] = useState("");
  const handleChange = (event) => {
    const value = event.target.value;
    setWord(value);
    search(value);
  };
  return (
    <div className="mx-10 mt-10 flex justify-between">
      <div className="text-5xl font-bold">{title}</div>
      <div className="flex">
        <div className="relative flex items-center mr-8">
          <input
            type="text"
            placeholder="搜尋"
            className=" w-[285px] h-[65px] border border-[#000000] border-solid bg-[#FFFFFF] py-2 px-12 rounded-xl outline-none text-2xl"
            value={word}
            onChange={handleChange}
          />
          <i className="absolute ml-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33"
              height="34"
              viewBox="0 0 33 34"
              fill="none"
            >
              <path
                d="M15.0692 26.5789C20.8989 26.5789 25.6248 21.6776 25.6248 15.6316C25.6248 9.58551 20.8989 4.6842 15.0692 4.6842C9.23955 4.6842 4.51367 9.58551 4.51367 15.6316C4.51367 21.6776 9.23955 26.5789 15.0692 26.5789Z"
                stroke="#000000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M28.264 29.3158L22.5244 23.3632"
                stroke="#000000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </i>
        </div>

        <button
          className="btn btn-lg btn-outline btn-success px-5 py-3 text-2xl flex items-center mr-4"
          onClick={exports}
        >
          <span className="ml-1">匯出...</span>
        </button>

        <button
          className="btn btn-lg btn-outline btn-info px-5 py-3 text-2xl flex items-center"
          onClick={addnew}
        >
          <span className="ml-1">新增 +</span>
        </button>
      </div>
    </div>
  );
};

export default Header;
