/* eslint-disable react/prop-types */
import { Link, useNavigate, useLocation } from "react-router-dom";
import Logo from "./Logo";
import React, { useEffect, useState } from "react";

const navConfig = [
  {
    item: "採購單",
    href: "/procurement?page=1",
    pageName: "procurement_page",
  },
  {
    item: "員工請款單",
    href: "/employeeInvoice",
    pageName: "employee_invoice_page",
  },
  {
    item: "員工工數表",
    href: "/employeeWorkDaily",
    pageName: "daliy_work_hours_page",
  },
  {
    item: "工程案列表",
    href: "/constructions",
    pageName: "construction_work_page",
  },
  {
    item: "廠商列表",
    href: "/supplier",
    pageName: "supplier_page",
  },
  {
    item: "應付帳款",
    href: "/account-payable",
    pageName: "payment_page",
  },
  {
    item: "收支統計",
    href: "/statistic",
    pageName: "statistics_page",
  },
];
const Nav = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [current, setCurrent] = useState("/");
  const lineUrl = localStorage.getItem("lineUrl");
  const displayName = localStorage.getItem("displayName");
  const permissions = JSON.parse(localStorage.getItem("permissions"));

  useEffect(() => {
    console.log(location.pathname);
    console.log(location.pathname.split("/")[1]);
    setCurrent(location.pathname);
  }, [location]);

  const isCurrentPath = (href) => {
    const rootPathName = current.split("/")[1];
    if (rootPathName.length === 0) {
      return false;
    } else {
      return href.includes(rootPathName);
    }
  };

  const list = (navInfo, permissions) => {
    let filteredNavInfo = [];
    if (permissions) {
      filteredNavInfo = navInfo.filter((nav) =>
        permissions.includes(nav.pageName)
      );
    }

    return filteredNavInfo.map((nav, key) => (
      <li key={key} className="text-black font-bold text-2xl ">
        <Link
          to={nav.href}
          className={`active:!bg-transparent !text-black hover:!bg-transparent focus:!bg-transparent ${
            isCurrentPath(nav.href) ? "underline underline-offset-[10px]" : ""
          }`}
          onClick={() => {
            setCurrent(nav.href);
          }}
        >
          {nav.item}
        </Link>
      </li>
    ));
  };

  const navInfo = list(navConfig, permissions);

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <div className="navbar bg-base-100 justify-between pt-6 px-4 ">
      <div id="nav-start">
        <div className="dropdown">
          <label tabIndex={0} className="btn btn-ghost xl:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </label>
          <ul
            tabIndex={0}
            className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-[#F2C97A] rounded-box w-52"
          >
            {navInfo}
          </ul>
        </div>
        <Logo className="items-end hidden xl:flex" />
      </div>

      <div className=" flex xl:hidden">
        <Logo className="flex items-end" />
      </div>

      <div id="nav-end" className="justify-end">
        <div className="bg-[#F8F0E1] rounded-2xl mr-3 hidden  xl:flex">
          <ul className="menu menu-horizontal px-1">{navInfo}</ul>
        </div>
        <div className="dropdown dropdown-end items-center">
          <label
            tabIndex={0}
            className="btn btn-ghost btn-circle avatar h-16 w-16"
          >
            <div className="w-24 rounded-full">
              <img src={lineUrl ? lineUrl : "/assets/avatar.png"} />
            </div>
          </label>
          <ul
            tabIndex={0}
            className="mt-3 z-[1] p-2 shadow menu menu-sm dropdown-content bg-[#F2C97A] rounded-box w-50 items-center"
          >
            <span className="text-xl ">
              {displayName ? displayName : "???"}
            </span>
            <li className="text-black font-bold">
              <span className="text-xl " onClick={logout}>
                {" "}
                登出{" "}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Nav;
