/* eslint-disable react/prop-types */
import React from "react";

const ProcurementDetailHeader = ({ title, cancel, upsert, isEdit }) => {
  const upsertButtonCopy = isEdit ? "儲存" : "建立";
  return (
    <div className="mx-10 mt-10 flex justify-between">
      <div className="text-5xl font-bold">{title}</div>
      <div className="flex text-white">
        <button className="btn btn-lg mr-4" onClick={cancel}>
          取消
        </button>
        <button className="btn btn-lg btn-success mr-10" onClick={upsert}>
          {upsertButtonCopy}
        </button>
      </div>
    </div>
  );
};

export default ProcurementDetailHeader;
