import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import _ from "lodash";

const PublicRoute = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const auth_token = localStorage.getItem("auth_token");
  const initLogin = JSON.parse(localStorage.getItem("initLogin"));
  const redicrectPath = auth_token ? (initLogin ? "/register" : "/") : "/login";

  return _.isEqual(redicrectPath, currentPath) ? (
    <Outlet />
  ) : (
    <Navigate to={redicrectPath} />
  );
};

export default PublicRoute;
