import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../components/Loading";
import StatisticNavBtn from "../components/StatisticNavBtn";
import http from "../services/http";

const StatisticComprehensiveConstruction = () => {
  const [constructionList, setConstructionList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const { year, month } = useParams();

  useEffect(() => {
    const fetchComprehensiveConstruction = async (year, month) => {
      setLoading(true);
      try {
        const response = await http.get(
          `auth/statistic/comprehensive/constructionWork?year=${year}&month=${month}`
        );
        if (response) {
          setConstructionList(response);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    fetchComprehensiveConstruction(year, month);
  }, []);

  return (
    <div className="w-full">
      {isLoading && <Loading />}
      <div className="mx-10 mt-10 text-5xl font-bold">{`${year} 年度綜合統計-${month}月份支出`}</div>
      {constructionList.map((c) => (
        <StatisticNavBtn
          key={c.ConstructionWorkID}
          content={
            <div className="flex flex-col">
              <span className="font-bold text-3xl">
                {c.ConstructionWorkName}
              </span>
              <span className="text-2xl">共支出</span>
              <span className="text-2xl">{`${c.TotalCost?.toLocaleString()}元`}</span>
            </div>
          }
          href={`/statistic/constructionWork/${c.ConstructionWorkID}/${year}/${month}`}
        />
      ))}
    </div>
  );
};

export default StatisticComprehensiveConstruction;
