/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useEffect } from "react";

import Dialog from "../components/Dialog";
import ExportProcurement from "./ExportProcurement";

const ProcurementDetailHeader = ({
  procurementID,
  approve,
  disgard,
  edit,
  changeEditable,
  canApprove = false,
  canChangeEditable = false,
  approveStatus = "",
  isEditable = false,
}) => {
  const [isOpen, setModalOpen] = useState(false);
  const [isEditableState, setIsEditableState] = useState(isEditable);
  useEffect(() => {
    setIsEditableState(isEditable);
  }, [isEditable]);
  const closeDialog = () => {
    setModalOpen(false);
  };
  const title = `採購單 - 詳細資訊 #${procurementID}`;
  return (
    <div className="mx-20 mt-10 flex justify-between items-center">
      <div className="text-4xl font-bold">{title}</div>
      <div className="flex items-center">
        {canChangeEditable ? (
          <div>
            {isEditableState ? (
              <div className="flex items-center">
                <button
                  className="text-2xl text-btn-blue"
                  onClick={() => {
                    changeEditable(false);
                    setIsEditableState(false);
                  }}
                >
                  鎖定編輯
                </button>
                <div className="text-2xl text-divider-gray">｜</div>
              </div>
            ) : (
              <div className="flex items-center">
                <button
                  className="text-2xl text-btn-blue"
                  onClick={() => {
                    changeEditable(true);
                    setIsEditableState(true);
                  }}
                >
                  開啟編輯
                </button>
                <div className="text-2xl text-divider-gray">｜</div>
              </div>
            )}
          </div>
        ) : null}

        {canApprove && approveStatus === "waiting" ? (
          <div className="flex items-center">
            <button className="text-2xl text-btn-blue" onClick={approve}>
              核准
            </button>
            <div className="text-2xl text-divider-gray">｜</div>
          </div>
        ) : null}

        {approveStatus === "waiting" ? (
          <div className="flex items-center">
            <button
              className="text-2xl text-btn-blue"
              onClick={() => {
                setModalOpen(true);
              }}
            >
              作廢
            </button>
            <div className="text-2xl text-divider-gray">｜</div>
          </div>
        ) : null}

        <ExportProcurement procurementID={procurementID} />

        <div className="text-2xl text-divider-gray">｜</div>
        {isEditableState ? (
          <button className="text-2xl text-btn-blue" onClick={edit}>
            編輯
          </button>
        ) : (
          <div className="text-2xl text-divider-gray">編輯</div>
        )}
      </div>
      <Dialog isOpen={isOpen} cancel={() => closeDialog()}>
        <div className="flex flex-col items-center">
          <div className="text-2xl m-4">確定作廢此採購單？</div>
          <div className="flex">
            <button className="btn btn-lg m-4" onClick={() => closeDialog()}>
              取消
            </button>
            <button className="btn btn-lg btn-error m-4" onClick={disgard}>
              作廢
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ProcurementDetailHeader;
