import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Http from "../services/http";

import Header from "../components/InvoiceDetailHeader";
import ErrorNotification from "../components/ErrorNotification";

import { statusLookup } from "../constants/InvoiceConstants";
import Loading from "../components/Loading";

const InvoiceDetail = () => {
  const navigate = useNavigate();
  const [invoiceData, setInvoiceData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    const fetchInvoiceData = async () => {
      setLoading(true);
      try {
        const response = await Http.get(
          `auth/employeeInvoice/info?employeeInvoiceID=${id}`
        );

        setInvoiceData(response);
      } catch (error) {
        setErrorMessage("獲取請款單資料錯誤，請稍後再試");
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };

    fetchInvoiceData();
  }, []);

  const handleReceive = useCallback(async () => {
    // TODO: test with manager account
    setLoading(true);
    try {
      await Http.post(`auth/employeeInvoice/receive`, { id: Number(id) });
      navigate(`/employeeInvoice`);
    } catch (error) {
      setErrorMessage("批准請款單錯誤，請稍後再試");
      console.error("Error for approving:", error);
    }
    setLoading(false);
  }, [navigate]);
  const handleDiscard = useCallback(async () => {
    // TODO: test with manager account
    setLoading(true);
    try {
      await Http.delete(`auth/employeeInvoice/${id}`);
      navigate(`/employeeInvoice`);
    } catch (error) {
      setErrorMessage("刪除請款單錯誤，請稍後再試");
      console.error("Error for approving:", error);
    }
    setLoading(false);
  }, [navigate]);

  const handleEdit = useCallback(async () => {
    navigate(`/employeeInvoice/edit/${id}`);
  }, [navigate]);

  const InvoiceDetailTemplate = useMemo(() => {
    return invoiceData?.employeeInvoiceDetail?.map((detail) => (
      <div className="grid grid-cols-5 p-3" key={detail.id}>
        <div className="p-3">{detail.content}</div>
        <div className="p-3">{detail.quantity?.toLocaleString()}</div>
        <div className="p-3">{detail.unitPrice?.toLocaleString()}</div>
        <div className="p-3">{detail.totalAmount?.toLocaleString()}</div>
        <div className="p-3">{detail.remark}</div>
      </div>
    ));
  }, [invoiceData]);

  const isOwner = () => {
    const userID = localStorage.getItem("userID");
    return userID === invoiceData.applyUserID;
  };

  return (
    <div className="w-full text-header">
      {isLoading && <Loading />}
      <Header
        employeeInvoiceID={id}
        receive={handleReceive}
        disgard={handleDiscard}
        edit={handleEdit}
        isOwner={isOwner()}
        isWaiting={invoiceData.status === "waiting"}
      />
      {errorMessage ? <ErrorNotification message={errorMessage} /> : null}
      <div className="border border-gray-400 p-3 mx-20 my-10">
        <div className="grid grid-rows-3">
          <div className="grid grid-cols-4 p-3">
            <div className="font-bold p-3">工程</div>
            <div className="p-3">{invoiceData.constructionWorkName}</div>
            <div className="font-bold p-3">請款狀況</div>
            <div className="p-3">{statusLookup[invoiceData.status]}</div>
          </div>
          <div className="grid grid-cols-4 p-3">
            <div className="font-bold p-3">請款日期</div>
            <div className="p-3">{invoiceData.createTime}</div>
            <div className="font-bold p-3">金額</div>
            <div className="p-3">
              {invoiceData.totalAmount?.toLocaleString()}
            </div>
          </div>
          <div className="grid grid-cols-4 p-3">
            <div className="font-bold p-3">請款人</div>
            <div className="p-3">{invoiceData.applyUserName}</div>
            <div className="font-bold p-3">簽收時間</div>
            <div className="p-3">{invoiceData.receiveTime}</div>
          </div>
        </div>
        <div className="divider"></div>
        <div className="grid grid-rows-2">
          <div className="grid grid-cols-5 p-3">
            <div className="font-bold p-3">請款內容</div>
            <div className="font-bold p-3">數量</div>
            <div className="font-bold p-3">單價</div>
            <div className="font-bold p-3">金額</div>
            <div className="font-bold p-3">備註</div>
          </div>
          {InvoiceDetailTemplate}
        </div>
        <div className="divider"></div>
        <div className="grid grid-cols-5 p-3">
          <div></div>
          <div></div>
          <div></div>
          <div className="p-3">總額</div>
          <div className="p-3">{invoiceData.totalAmount?.toLocaleString()}</div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetail;
