/* eslint-disable react/prop-types */
import React from "react";

const OtherExpenditureEditHeader = ({ title, upsert, cancel, isEdit }) => {
  const upsertButtonCopy = isEdit ? "儲存" : "新增";

  return (
    <div className="mx-20 mt-10 flex justify-between">
      <div className="flex text-center">
        <div className="text-5xl font-bold relative top-2">{title}</div>
      </div>
      <div className="flex">
        <button className="btn btn-lg mr-4" onClick={cancel}>
          取消
        </button>
        <button className="btn btn-lg btn-success" onClick={upsert}>
          {upsertButtonCopy}
        </button>
      </div>
    </div>
  );
};

export default OtherExpenditureEditHeader;
