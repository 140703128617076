/* eslint-disable react/prop-types */
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

/* 
  header必須要有 key, name兩個欄位
  body會自動從list中找到對應key的資料，找不到則顯示空字串
  顯示順序按照header順序
*/

const InfinityTable = ({
  headerList,
  bodyList,
  viewDetail,
  dataLength,
  next,
  hasMore,
}) => {
  const headerInfo = headerList.map((header, index) => (
    <td key={index}>{header.name}</td>
  ));

  const listInfo = bodyList.map((row) => (
    <tr
      key={row.id}
      onClick={() => viewDetail(row.id)}
      className="text-lg border-b border-[#91949D] border-solid hover:bg-[#C4E1FF] odd:bg-[#ECF5FF]"
    >
      {headerList.map((header, index) => {
        const data = row[header.key];

        if (data) {
          return <td key={index}>{data}</td>;
        } else {
          return <td key={index}>{""}</td>;
        }
      })}
    </tr>
  ));

  return (
    <div className="overflow-x-auto mt-10">
      <InfiniteScroll
        dataLength={dataLength}
        next={next}
        hasMore={hasMore} // 是否還有更多資料需要加載
        loader={<h4>Loading...</h4>}
      >
        <table className="table">
          <thead className="text-center">
            <tr className=" text-[#91949D] font-medium text-xl pb-6 border-b border-[#91949D] border-solid">
              {headerInfo}
            </tr>
          </thead>
          <tbody className="text-center">{listInfo}</tbody>
        </table>
      </InfiniteScroll>
    </div>
  );
};

export default InfinityTable;
