import axios from "axios";

const Http = axios.create({
  timeout: 10000,
  baseURL: process.env.REACT_APP_BASE_URL,
});
Http.interceptors.request.use(
  (config) => {
    const auth_token = localStorage.getItem("auth_token");
    if (auth_token) {
      config.headers["Authorization"] = `Bearer ${auth_token}`;
    }
    config.headers["Content-Type"] = "application/json";
    console.log("request", config);
    return config;
  },
  (error) => {
    console.log("error", error);
    // Do something with request error
    return Promise.reject(error);
  }
);

Http.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return response.data;
    }
    return response;
  },
  (error) => {
    console.log("response", error);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);
export default Http;