/* eslint-disable react/prop-types */
import React from "react";
import { generatePeriodOptions } from "../utils/periodUtils";

const periodOptions = generatePeriodOptions();

const PeriodSelector = ({ selectedPeriodValue, handlePeriodChanged }) => {
  return (
    <select
      className="select select-bordered ml-4"
      value={selectedPeriodValue}
      onChange={handlePeriodChanged}
    >
      <option value="" disabled>
        請選擇月份
      </option>
      {periodOptions.map((option) => (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      ))}
    </select>
  );
};

export default PeriodSelector;
