export const header = [
  {
    name: "編號",
    key: "payeeID",
  },
  {
    name: "日期",
    key: "createDate",
  },
  {
    name: "付款對象",
    key: "payeeName",
  },
  {
    name: "總計金額",
    key: "totalAmount",
  },
  {
    name: "付款狀態",
    key: "status",
  },
  {
    name: "類型",
    key: "typeCopy",
  },
];

export const detailProcurementInvoiceTitle = [
  {
    name: "到貨日期",
    key: "invoiceDate",
  },
  {
    name: "到貨單編號",
    key: "procurementAndInvoiceID",
  },
  {
    name: "工程",
    key: "constructionWorkName",
  },
  {
    name: "金額(含稅)",
    key: "totalAmount",
  },
  {
    name: "付款方式",
    key: "paymentMethod",
  },
  {
    name: "付款號碼",
    key: "paymentNumber",
  },
  {
    name: "發票號碼",
    key: "receiptNumber",
  },
  {
    name: "付款狀態",
    key: "paymentStatus",
  },
];

export const detailEmployInvoiceTitle = [
  {
    name: "日期",
    key: "createDate",
  },
  {
    name: "請款單編號",
    key: "employeeInvoiceID",
  },
  {
    name: "工程",
    key: "constructionWorkName",
  },
  {
    name: "金額",
    key: "totalAmount",
  },
  {
    name: "請款狀態",
    key: "employeeInvoiceStatus",
  },
  {
    name: "付款方式",
    key: "paymentMethod",
  },
  {
    name: "付款狀態",
    key: "paymentStatus",
  },
];

export const paidStatusLookup = {
  pending: "待付款",
  paid: "已完成付款",
};

export const employeeInvoiceStatusLookup = {
  waiting: "待簽收",
  receive: "已簽收",
  delete: "已刪除",
};

export const typeLookup = {
  procurement: "採購",
  procurementInvoice: "採購",
  employeeInvoice: "請款",
  otherExpenditure: "其他"
};

export const paymentMethodLookup = {
  "": "-",
  cash: "現金",
  check: "支票",
  credit: "信用狀"
};

export const paymentMethodOptions = Object.keys(paymentMethodLookup).map((key) => ({
  id: key,
  name: paymentMethodLookup[key],
}));

export const paidStatusOptions = Object.keys(paidStatusLookup).map((key) => ({
  id: key,
  name: paidStatusLookup[key],
}));