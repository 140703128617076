import React, { useEffect, useState } from "react";
import http from "../services/http";
import StatisticNavBtn from "../components/StatisticNavBtn";
import Loading from "../components/Loading";

const StatisticConstructionList = () => {
  const [constructionList, setConstructionList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const fetchConstructionList = async () => {
      setLoading(true);
      try {
        const response = await http.get("auth/constructionWork/list");
        if (response) {
          setConstructionList(response.constructionWorkList);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    fetchConstructionList();
  }, []);

  return (
    <div className="w-full">
      {isLoading && <Loading />}
      <div className="mx-10 mt-10 text-5xl font-bold">各工程案收支統計</div>
      <div className="flex flex-wrap">
        {constructionList.map((c) => (
          <StatisticNavBtn
            key={c.id}
            content={c.name}
            href={`/statistic/constructionWork/${c.id}`}
          />
        ))}
      </div>
    </div>
  );
};

export default StatisticConstructionList;
