/* eslint-disable react/prop-types */
import React from "react";
import {
  purchaseStatusLookup,
  approveStatusLookup,
  editableStatusLookup,
} from "../constants/ProcurementConstants";

const ProcurementStatus = ({ status }) => {
  if (status === "not_yet_purchase") {
    return (
      <div className="text-yellow-word bg-yellow-bg-38 border border-yellow-border rounded w-[136px] h-[35px] flex items-center justify-center mr-2">
        {purchaseStatusLookup[status]}
      </div>
    );
  } else if (status === "purchased") {
    return (
      <div className="text-green-word bg-green-bg-38 border border-green-border rounded w-[136px] h-[35px] flex items-center justify-center mr-2">
        {purchaseStatusLookup[status]}
      </div>
    );
  } else if (status === "arrived") {
    return (
      <div className="text-green-word bg-green-bg-38 border border-green-border rounded w-[136px] h-[35px] flex items-center justify-center mr-2">
        {purchaseStatusLookup[status]}
      </div>
    );
  } else if (status === "waiting") {
    return (
      <div className="text-yellow-word bg-yellow-bg-38 border border-yellow-border rounded w-[136px] h-[35px] flex items-center justify-center mr-2">
        {approveStatusLookup[status]}
      </div>
    );
  } else if (status === "approve") {
    return (
      <div className="text-green-word bg-green-bg-38 border border-green-border rounded w-[136px] h-[35px] flex items-center justify-center mr-2">
        <div>{approveStatusLookup[status]}</div>
      </div>
    );
  } else if (status === "reject") {
    return (
      <div className="text-red-word bg-red-bg-38 border border-red-border rounded w-[136px] h-[35px] flex items-center justify-center mr-2">
        {approveStatusLookup[status]}
      </div>
    );
  } else if (status === "delete") {
    return (
      <div className="text-red-word bg-red-bg-38 border border-red-border rounded w-[136px] h-[35px] flex items-center justify-center mr-2">
        {approveStatusLookup[status]}
      </div>
    );
  } else if (status === true) {
    return (
      <div className="text-blue-word bg-blue-bg-38 border border-blue-border rounded w-[136px] h-[35px] flex items-center justify-center mr-2">
        {editableStatusLookup[status]}
      </div>
    );
  } else if (status === false) {
    return (
      <div className="text-gray-word bg-gray-bg-38 border border-gray-border rounded w-[136px] h-[35px] flex items-center justify-center mr-2">
        {editableStatusLookup[status]}
      </div>
    );
  }
  return <div>{status}</div>;
};

export default ProcurementStatus;
