import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Http from "../services/http";

import { extractPeriodID } from "../utils/periodUtils";

import AccountsPayableHeader from "../components/AccountsPayableHeader";
import Table from "../components/Table";

import {
  header,
  typeLookup,
  paidStatusLookup,
} from "../constants/AccountsPayableConstants";
import Loading from "../components/Loading";

const AccountsPayable = () => {
  const navigate = useNavigate();
  const { year, month } = useParams();

  const [accountsPaybleList, setAccountsPaybleList] = useState([]);
  const [selectedPeriodValue, setSelectedPeriodValue] = useState("");
  const [isLoading, setLoading] = useState(false);

  const fetchData = async (year, month) => {
    setLoading(true);
    try {
      const response = await Http.get("auth/accountsPayable/list", {
        params: { year, month },
      });

      const sortData = response.sort((a, b) => {
        const typeOrder = {
          procurement: 1,
          employeeInvoice: 2,
          otherExpenditure: 3,
        };

        const typeA = typeOrder[a.type];
        const typeB = typeOrder[b.type];

        if (typeA < typeB) {
          return -1;
        }
        if (typeA > typeB) {
          return 1;
        }

        const numericA = parseFloat(a.payeeID);
        const numericB = parseFloat(b.payeeID);

        if (!isNaN(numericA) && !isNaN(numericB)) {
          return numericA - numericB;
        } else {
          return a.payeeID.localeCompare(b.payeeID);
        }
      });

      const formatedList = sortData.map((item) => ({
        ...item,
        id: item.payeeID,
        createDate: `${year - 1911}/${month}`,
        status: paidStatusLookup[item.status],
        typeCopy: typeLookup[item.type],
        totalAmount: "$" + item.totalAmount?.toLocaleString(),
      }));

      setAccountsPaybleList(formatedList);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setSelectedPeriodValue(`${year}-${month}`);
    fetchData(year, month);
  }, [year, month]);

  const handleAddNew = () => {
    navigate(`/account-payable/detail/otherExpenditure/edit`);
  };

  const handleViewDetail = (payeeID) => {
    const { type } = accountsPaybleList.find(
      (data) => data.payeeID === payeeID
    );
    const { year, month } = extractPeriodID(selectedPeriodValue);

    if (type === "procurementInvoice" || type === "employeeInvoice") {
      navigate(`/account-payable/detail/${type}/${payeeID}/${year}/${month}`);
    } else if (type === "otherExpenditure") {
      navigate(`/account-payable/detail/${type}/${payeeID}`);
    }
  };

  const handlePeriodChanged = (event) => {
    const { year, month } = extractPeriodID(event.target.value);

    console.log("call year, month", year, month);
    navigate(`/account-payable/${year}/${month}`);
  };

  return (
    <div className="w-full">
      {isLoading && <Loading />}
      <AccountsPayableHeader
        title="應付帳款"
        addnew={handleAddNew}
        selectedPeriodValue={selectedPeriodValue}
        handlePeriodChanged={handlePeriodChanged}
      />
      <Table
        headerList={header}
        bodyList={accountsPaybleList}
        viewDetail={handleViewDetail}
      />
    </div>
  );
};

export default AccountsPayable;
