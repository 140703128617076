import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import zhCN from "date-fns/locale/zh-CN"; // 引入中文語言包
import moment from "moment";

import Http from "../services/http";

import Header from "../components/OtherExpenditureEditHeader";
import ErrorNotification from "../components/ErrorNotification";
import { getYearMonth } from "../utils/tool";

import {
  paymentMethodOptions,
  paidStatusOptions,
} from "../constants/AccountsPayableConstants";
import NumericInput from "../components/NumericInput";
import Loading from "../components/Loading";
import SearchSelector from "../components/SearchSelector";

registerLocale("zh-CN", zhCN); // 註冊中文語言包

const initialExpenditureObject = {
  constructionWorkIDList: [],
  content: "",
  expenditureDate: "",
  payeeName: "",
  paymentMethod: "",
  paymentNumber: "",
  paymentStatus: "",
  quantity: 0,
  receiptNumber: "",
  remark: "",
  supplierID: "",
  unitPrice: 0,
};

const OtherExpenditure = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const isEdit = Boolean(id);

  const [expenditureData, setExpenditureData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [isSelector, setIsSelector] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [constructionRadioData, setConstructionRadioData] = useState({});
  const [constructionRadioList, setConstructionRadioList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const title = `${isEdit ? "編輯" : "新增"}支出`;
  const userName = localStorage.getItem("displayName");

  const fetchConstructionList = async (expenditureDate, construcntionList) => {
    setLoading(true);
    try {
      const response = await Http.get(
        `auth/accountsPayable/otherExpenditure/constructionWorkList?expenditureDate=${expenditureDate}`
      );

      setConstructionRadioList(response);
      setConstructionRadioData(
        response.reduce((previousData, currentWork) => {
          return {
            ...previousData,
            [currentWork.id]: construcntionList
              .map((data) => data.id)
              .includes(currentWork.id),
          };
        }, {})
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await Http.get(
          `auth/accountsPayable/otherExpenditure/${id}`
        );

        if (response.supplierID) {
          setIsSelector(true);
          setExpenditureData({
            ...response,
            payeeName: "",
          });
        } else {
          setExpenditureData(response);
        }

        fetchConstructionList(
          response.expenditureDate,
          response.constructionWorkAllocateList
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };

    const fetchSupplierOptions = async () => {
      setLoading(true);
      try {
        const response = await Http.get(`auth/supplier/list`);

        setSupplierOptions(response.supplierList);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };

    fetchSupplierOptions();

    if (isEdit) {
      fetchData();
    } else {
      setExpenditureData({
        ...initialExpenditureObject,
        expenditureDate: moment().format("YYYY-MM-DD"),
      });
      fetchConstructionList(moment().format("YYYY-MM-DD"), []);
    }
  }, []);

  const handleUpdate = useCallback(async () => {
    const updateOtherExpenditure = async () => {
      // TODO: error frontend handling here
      // if () {
      //   setErrorMessage('testing');

      //   return;
      // }
      setLoading(true);
      try {
        await Http.put(`auth/accountsPayable/otherExpenditure`, {
          ...expenditureData,
          id: Number(id),
          totalAmount: expenditureData.quantity * expenditureData.unitPrice,
          constructionWorkIDList: Object.keys(constructionRadioData).filter(
            (key) => constructionRadioData[key]
          ),
        });
        const [year, month] = getYearMonth(expenditureData.expenditureDate);
        navigate(`/account-payable/${year}/${month}`);
      } catch (error) {
        console.error("Error fetching data:", error);
        setErrorMessage("修改其他支出錯誤，請稍後再試");
      }
      setLoading(false);
    };

    await updateOtherExpenditure();
  }, [expenditureData, constructionRadioData, navigate, setErrorMessage]);

  const handleCreate = useCallback(async () => {
    const createOtherExpenditure = async () => {
      setLoading(true);
      try {
        await Http.post(`auth/accountsPayable/otherExpenditure`, {
          ...expenditureData,
          totalAmount: expenditureData.quantity * expenditureData.unitPrice,
          constructionWorkIDList: Object.keys(constructionRadioData).filter(
            (key) => constructionRadioData[key]
          ),
        });
        const [year, month] = getYearMonth(expenditureData.expenditureDate);
        navigate(`/account-payable/${year}/${month}`);
      } catch (error) {
        console.error("Error fetching data:", error);
        setErrorMessage("新增請款單錯誤，請稍後再試");
      }
      setLoading(false);
    };

    await createOtherExpenditure();
  }, [expenditureData, constructionRadioData, navigate, setErrorMessage]);

  const handleCancel = useCallback(async () => {
    navigate(`/account-payable`);
  }, [expenditureData, navigate]);

  const handleExpenitureDataChanged = useCallback(
    (date) => {
      setExpenditureData((previousData) => ({
        ...previousData,
        expenditureDate: moment(date).format("YYYY-MM-DD"),
      }));
      fetchConstructionList(
        moment(date).format("YYYY-MM-DD"),
        expenditureData.constructionWorkIDList
      );
    },
    [expenditureData, setExpenditureData]
  );

  const handleAllSelectedChanged = useCallback(() => {
    setIsAllSelected((prevValue) => {
      setConstructionRadioData((prevData) =>
        Object.keys(prevData).reduce(
          (acc, cur) => ({ ...acc, [cur]: !prevValue }),
          {}
        )
      );

      return !prevValue;
    });
  }, [setIsAllSelected, setConstructionRadioData]);

  const selectorLookup = useMemo(() => {
    return {
      supplierID: {
        options: supplierOptions,
        copy: "請選擇廠商",
      },
      paymentMethod: {
        options: paymentMethodOptions,
        copy: "請選擇付款方式",
      },
      paymentStatus: {
        options: paidStatusOptions,
        copy: "請選擇付款狀態",
      },
    };
  }, [supplierOptions]);

  const InputTemplate = (title, isBig) => {
    const inputClassName = `input input-bordered w-1/4 text-2xl ${
      isBig ? "max-w-xs" : ""
    }`;
    const isNumber = title === "unitPrice" || title === "quantity";

    return isNumber ? (
      <NumericInput
        value={expenditureData[title]}
        onChange={(event) => {
          setExpenditureData((prevData) => ({
            ...prevData,
            [title]: Number(event.target.value),
          }));
        }}
      />
    ) : (
      <input
        type="text"
        value={expenditureData[title]}
        onChange={(event) => {
          setExpenditureData((prevData) => ({
            ...prevData,
            [title]: event.target.value,
          }));
        }}
        className={inputClassName}
      />
    );
  };

  const SelectorTemplate = (title) => {
    if (title === "supplierID") {
      return (
        <div className="w-1/4 max-w-xs text-2xl">
          <SearchSelector
            title={selectorLookup[title]?.copy}
            value={expenditureData[title]}
            itemList={selectorLookup[title]?.options}
            onChange={(item) => {
              setExpenditureData((prevData) => ({
                ...prevData,
                [title]: item.id,
              }));
            }}
          ></SearchSelector>
        </div>
      );
    }
    return (
      <select
        className="select select-bordered w-1/4 max-w-xs text-2xl "
        value={expenditureData[title]}
        onChange={(event) => {
          setExpenditureData((prevData) => ({
            ...prevData,
            [title]: event.target.value,
          }));
        }}
      >
        <option value="" disabled>
          {selectorLookup[title]?.copy}
        </option>
        {selectorLookup[title]?.options?.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
    );
  };

  const ConstrucitonTitleTemplate = useMemo(() => {
    if (constructionRadioList.length === 0) return null;

    return (
      <div className="flex items-center">
        <span>按工數比例分攤至以下工程：</span>
        <button className="btn" onClick={handleAllSelectedChanged}>
          {isAllSelected ? "全不選" : "全選"}
        </button>
      </div>
    );
  }, [constructionRadioList, isAllSelected, handleAllSelectedChanged]);

  const ConstrucitonTemplate = useMemo(() => {
    return (
      <div className="flex flex-wrap items-center">
        {constructionRadioList?.map((work) => (
          <label
            key={work.id}
            htmlFor={`construction-${work.id}`}
            className="flex justify-center items-center p-3"
          >
            <input
              type="checkbox"
              id={`construction-${work.id}`}
              name={`construction-${work.id}`}
              className="checkbox"
              value={work.id}
              checked={constructionRadioData[work.id]}
              onChange={(event) => {
                setConstructionRadioData((previousData) => ({
                  ...previousData,
                  [event.target.value]: !previousData[event.target.value],
                }));
              }}
            />
            <span className="ml-2">{work.name}</span>
          </label>
        ))}
      </div>
    );
  }, [constructionRadioList, constructionRadioData, setConstructionRadioData]);

  const PayeeNameTemplate = useMemo(() => {
    if (isSelector) {
      return SelectorTemplate("supplierID");
    } else {
      return InputTemplate("payeeName", true);
    }
  }, [expenditureData, SelectorTemplate, InputTemplate]);

  return (
    <div className="w-full text-header">
      {isLoading && <Loading />}
      <Header
        title={title}
        isEdit={isEdit}
        upsert={isEdit ? handleUpdate : handleCreate}
        cancel={handleCancel}
      />
      {errorMessage ? <ErrorNotification message={errorMessage} /> : null}
      <div className="border border-gray-400 p-3 mx-20 my-10">
        <div className="grid grid-rows-11">
          <div className="flex items-center">
            <div className="font-bold p-3 w-1/6">開單人</div>
            <div className="p-3">
              {isEdit ? expenditureData.createUserName : userName}
            </div>
          </div>
          <div className="flex items-center">
            <div className="font-bold p-3 w-1/6">交易對象</div>
            {PayeeNameTemplate}
            <button
              className="btn ml-3"
              onClick={() => {
                setIsSelector((prev) => !prev);
              }}
            >
              切換對象
            </button>
          </div>
          <div className="flex items-center">
            <div className="font-bold p-3 w-1/6">支出日期</div>
            <DatePicker
              selected={
                expenditureData.expenditureDate === ""
                  ? null
                  : moment(expenditureData.expenditureDate).toDate()
              }
              onChange={handleExpenitureDataChanged}
              dateFormat="yyyy-MM-dd"
              locale="zh-CN"
              placeholderText={"請選擇支出日期"}
              className="input input-bordered max-w-xs text-2xl"
            />
          </div>
          <div className="flex items-center">
            <div className="font-bold p-3 w-1/6">支出內容</div>
            {InputTemplate("content", true)}
          </div>
          <div className="flex items-center">
            <div className="font-bold p-3 w-1/6">備註</div>
            {InputTemplate("remark", true)}
          </div>
          <div className="flex items-center">
            <div className="font-bold p-3 w-1/6">單價</div>
            {InputTemplate("unitPrice")}
          </div>
          <div className="flex items-center">
            <div className="font-bold p-3 w-1/6">數量</div>
            {InputTemplate("quantity")}
          </div>
          <div className="flex items-center">
            <div className="font-bold p-3 w-1/6">金額</div>
            <div className="p-3">
              {(
                expenditureData.unitPrice * expenditureData.quantity
              )?.toLocaleString()}
            </div>
          </div>
          <div className="flex">
            <div className="font-bold p-3 w-1/6">工程</div>
            <div className="flex-col w-5/6">
              {ConstrucitonTitleTemplate}
              {ConstrucitonTemplate}
            </div>
          </div>
          <div className="flex items-center">
            <div className="font-bold p-3 w-1/6">付款狀態</div>
            {SelectorTemplate("paymentStatus")}
            <div className="font-bold p-3 w-1/6 ml-5">付款方式</div>
            {SelectorTemplate("paymentMethod")}
          </div>
          <div className="flex items-center">
            <div className="font-bold p-3 w-1/6">付款號碼</div>
            {InputTemplate("paymentNumber", true)}
            <div className="font-bold p-3 w-1/6 ml-5">發票號碼</div>
            {InputTemplate("receiptNumber", true)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherExpenditure;
